import React from 'react';
import { Container, Row } from 'react-bootstrap';

export default function TermsOfUse() {
  return (
    <>
        <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
            <Row className="justify-content-center">
                Terms of Use
            </Row>
        </Container>
        <Container fluid="lg" className="mt-4">
            <Row style={{paddingLeft:"1em", paddingRight:"1em"}}>
                This Website (this"Site") is owned and operated by Limitless Staging Rentals. Your use of this Site is conditioned on your acceptance of these Terms of Use (these "Terms"). By accessing, using or browsing this Site, you acknowledge and agree to be bound to these Terms. If you do not agree to these Terms or if you do not agree with our Privacy Policy, please do not use this Site. Any use of this Site inconsistent with these Terms is deemed unauthorized access. We reserve the right to make changes to these Terms at any time, without notice to you, by posting changes on this Site. Check back from time to time to ensure you are aware of any updates or changes to these Terms.
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                <Row className='mt-2' style={{fontSize:"1.3em"}}>
                    Communications
                </Row>
                <Row>
                    When you communicate to us through our forms, email, text message, phone call or any other means, you consent to receive communications from us. Both parties have the right to retain copies of these communications.
                </Row>
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                <Row className='mt-2' style={{fontSize:"1.3em"}}>
                    Intellectual Property
                </Row>
                <Row>
                    Limitless Staging Rentals owns the rights to the title and interest in this Site, logo, code, the look and feel, selection and arrangement, home page header images, design and organization of this Site. These rights are protected by Canadian and international copyright laws.
                </Row>
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                <Row className='mt-2' style={{fontSize:"1.3em"}}>
                    Copyright Complaint Policy
                </Row>
                <Row>
                    Infringement Notification: If you believe in good faith that materials hosted by us infringe your copyright, please provide the written information requested below. Please provide the following information in the following order (including Paragraph Numbers):
                    <ol style={{margin:'1em'}}>
                        <li>A clear identification of the material you claim is infringing the copyrighted work, and information that will allow us to locate that material on the Site, such as a link to the infringing material.</li>
                        <li>Your contact information so that we can reply to your complaint, preferably including an email address and a telephone number.</li>
                        <li>Include the following statement: "I have a good faith belief that the material that is claimed as copyright infringement is not authorized by the copyright owner, its agent, or the law."</li>
                        <li>Include the following statement: "I swear, under penalty of perjury, that the information in the notification is accurate and I am the copyright owner, or am authorized to act on behalf of the owner, of an exclusive right that is allegedly infringed."</li>
                        <li>The notice must be signed by the person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. Notices of claims of copyright infringement on this Site should be emailed to: limitlessstagingrentals@gmail.com</li>
                    </ol>
                </Row>
            </Row>
        </Container>
    </>
  )
}
