import React from 'react';
import { Container, Row } from 'react-bootstrap';

export default function TermsOfRentalAgremment() {
  return (
    <>
        <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em", textAlign:"center"}}>
            <Row className="justify-content-center">
                Terms & Policies of Rental Agreement
            </Row>
            <Row className="justify-content-center" style={{fontSize:"0.75em"}}>
                Limitless Staging Rentals Incorporated
            </Row>
        </Container>
        <Container fluid="lg" className="mt-4" style={{fontSize:"1.25em", textAlign:"center"}}>
          <Row className="justify-content-center">
            Acceptance of our Rental Agreement, acknowledgment of these policies, and full payment is required prior to receiving rental furniture.
          </Row>
        </Container>
        <Container fluid="lg" className="mt-4">
          <ol style={{margin:'0.5em'}}>
            <li style={{marginBottom:"1em"}}>The rental contract period is for a 30-day period with a 30-day minimum.</li>
            <li style={{marginBottom:"1em"}}>Orders placed must be paid in full prior to delivery date. Inventory items ordered are not held while Limitless Staging Rentals awaits payment. The inventory is open to other clients until paid in full.</li>
            <li style={{marginBottom:"1em"}}>All renewals are paid in advance of the renewed rental period. The rental rate is based off a 30-day period.  A 50% discount is given for rentals after the first contract period.</li>
            <li style={{marginBottom:"1em"}}>The address where the furniture is located is recorded on your file. Moving the inventory to another property is not permitted unless agreed to in writing.</li>
            <li style={{marginBottom:"1em"}}>There is a strict “no pets” and “no smoking” policy in homes where our furnishings are located.</li>
            <li style={{marginBottom:"1em"}}>All damages/ lost or stolen items are the responsibility of the client and will be charged back at retail cost plus 20%.</li>
            <li style={{marginBottom:"1em"}}>Any soiling to items rented is considered damage. Cleaning and replacement costs are borne by the client. Contact information must be provided for homeowner(s) in the spirit of this policy.</li>
            <li style={{marginBottom:"1em"}}>Clients are required to provide credit card details for security purposes.</li>
            <li style={{marginBottom:"1em"}}>One business days' notice is required for the pickup of inventory.</li>
            <li style={{marginBottom:"1em"}}>Limitless Staging Rentals reserves the right to pick up rental items once the property has sold firm.</li>
            <li style={{marginBottom:"1em"}}>Client will maintain liability and property insurance to cover items rented.</li>
            <li style={{marginBottom:"1em"}}>Limitless Staging Rentals reserves the right to photograph and video the staging project for portfolio, publication, or advertising purposes without compensation or further permission of client.</li>
          </ol>
          <Row className="justify-content-center" style={{fontSize:"1.25em"}}>
            Thank you
          </Row>
        </Container>
    </>
  )
}
