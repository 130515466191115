import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

import { db, functions } from '../firebase-config';

import { doc, getDoc } from 'firebase/firestore';
import { httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

// import NavigationButton from './NavigationButton';
import { useNavigate } from 'react-router-dom';
import Calendar from './Calendar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';

import { formatChosenDeliveryDate, getTwentyFourHourTime, createChosenDeliveryDateTimeStamp, getDistanceFromLocation } from '../HelperFunctions/helper_functions';

import '../css/addbutton.css';

export default function CheckoutMemberDetails({ databaseInfo, setDatabaseInfo, cart, clearCart, chosenDeliveryDate, setChosenDeliveryDate, chosenDeliveryWindow, setChosenDeliveryWindow, doubleCheckCart }) {
    const { currentUser, currentUserInfo } = useAuth();
    const [subtotal, setSubtotal] = useState(0);
    const originalSubTotal = useRef(0);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [deliveryCostOfDelivery, setDeliveryCostOfDelivery] = useState(0);
    const [deliveryDistanceTolerance, setDeliveryDistanceTolerance] = useState(0);
    const [deliveryTotalDistTolerance, setDeliveryTotalDistTolerance] = useState(0);
    const [deliveryPriceTolerance, setDeliveryPriceTolerance] = useState(0);
    const [deliveryAddressError, setDeliveryAddressError] = useState('');
    const [tax, setTax] = useState(0);
    const [deliveryArea, setDeliveryArea] = useState("");
    const [deliveryDisclaimer, setDeliveryDisclaimer] = useState("");
    const [leadTime, setLeadTime] = useState(0);
    const [weekends, setWeekends] = useState(true);
    const [daysOff, setDaysOff] = useState([]);
    const [limitDaysInAdvance, setLimitDaysInAdvance] = useState(0);
    const [timeslotInfo, setTimeslotInfo] = useState({});

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [couponNotification, setCouponNotification] = useState('');
    const [orderConfirmationNumber, setOrderConfirmationNumber] = useState('');

    const [showReviewCart, setShowReviewCart] = useState(false);

    const [deliveryDetailsAddress, setDeliveryDetailsAddress] = useState('');
    const [deliveryDetailsCity, setDeliveryDetailsCity] = useState('');
    const [deliveryDetailsProvince, setDeliveryDetailsProvince] = useState('Ontario');
    const [deliveryDetailsCountry, setDeliveryDetailsCountry] = useState('Canada');
    const [deliveryDetailsPostalCode, setDeliveryDetailsPostalCode] = useState('');
    const [deliveryDetailsNotes, setDeliveryDetailsNotes] = useState('');
    const [billingDetailsEmail, setBillingDetailsEmail] = useState('');
    const [billingDetailsFirstName, setBillingDetailsFirstName] = useState('');
    const [billingDetailsLastName, setBillingDetailsLastName] = useState('');
    const [billingDetailsCompany, setBillingDetailsCompany] = useState('');
    const [billingDetailsAddress, setBillingDetailsAddress] = useState('');
    const [billingDetailsCity, setBillingDetailsCity] = useState('');
    const [billingDetailsProvince, setBillingDetailsProvince] = useState('');
    const [billingDetailsCountry, setBillingDetailsCountry] = useState('');
    const [billingDetailsPostalCode, setBillingDetailsPostalCode] = useState('');
    const [customerPhoneNumber, setCustomPhoneNumber] = useState('');
    const [billingDetailsCardNumber, setBillingDetailsCarNumber] = useState('');
    const [billingDetailsExpiryMonth, setBillingDetailsExpiryMonth] = useState('');
    const [billingDetailsExpiryYear, setBillingDetailsExpiryYear] = useState('');
    const [billingDetailsCVV, setBillingDetailsCVV] = useState('');

    const [textAreaKeyPress, setTextAreaKeyPress] = useState('');

    const [termsAgreedTo, setTermsAgreedTo] = useState(false);

    const [deliveryVerified, setDeliveryVerified] = useState(false);

    const [couponCode, setCouponCode] = useState('');
    const [couponCodeToBeApplied, setCouponCodeToBeApplied] = useState({});
    const [discount, setDiscount] = useState(0);

    const navigate = useNavigate();

    // used for switching to emulators
    const [emulators, setEmulators] = useState(false);

    //const navigate = useNavigate();

    const yearsArray = [];
    for(let x=0; x<16; ++x){
        yearsArray.push(new Date().getFullYear() + x);
    }

    useEffect(()=>{
        // window.scrollTo({top:0, behavior:'smooth'});
        const checkoutInfoRef = doc(db, 'website_data', 'checkout');
        getDoc(checkoutInfoRef).then((docSnap)=>{
            if(docSnap.exists()){
                setTax(docSnap.data()?.tax);
                setDeliveryCostOfDelivery(docSnap.data()?.deliveryCostOfDelivery);
                setDeliveryTotalDistTolerance(docSnap.data()?.deliveryTotalDistTolerance);
                setDeliveryDistanceTolerance(docSnap.data()?.deliveryDistanceTolerance);
                setDeliveryPriceTolerance(docSnap.data()?.deliveryPriceTolerance);
                setDeliveryArea(docSnap.data()?.delivery_area);
                setDeliveryDisclaimer(docSnap.data()?.delivery_disclaimer);
                setLeadTime(docSnap.data()?.leadTime);
                setWeekends(docSnap.data()?.weekends);
                setDaysOff(docSnap.data()?.daysOff);
                setLimitDaysInAdvance(docSnap.data()?.limitDaysInAdvance);
                setTimeslotInfo(docSnap.data()?.timeslotInfo);
            }else{
                console.log('No such document!');
            }
        }).catch((error)=>{
            console.log(`Error geting website data: ${error}`);
        });

        setLoading(false);
        setError('');
        setSuccess('');
        setCouponNotification('');
        setOrderConfirmationNumber('');
    }, []);

    useEffect(()=>{
        setEmulators(false);
        if(emulators){
            connectFunctionsEmulator(functions, "localhost", 5001);
        }
    }, [emulators]);

    useEffect(()=>{
        let sub = 0;
        cart.forEach((item)=>{
            sub += Math.round((parseFloat(item.productInfo.itemInfo.info.price) * parseFloat(item.Quantity))*100)/100
        })
        setSubtotal(sub);
        originalSubTotal.current = sub;
    }, [cart, setSubtotal]);

    useEffect(()=>{
        if(currentUser){
            setBillingDetailsEmail(currentUser.email);
            if(currentUserInfo?.first_name !== undefined){
                setBillingDetailsFirstName(currentUserInfo.first_name);
            }
            if(currentUserInfo?.last_name !== undefined){
                setBillingDetailsLastName(currentUserInfo.last_name);
            }
            if(currentUserInfo?.company !== undefined){   
                setBillingDetailsCompany(currentUserInfo.company);
            }
            if(currentUserInfo?.phonenumber !== undefined){
                setCustomPhoneNumber(currentUserInfo.phonenumber);
            }
            if(currentUserInfo?.country !== undefined){
                setBillingDetailsCountry(currentUserInfo.country);
            }
            if(currentUserInfo?.city !== undefined){
                setBillingDetailsCity(currentUserInfo.city);
            }
            if(currentUserInfo?.postalcode !== undefined){
                setBillingDetailsPostalCode(currentUserInfo.postalcode);
            }
            if(currentUserInfo?.address !== undefined){
                setBillingDetailsAddress(currentUserInfo.address);
            }
            if(currentUserInfo?.province !== undefined){
                setBillingDetailsProvince(currentUserInfo.province);
            }
        }else{
            setDeliveryDetailsAddress('');
            setDeliveryDetailsCity('');
            setDeliveryDetailsProvince('Ontario');
            setDeliveryDetailsCountry('Canada');
            setDeliveryDetailsPostalCode('');
            setDeliveryDetailsNotes('');
            setBillingDetailsEmail('');
            setBillingDetailsFirstName('');
            setBillingDetailsLastName('');
            setBillingDetailsCompany('');
            setBillingDetailsAddress('');
            setBillingDetailsCity('');
            setBillingDetailsProvince('');
            setBillingDetailsCountry('');
            setBillingDetailsPostalCode('');
            setCustomPhoneNumber('');
            setBillingDetailsCarNumber('');
            setBillingDetailsExpiryMonth('');
            setBillingDetailsExpiryYear('');
            setBillingDetailsCVV('');
        }
        setLoading(false);
        setError('');
        setSuccess('');
        setCouponNotification('');
        setOrderConfirmationNumber('');
    }, [currentUser, currentUserInfo]);

    const checkCouponCode = ()=>{
        setLoading(true);
        setError('');
        setCouponCodeToBeApplied({});
        setDiscount(0);
        setCouponNotification('Checking Coupon Code...');
        const applyCoupon = httpsCallable(functions, 'applyCoupon');
        applyCoupon({
            code: couponCode,
            member_code: currentUser?.uid !== undefined ? currentUser.uid : "",
            member_email: currentUser?.email !== undefined ? currentUser.email : "",
            subtotal: originalSubTotal.current,
        }).then((result)=>{
            // console.log(result);
            setSubtotal(originalSubTotal.current);
            if(result.data.status && subtotal){
                setCouponCodeToBeApplied(result.data);
                if(result.data.info.discount_type === "dollar"){
                    let tempSubTotal = originalSubTotal.current;
                    setDiscount(Math.round(result.data.info.discount_amount*100)/100);
                    console.log(Math.round(result.data.info.discount_amount*100)/100)
                    tempSubTotal = tempSubTotal - result.data.info.discount_amount;
                    setSubtotal(tempSubTotal);
                }
                if(result.data.info.discount_type === "percent"){
                    let tempSubTotal = originalSubTotal.current;
                    setDiscount(Math.round((tempSubTotal*(result.data.info.discount_amount/100))*100)/100);
                    console.log(Math.round((tempSubTotal*(result.data.info.discount_amount/100))*100)/100);
                    tempSubTotal = tempSubTotal - (tempSubTotal*(result.data.info.discount_amount/100));
                    setSubtotal(tempSubTotal);
                }
            }
            setLoading(false);
            setError('');
            setCouponCode('');
            setCouponNotification(result.data.message);
        }).catch((error)=>{
            console.log(error);
            setLoading(false);
            setCouponNotification(`Error getting coupon code: ${error.error}. If you have any questions feel free to contact us. (905) 885-2885`);
        });
    };

    const placeOrder = async ()=>{
        setLoading(true);
        setError('');
        setOrderConfirmationNumber('');
        setShowReviewCart(false);
        if(formComplete()){
            setSuccess('Double checking available inventory, one moment...');
            try{
                let cartCheckSuccess = await doubleCheckCart();
                if(cartCheckSuccess){
                    setSuccess('Processing...');
                    const chargeCreditCard = httpsCallable(functions, 'chargeCreditCard');
                    chargeCreditCard({
                        id:`${currentUser ? currentUser.uid : 'guest'}`,
                        delivery_address: `${deliveryDetailsAddress}`,
                        delivery_city: `${deliveryDetailsCity}`,
                        delivery_province: `${deliveryDetailsProvince}`,
                        delivery_country: `${deliveryDetailsCountry}`,
                        delivery_postalcode: `${deliveryDetailsPostalCode}`,
                        delivery_date: `${formatChosenDeliveryDate(chosenDeliveryDate)}`,
                        delivery_window: `${chosenDeliveryWindow}`,
                        delivery_start: getTwentyFourHourTime(chosenDeliveryWindow.split('-')[0]),
                        delivery_end: getTwentyFourHourTime(chosenDeliveryWindow.split('-')[1]),
                        delivery_timestamp: createChosenDeliveryDateTimeStamp(formatChosenDeliveryDate(chosenDeliveryDate)),
                        delivery_notes: `${deliveryDetailsNotes}`,
                        order_timestamp: new Date().getTime(),
                        email:`${billingDetailsEmail}`,
                        first_name: `${billingDetailsFirstName}`,
                        last_name: `${billingDetailsLastName}`,
                        billing_company: `${billingDetailsCompany}`,
                        billing_address: `${billingDetailsAddress}`,
                        billing_city: `${billingDetailsCity}`,
                        billing_province: `${billingDetailsProvince}`,
                        billing_country: `${billingDetailsCountry}`,
                        billing_postalcode: `${billingDetailsPostalCode}`,
                        customer_phonenumber: `${customerPhoneNumber}`,
                        card_number: `${billingDetailsCardNumber.replace(/\s+/g, '')}`,
                        expiry: `${billingDetailsExpiryMonth}${billingDetailsExpiryYear.slice(-2)}`,
                        cvv: `${billingDetailsCVV}`,
                        subtotal: Math.round(subtotal*100)/100,
                        delivery_charge: Math.round(deliveryCharge*100)/100,
                        tax: Math.round((((subtotal+deliveryCharge) * tax)/100)*100)/100,
                        total: Math.round(((subtotal+deliveryCharge) + (((subtotal+deliveryCharge) * tax)/100))*100)/100,
                        cart: cart,
                        discount: discount,
                        discount_name: couponCodeToBeApplied?.info?.code !== undefined ? couponCodeToBeApplied.info.code : "",
                    }).then((cardInfo)=>{
                        // console.log(cardInfo);
                        setLoading(false);
                        if(cardInfo.data.result){
                            setError('');
                            setShowReviewCart(false);
                            setSuccess('Thank you, order is complete. You should be seeing an email confirmation shortly. If you have any questions feel free to contact us. (905) 885-2885');
                            setOrderConfirmationNumber(`Confirmation: ${cardInfo.data.docId}`);
                            setBillingDetailsCVV('');
                            setBillingDetailsCarNumber('');
                            setBillingDetailsExpiryMonth('');
                            setBillingDetailsExpiryYear('');
                            let tempDatabaseInfo = databaseInfo;
                            cart.forEach((item)=>{
                                tempDatabaseInfo.data[`${item.productInfo.department}`][`${item.productInfo.subdepartment}`][`${item.productInfo.itemInfo.name}`].quantity = tempDatabaseInfo.data[`${item.productInfo.department}`][`${item.productInfo.subdepartment}`][`${item.productInfo.itemInfo.name}`].quantity - item.Quantity;
                            });
                            setDatabaseInfo(tempDatabaseInfo);
                            clearCart();
                            window.scrollTo({top:0, behavior:'smooth'});
                        }else{
                            setSuccess('');
                            setOrderConfirmationNumber('');
                            setShowReviewCart(false);
                            setError(`1. Transaction NOT completed: ${cardInfo.data.error} If you have any questions feel free to contact us. (905) 885-2885`);
                        }
                    }).catch((error)=>{
                        console.log(error);
                        setLoading(false);
                        setSuccess('');
                        setOrderConfirmationNumber('');
                        setShowReviewCart(false);
                        setError(`2. Transaction NOT completed: ${error.error}. If you have any questions feel free to contact us. (905) 885-2885`);
                    });
                }else{
                    setLoading(false);
                    setSuccess('');
                    setOrderConfirmationNumber('');
                    setShowReviewCart(true);
                    setError('It appears one or more of your items are no longer available. Please press the button below to review your cart.');
                }
            }catch(error){
                setLoading(false);
                setShowReviewCart(false);
                setSuccess('');
                setOrderConfirmationNumber('');
                console.log(error);
                setError('Sorry, there has been an error connecting to the database. Please try again.');
            }
        }else{
            setLoading(false);
            setShowReviewCart(false);
            setSuccess('');
            setOrderConfirmationNumber('');
            setError('Not all required information is filled out. If you have any questions feel free to contact us. (905) 885-2885');
        }
    };

    const formComplete = ()=>{
        if(
            deliveryDetailsAddress !== '' &&
            deliveryDetailsCity !== '' &&
            deliveryDetailsProvince !== '' &&
            deliveryDetailsCountry !== '' &&
            // deliveryDetailsPostalCode !== '' &&
            billingDetailsEmail !== '' &&
            billingDetailsFirstName !== '' &&
            billingDetailsLastName !== '' &&
            billingDetailsCardNumber !== '' &&
            billingDetailsExpiryMonth !== '' &&
            billingDetailsExpiryYear !== '' &&
            billingDetailsCVV !== '' &&
            chosenDeliveryDate !== '' &&
            chosenDeliveryWindow !== '' &&
            billingDetailsAddress !== '' &&
            billingDetailsCity !== '' &&
            billingDetailsProvince !== '' &&
            billingDetailsCountry !== '' &&
            billingDetailsPostalCode !== '' &&
            customerPhoneNumber !== ''
        ){
            return true;
        }else{
            return false;
        }
    };

    const checkDeliveryArea = async()=>{
        const address = `${deliveryDetailsAddress} ${deliveryDetailsCity} ${deliveryDetailsProvince} ${deliveryDetailsCountry} ${deliveryDetailsPostalCode}`;
        try{
            const response = await getDistanceFromLocation(address);
            if(response.status === false){
                setDeliveryVerified(false);
                if(response.distance === -1){
                    setDeliveryAddressError(`Unable to determine location: ${address}. Please check the address and try again. If the error continues please call (905) 885-2885 for assistance.`);
                }
                if(response.distance === -2){
                    setDeliveryAddressError(`There was an error while trying to assess the address. If the error continues please call (905) 885-2885 for assistance.`);
                }
                setDeliveryDetailsAddress("");
                setDeliveryDetailsCity("");
                setDeliveryDetailsPostalCode("");
            }else{
                setDeliveryCharge(calculateShippingCost(response.distance, subtotal, address));
            }
        }catch(err){
            setDeliveryAddressError(`There was an error while trying to assess the address. If the error continues please call (905) 885-2885 for assistance.`);
        }
    };

    // this should be website side = only return the distance
    const calculateShippingCost = (dist, price, address) =>{
        let shippingCost = 0.00;
        if(dist > deliveryTotalDistTolerance){
            setDeliveryAddressError(`${address} is outside of our delivery area. For further assistance please call (905) 885-2885.`);
            setDeliveryVerified(false);
            setDeliveryDetailsAddress("");
            setDeliveryDetailsCity("");
            setDeliveryDetailsPostalCode("");
        }else if (dist >= deliveryDistanceTolerance && price < deliveryPriceTolerance) {
            shippingCost = deliveryCostOfDelivery;
            setDeliveryVerified(true);
        } else {
            shippingCost = 0.00;
            setDeliveryVerified(true);
        }
        return shippingCost;
    };

    return (
        <>
            <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                <Row className="justify-content-center">
                    {orderConfirmationNumber === '' ? 'CHECKOUT' : 'ORDER COMPLETE' }
                </Row>
            </Container>
            <Container fluid="lg">
                <Row>
                { orderConfirmationNumber === '' ?    
                    <Col lg="6" className="mt-4">
                        DELIVERY AREA
                        <div style={{background: "#F5F5F5", padding: "20px"}}>
                            {
                                <div>{deliveryArea}</div>
                            }
                            {
                                <div style={{fontSize:"0.8em"}}>{deliveryDisclaimer}</div>
                            }
                        </div>
                        <Form style={{margin:'20px 0 20px 0'}}>
                            DELIVERY DETAILS
                            <Form.Group id="email" style={{background: "#F5F5F5", padding: "20px"}}>
                                <Form.Label style={{fontSize:"0.8em", color:deliveryDetailsAddress === ''? "red": "black"}}>Street Address *</Form.Label>
                                <Form.Control type="text" value={deliveryDetailsAddress} onChange={(e)=>{
                                    setDeliveryDetailsAddress(e.target.value)
                                    setDeliveryVerified(false);
                                }} required />
                                <Form.Label style={{fontSize:"0.8em", color:deliveryDetailsCity === ''? "red": "black"}}>Town / City *</Form.Label>
                                <Form.Control type="text" value={deliveryDetailsCity} onChange={(e)=>{
                                    setDeliveryDetailsCity(e.target.value)
                                    setDeliveryVerified(false);
                                }} required />
                                <Form.Label style={{fontSize:"0.8em"}}>Province *</Form.Label>
                                <Form.Control type="text" value={deliveryDetailsProvince} onChange={(e)=>setDeliveryDetailsProvince(e.target.value)} disabled required />
                                <Form.Label style={{fontSize:"0.8em"}}>Country *</Form.Label>
                                <Form.Control type="text" value={deliveryDetailsCountry} onChange={(e)=>setDeliveryDetailsCountry(e.target.value)} disabled required />
                                <Form.Label style={{fontSize:"0.8em"}}>Postal Code (optional)</Form.Label>
                                <Form.Control type="text" value={deliveryDetailsPostalCode} onChange={(e)=>{
                                    setDeliveryDetailsPostalCode(e.target.value)
                                    setDeliveryVerified(false);
                                }} />
                                {
                                    deliveryAddressError !== '' &&
                                        <Alert variant="danger">{deliveryAddressError}</Alert>
                                }
                                <div style={{marginTop:"20px", width:"100%", textAlign:"center"}}>
                                    <div
                                        className="button"
                                        style={
                                            loading || deliveryDetailsAddress === '' || deliveryDetailsCity === '' || deliveryVerified ? 
                                                {
                                                    width:"80%",
                                                    fontSize:"0.8em",
                                                    height:"60px",
                                                    lineHeight:"40px",
                                                    backgroundColor: "grey",
                                                }
                                            :
                                                {
                                                    width:"80%",
                                                    fontSize:"0.8em",
                                                    height:"60px",
                                                    lineHeight:"40px",
                                                }
                                        }
                                        onClick={()=>{
                                            if(!loading && deliveryDetailsAddress !== '' && deliveryDetailsCity !== '' && !deliveryVerified){
                                                setDeliveryAddressError(``);
                                                checkDeliveryArea();
                                            } 
                                        }}>
                                        CHECK DELIVERY AREA
                                    </div>
                                </div>
                            </Form.Group>
                            
                            {
                                deliveryVerified &&
                                <div>
                                    <Calendar leadTime={leadTime} weekends={weekends} daysOff={daysOff} limitDaysInAdvance={limitDaysInAdvance} timeslotInfo={timeslotInfo} setChosenDeliveryDate={setChosenDeliveryDate} setChosenDeliveryWindow={setChosenDeliveryWindow} />
                                    <Form.Group className="mt-3" style={{background: "#F5F5F5", padding: "20px"}}>
                                        <Form.Label style={{fontSize:"0.8em"}}>Order notes (optional)</Form.Label>
                                        <Form.Control as="textarea" placeholder="Special notes about the order or for delivery. (max 255 characters)" value={deliveryDetailsNotes} onKeyDown={(e)=>setTextAreaKeyPress(e.key)} onChange={(e)=>{
                                            if(deliveryDetailsNotes.length < 255 || textAreaKeyPress === 'Backspace'){
                                                setDeliveryDetailsNotes(e.target.value)
                                            }
                                        }} rows={4} />
                                        <Form.Label style={{fontSize:"0.8em"}}>Characters remaining: {255-deliveryDetailsNotes.length}</Form.Label>
                                    </Form.Group>
                                    <hr></hr>
                                    BILLING DETAILS
                                    <Form.Group id="email" style={{background: "#F5F5F5", padding: "20px", marginTop: "10px"}}>
                                        <Form.Label style={{fontSize:"0.8em"}}>Phone Number *</Form.Label>
                                        <Form.Control type="text" value={customerPhoneNumber} onChange={(e)=>setCustomPhoneNumber(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>Email Address *</Form.Label>
                                        <Form.Control type="email" value={billingDetailsEmail} onChange={(e)=>setBillingDetailsEmail(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>We'll send order updates to this email.</Form.Label>
                                    </Form.Group>
                                    <Form.Group id="name" style={{background: "#F5F5F5", padding: "20px", marginTop: "10px"}}>
                                        <Form.Label style={{fontSize:"0.8em"}}>First Name *</Form.Label>
                                        <Form.Control type="email" value={billingDetailsFirstName} onChange={(e)=>setBillingDetailsFirstName(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>Last Name *</Form.Label>
                                        <Form.Control type="email" value={billingDetailsLastName} onChange={(e)=>setBillingDetailsLastName(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>Company (optional)</Form.Label>
                                        <Form.Control type="email" value={billingDetailsCompany} onChange={(e)=>setBillingDetailsCompany(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group id="name" style={{background: "#F5F5F5", padding: "20px", marginTop: "10px"}}>
                                        <Form.Label style={{fontSize:"0.8em"}}>Street Address *</Form.Label>
                                        <Form.Control type="text" value={billingDetailsAddress} onChange={(e)=>setBillingDetailsAddress(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>Town / City *</Form.Label>
                                        <Form.Control type="text" value={billingDetailsCity} onChange={(e)=>setBillingDetailsCity(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>Province / State*</Form.Label>
                                        <Form.Control type="text" value={billingDetailsProvince} onChange={(e)=>setBillingDetailsProvince(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>Country *</Form.Label>
                                        <Form.Control type="text" value={billingDetailsCountry} onChange={(e)=>setBillingDetailsCountry(e.target.value)} required />
                                        <Form.Label style={{fontSize:"0.8em"}}>Postal Code *</Form.Label>
                                        <Form.Control type="text" value={billingDetailsPostalCode} onChange={(e)=>setBillingDetailsPostalCode(e.target.value)} required />
                                    </Form.Group>
                                </div>
                            }
                        </Form>
                    </Col>
                :
                    ''
                }
                    <Col  className="mt-4" style={{border:'1px solid grey'}}>
                    {orderConfirmationNumber === '' ?
                        <Row className="justify-content-center" style={{margin:'20px 0 20px 0'}}>
                            YOUR ORDER
                        </Row>
                    :
                        ''
                    }
                        {orderConfirmationNumber === '' ?
                            cart.map((item, index)=>{
                                if(item?.Quantity>item?.productInfo?.itemInfo?.info?.quantity){
                                    return(
                                        <Row key={index} className="justify-content-center" style={{borderBottom: "1px solid grey", marginLeft:"1px", marginRight:"1px", padding:"10px 0 10px 0"}}>
                                            <Col style={{fontSize:"0.8em"}} className="errorColor">{item.productInfo.itemInfo.name} x {item.Quantity}</Col>
                                            <Col xs="5" lg="4" xl="3" className="number">{
                                                `$${Math.round((item.productInfo.itemInfo.info.price*item.Quantity)*100)/100}`     
                                            }</Col>
                                        </Row>
                                    )
                                }else{
                                    return(
                                        <Row key={index} className="justify-content-center" style={{borderBottom: "1px solid grey", marginLeft:"1px", marginRight:"1px", padding:"10px 0 10px 0"}}>
                                            <Col style={{fontSize:"0.8em"}}>{item.productInfo.itemInfo.name} x {item.Quantity}</Col>
                                            <Col xs="5" lg="4" xl="3" className="number">{
                                                `$${Math.round((item.productInfo.itemInfo.info.price*item.Quantity)*100)/100}`     
                                            }</Col>
                                        </Row>
                                    )
                                }   
                            })
                        :
                            ''
                        }
                        {orderConfirmationNumber === '' && couponCodeToBeApplied?.info?.discount_type !== undefined && couponCodeToBeApplied.info.discount_type === "dollar" ?
                            <Row className="justify-content-center" style={{marginLeft:"1px", marginRight:"1px", padding:"5px 0 5px 0"}}>
                                <Col>{couponCodeToBeApplied.info.code}</Col>
                                <Col xs="5" lg="4" xl="3" className="number">{
                                    cart.length > 0 && `-$${Math.round(couponCodeToBeApplied.info.discount_amount*100)/100}`
                                }</Col>
                            </Row>
                            :
                            ''
                        }
                        {orderConfirmationNumber === '' && couponCodeToBeApplied?.info?.discount_type !== undefined && couponCodeToBeApplied.info.discount_type === "percent" ?
                            <Row className="justify-content-center" style={{marginLeft:"1px", marginRight:"1px", padding:"5px 0 5px 0"}}>
                                <Col>{couponCodeToBeApplied.info.code}</Col>
                                <Col xs="5" lg="4" xl="3" className="number">{
                                    cart.length > 0 && `-${Math.round(couponCodeToBeApplied.info.discount_amount*100)/100}%`
                                }</Col>
                            </Row>
                            :
                            ''
                        }
                        {orderConfirmationNumber === '' ?
                            <Row className="justify-content-center" style={{marginLeft:"1px", marginRight:"1px", padding:"5px 0 5px 0"}}>
                                <Col>Subtotal</Col>
                                <Col xs="5" lg="4" xl="3" className="number">{
                                    cart.length > 0 && `$${Math.round(subtotal*100)/100}`     
                                }</Col>
                            </Row>
                            :
                            ''
                        }
                        {orderConfirmationNumber === '' ?
                            <Row className="justify-content-center" style={{marginLeft:"1px", marginRight:"1px", padding:"5px 0 5px 0"}}>
                                <Col>Delivery Charge</Col>
                                <Col xs="5" lg="4" xl="3" className="number">{
                                    cart.length > 0 && `$${Math.round(deliveryCharge*100)/100}`     
                                }</Col>
                            </Row>
                            :
                            ''
                        }
                        {orderConfirmationNumber === '' ?
                            <Row className="justify-content-center" style={{marginLeft:"1px", marginRight:"1px", padding:"5px 0 5px 0"}}>
                                <Col>Tax</Col>
                                <Col xs="5" lg="4" xl="3" className="number">{
                                    cart.length > 0 && `$${Math.round((((subtotal+deliveryCharge) * tax)/100)*100)/100}`     
                                }</Col>
                            </Row>
                            :
                            ''
                        }
                        {orderConfirmationNumber === '' ?
                            <Row className="justify-content-center" style={{fontSize:'1.3em',marginLeft:"1px", marginRight:"1px", padding:"5px 0 5px 0"}}>
                                <Col>Total</Col>
                                <Col xs="5" lg="4" xl="3" className="number">{
                                    cart.length > 0 && `$${Math.round(((subtotal+deliveryCharge) + (((subtotal+deliveryCharge) * tax)/100))*100)/100}`     
                                }</Col>
                            </Row>
                            :
                            ''
                        }
                        {orderConfirmationNumber === '' ?
                            <Row className="justify-content-center" style={{margin:'20px 0 20px 0'}}>
                                <Col xs="3">Delivery:</Col>
                                <Col className="number">{chosenDeliveryDate} {chosenDeliveryWindow}</Col>
                            </Row>
                            :
                            ''
                        }
                        {couponNotification && <Alert variant="info">{couponNotification}</Alert>}
                        {
                            orderConfirmationNumber === '' ?
                                <Form.Group id="name" style={{background: "#F5F5F5", padding: "20px", marginTop: "10px"}}>
                                    <Form.Label style={{fontSize:"0.9em"}}>Apply Coupon Code</Form.Label>
                                    <Form.Control className="number" type="text" value={couponCode} onChange={(e)=> setCouponCode(e.target.value)} />
                                    <Row className="justify-content-center" style={{margin:'20px 0 20px 0'}}>
                                        <div
                                            className="button"
                                            style={
                                                loading || couponCode === '' ? 
                                                    {
                                                        width:"80%",
                                                        fontSize:"0.8em",
                                                        height:"60px",
                                                        lineHeight:"40px",
                                                        backgroundColor: "grey",
                                                    }
                                                :
                                                    {
                                                        width:"80%",
                                                        fontSize:"0.8em",
                                                        height:"60px",
                                                        lineHeight:"40px",
                                                    }
                                            }
                                            onClick={()=>{
                                                if(!loading && couponCode !== ''){
                                                    checkCouponCode();
                                                } 
                                            }}>
                                            Apply Coupon
                                        </div>
                                    </Row>
                                </Form.Group>
                            :
                            ''
                        }
                    {orderConfirmationNumber === '' ?
                        <Form.Group id="name" style={{background: "#F5F5F5", padding: "20px", marginTop: "10px"}}>
                            <div style={{fontSize:"0.9em"}}>Card Information</div>
                            <div style={{fontSize:"0.8em"}}>We accept the following cards.</div>
                            <div className="d-flex justify-content-around"><FontAwesomeIcon style={{fontSize: '3em'}} icon={faCcVisa} /><FontAwesomeIcon style={{fontSize: '3em'}} icon={faCcMastercard} /></div>
                            <Form.Label style={{fontSize:"0.8em"}}>Card Number</Form.Label>
                            <Form.Control className="number" type="tel" maxLength="19" pattern="[0-9]+" value={billingDetailsCardNumber.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()} onChange={(e)=>setBillingDetailsCarNumber(e.target.value)} required />
                            
                            <Form.Group as={Row}>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label column style={{fontSize:"0.8em"}}>Expiry Month</Form.Label>
                                        <Form.Select aria-label="Default select example" className="number" value={billingDetailsExpiryMonth} onChange={(e)=>setBillingDetailsExpiryMonth(e.target.value)}>
                                            <option></option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label column style={{fontSize:"0.8em"}}>Expiry Year</Form.Label>
                                        <Form.Select aria-label="Default select example" className="number" value={billingDetailsExpiryYear} onChange={(e)=>setBillingDetailsExpiryYear(e.target.value)}>
                                            <option></option>
                                            {
                                                yearsArray.map((year)=>{
                                                    return <option key={year} value={year}>{year}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col sm={6}>
                                    <Form.Label style={{fontSize:"0.8em"}}>CVV</Form.Label>
                                    <Form.Control className="number" type="tel" maxLength="4" pattern="[0-9]+" value={billingDetailsCVV.replace(/[^\dA-Z]/g, '')} onChange={(e)=>setBillingDetailsCVV(e.target.value)} required />
                                </Col>
                                <Col sm={6}>
                                    <div className="d-flex align-items-end" style={{height:"100%", fontSize:"0.7em"}}>This 3 or 4 digit code is on the back or front of your card.</div>
                                </Col>
                            </Form.Group>
                        </Form.Group>
                    :
                        ''
                    }
                        <Row className="justify-content-center" style={{margin:'20px 0 20px 0'}}>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}
                            {orderConfirmationNumber && <Alert className="number" variant="success">{orderConfirmationNumber}</Alert>}
                            {
                                cart.length > 0 ? 
                                    showReviewCart === false ?
                                    <div style={{width:"100%", textAlign:"center"}}>
                                        <div
                                            className="button"
                                            style={
                                                loading || !termsAgreedTo || !deliveryVerified ? 
                                                    {
                                                        width:"80%",
                                                        fontSize:"0.8em",
                                                        height:"60px",
                                                        lineHeight:"40px",
                                                        backgroundColor: "grey",
                                                    }
                                                :
                                                    {
                                                        width:"80%",
                                                        fontSize:"0.8em",
                                                        height:"60px",
                                                        lineHeight:"40px",
                                                    }
                                            }
                                            onClick={()=>{
                                                if(!loading && termsAgreedTo && deliveryVerified){
                                                    placeOrder()
                                                } 
                                            }}>
                                            PLACE ORDER
                                        </div>
                                        <div style={{fontSize: "0.9em", marginTop:"10px", textAlign:"center"}}>
                                            <Form.Check 
                                                type="checkbox"
                                                id="agree_to_terms"
                                                label={`Clicking the checkbox means you agree to our Terms & Policies of Rental Agreement`}
                                                onClick={()=>{
                                                    let tempAgreed = termsAgreedTo;
                                                    if(tempAgreed){
                                                        tempAgreed = false;
                                                    }else{
                                                        tempAgreed = true;
                                                    }
                                                    setTermsAgreedTo(tempAgreed);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                        <div
                                            className="button"
                                            style={
                                                loading ? 
                                                    {
                                                        width:"80%",
                                                        fontSize:"0.8em",
                                                        height:"60px",
                                                        lineHeight:"40px",
                                                        backgroundColor: "grey",
                                                    }
                                                :
                                                    {
                                                        width:"80%",
                                                        fontSize:"0.8em",
                                                        height:"60px",
                                                        lineHeight:"40px",
                                                        
                                                    }
                                            }
                                            onClick={()=>{
                                                navigate(`/cart`); 
                                            }}>
                                            REVIEW CART
                                        </div>
                                :
                                    <div
                                        className="button"
                                        style={
                                            loading ? 
                                                {
                                                    width:"80%",
                                                    fontSize:"0.8em",
                                                    height:"60px",
                                                    lineHeight:"40px",
                                                    backgroundColor: "grey",
                                                }
                                            :
                                                {
                                                    width:"80%",
                                                    fontSize:"0.8em",
                                                    height:"60px",
                                                    lineHeight:"40px",
                                                }
                                        }
                                        onClick={()=>{
                                            if(!loading){
                                                navigate('/');
                                            } 
                                        }}>
                                        CONTINUE SHOPPING
                                    </div>
                            }
                        </Row>
                        {
                            !termsAgreedTo &&
                                <Container style={{fontSize:"0.8em"}}>
                                    <Row style={{textAlign:"center", fontWeight:"bold"}}>
                                        Acceptance of our Rental Agreement, acknowledgment of these policies, and full payment is required prior to receiving rental furniture.
                                    </Row>
                                    <ol style={{paddingLeft:'0', marginTop:"1em"}}>
                                        <li>The rental contract period is for a 30-day period with a 30-day minimum.</li>
                                        <li>Orders placed must be paid in full prior to delivery date. Inventory items ordered are not held while Limitless Staging Rentals awaits payment. The inventory is open to other clients until paid in full.</li>
                                        <li>All renewals are paid in advance of the renewed rental period. The rental rate is based off a 30-day period.  A 50% discount is given for rentals after the first contract period.</li>
                                        <li>The address where the furniture is located is recorded on your file. Moving the inventory to another property is not permitted unless agreed to in writing.</li>
                                        <li>There is a strict “no pets” and “no smoking” policy in homes where our furnishings are located.</li>
                                        <li>All damages/ lost or stolen items are the responsibility of the client and will be charged back at retail cost plus 20%.</li>
                                        <li>Any soiling to items rented is considered damage. Cleaning and replacement costs are borne by the client. Contact information must be provided for homeowner(s) in the spirit of this policy.</li>
                                        <li>Clients are required to provide credit card details for security purposes.</li>
                                        <li>One business days' notice is required for the pickup of inventory.</li>
                                        <li>Limitless Staging Rentals reserves the right to pick up rental items once the property has sold firm.</li>
                                        <li>Client will maintain liability and property insurance to cover items rented.</li>
                                        <li>Limitless Staging Rentals reserves the right to photograph and video the staging project for portfolio, publication, or advertising purposes without compensation or further permission of client.</li>
                                    </ol>
                                    <Row className="justify-content-center" style={{fontSize:"1.25em"}}>
                                        Thank you
                                    </Row>
                                </Container>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

/*
                            <div style={{fontSize:"0.6em"}}>Sandbox Fake Card Numbers</div>
                            <div style={{fontSize:"0.6em"}}>{4111111111111111}</div>
                            <div style={{fontSize:"0.6em"}}>{5424000000000015}</div>
                            <div style={{fontSize:"0.6em"}}>{370000000000002}</div>
*/
