import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Alert, Container, Form } from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';

import { db } from '../firebase-config';
import { doc, setDoc , serverTimestamp } from 'firebase/firestore';

export default function MemberPortal() {
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { currentUser, currentUserInfo, updateUserPassword, updateCurrentUserInfo, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [userType, setUserType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [company, setCompany] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [postalcode, setPostalcode] = useState('');
    const [province, setProvince] = useState('');

    useEffect(()=>{
        (currentUserInfo?.type === undefined) ? setUserType('') : setUserType(currentUserInfo.type);
        (currentUserInfo?.first_name === undefined) ? setFirstName('') : setFirstName(currentUserInfo.first_name);
        (currentUserInfo?.last_name === undefined) ? setLastName('') : setLastName(currentUserInfo.last_name);
        (currentUserInfo?.company === undefined) ? setCompany('') : setCompany(currentUserInfo.company);
        (currentUserInfo?.phonenumber === undefined) ? setPhonenumber('') : setPhonenumber(currentUserInfo.phonenumber);
        (currentUserInfo?.country === undefined) ? setCountry('') : setCountry(currentUserInfo.country);
        (currentUserInfo?.city === undefined) ? setCity('') : setCity(currentUserInfo.city);
        (currentUserInfo?.address === undefined) ? setAddress('') : setAddress(currentUserInfo.address);
        (currentUserInfo?.postalcode === undefined) ? setPostalcode('') : setPostalcode(currentUserInfo.postalcode);
        (currentUserInfo?.province === undefined) ? setProvince('') : setProvince(currentUserInfo.province);
    }, [currentUserInfo]);

    async function handleLogout(){
        setUserType('');
        setFirstName('');
        setLastName('');
        setError('');
        try{
            await logout();
            navigate("/");
        }catch(error){
            setError(`Failed to log out: ${error.message}`);
        }
    }

    async function handlePersonalInformationSubmit(event){
        event.preventDefault();

        try{
            setError('');
            setLoading(true);
            const docRef = doc(db, 'users', currentUser.uid);
            await setDoc(docRef, {
                type: userType,
                email: currentUser.email,
                first_name: firstName,
                last_name: lastName,
                company: company,
                phonenumber: phonenumber,
                country: country,
                city: city,
                address: address,
                postalcode: postalcode,
                province: province,
                timestamp: serverTimestamp()
            });
            updateCurrentUserInfo({
                type: userType,
                email: currentUser.email,
                first_name: firstName,
                last_name: lastName,
                company: company,
                phonenumber: phonenumber,
                country: country,
                city: city,
                address: address,
                postalcode: postalcode,
                province: province,
                timestamp: serverTimestamp()
            })
            setLoading(false);
            setMessage('Information has been updated');
        }catch(error){
            setMessage('');
            setError(`Failed to update Information: ${error}`);
        }
    }

    function handleSubmit(event){
        event.preventDefault();
        setMessage('');
        setError('');
        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Passwords do not match');
        }
        if(passwordRef.current.value === ''){
            return setMessage('Password fields blank, no changes made');
        }
        const promises = [];
        setLoading(true);
        setMessage('');
        setError('');
        /*if(emailRef.current.value !== currentUser.email){
            promises.push(updateUserEmail(emailRef.current.value));
        }*/
        if(passwordRef.current.value){
            promises.push(updateUserPassword(passwordRef.current.value));
        }

        Promise.all(promises).then(()=>{
            setMessage('Password has been updated');
            passwordRef.current.value = '';
            passwordConfirmRef.current.value = '';
        }).catch(()=>{
            setError('Failed to update account password');
        }).finally(()=>{
            setLoading(false);
        });
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center mt-5" style={{minHeight: "60vh"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">{userType.charAt(0).toUpperCase() + userType.slice(1)} Profile</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && <Alert variant="success">{message}</Alert>}
                            <strong>Email:</strong> {currentUser.email}
                            <hr />
                            <h4 className="text-center mb-4">{userType.charAt(0).toUpperCase() + userType.slice(1)} Information</h4>
                            <Form onSubmit={handlePersonalInformationSubmit}>
                                <Form.Group id="phonenumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" value={phonenumber} name="phonenmber" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setPhonenumber(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="first-name">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" value={firstName} name="firstName" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setFirstName(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="last-name">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" value={lastName} name="lastName" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setLastName(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control type="text" value={company} name="company" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setCompany(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="address">
                                    <Form.Label>Street Address</Form.Label>
                                    <Form.Control type="text" value={address} name="address" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setAddress(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="city">
                                    <Form.Label>Town / City</Form.Label>
                                    <Form.Control type="text" value={city} name="city" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setCity(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="province">
                                    <Form.Label>Province / State</Form.Label>
                                    <Form.Control type="text" value={province} name="province" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setProvince(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" value={country} name="country" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setCountry(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="postalcode">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control type="text" value={postalcode} name="postalcode" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setPostalcode(event.target.value)
                                    }} />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handlePersonalInformationSubmit}>Update {userType.charAt(0).toUpperCase() + userType.slice(1)} Information</Button>
                            </Form>
                            <hr />
                            <h4 className="text-center mb-4">Password Update</h4>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep the same" />
                                </Form.Group>
                                <Form.Group id="password-confirm">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" ref={passwordConfirmRef} placeholder="Leave blank to keep the same" />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handleSubmit}>Update Password</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt2">
                        <Button variant="link" onClick={handleLogout}>Log Out</Button>
                    </div>
                </div>
            </Container>
        </>
    )
}
