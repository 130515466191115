import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { db } from '../firebase-config';

import { doc, getDoc } from 'firebase/firestore';

import '../css/howitworks.css';


export default function HowItWorks() {
  const [howItWorksInfo, setHowItWorksInfo] = useState({});

  useEffect(()=>{
    const howItWorksRef = doc(db, 'website_data', 'how_it_works');
    getDoc(howItWorksRef).then((docSnap)=>{
      if(docSnap.exists()){
        // console.log(docSnap.data());
        setHowItWorksInfo({data: docSnap.data()});
      }else{
        console.log('No such document!');
      }
    }).catch((error)=>{
      console.log(`Error geting website data: ${error}`);
    });
  }, []);
  return (
    <>
        <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
            <Row className="justify-content-center">
                {howItWorksInfo?.data?.title}
            </Row>
        </Container>
        <Container fluid="lg" className="mt-4">
            <Row className="how_it_works_body">
                <Col className="sections">
                    {
                        howItWorksInfo?.data?.display_numbers &&
                            <div className="number_circle">1</div>
                    }
                    <div>
						<div className="section_title" id="infoOneTitle">{howItWorksInfo?.data?.step_one_title}</div>
						<div className="section_info" id="infoOne">{howItWorksInfo?.data?.step_one}</div>
					</div>
                </Col>
                <Col className="sections">
                {
                        howItWorksInfo?.data?.display_numbers &&
                            <div className="number_circle">2</div>
                    }
                    <div>
						<div className="section_title" id="infoTwoTitle">{howItWorksInfo?.data?.step_two_title}</div>
						<div className="section_info" id="infoTwo">{howItWorksInfo?.data?.step_two}</div>
					</div>
                </Col>
                <Col className="sections">
                {
                        howItWorksInfo?.data?.display_numbers &&
                            <div className="number_circle">3</div>
                    }
                    <div>
						<div className="section_title" id="infoThreeTitle">{howItWorksInfo?.data?.step_three_title}</div>
						<div className="section_info" id="infoThree">{howItWorksInfo?.data?.step_three}</div>
					</div>
                </Col>
            </Row>
            <div className="notes_section">
                <div className="notes" id="notesSection">
                    <div style={{marginTop:"20px"}}>{howItWorksInfo?.data?.note_1}</div>
                    <div style={{marginTop:"20px"}}>{howItWorksInfo?.data?.note_2}</div>
                    <div style={{marginTop:"20px"}}>{howItWorksInfo?.data?.note_3}</div>
                </div>
            </div>
        </Container>
    </>
  )
}