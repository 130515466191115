import React, { useRef, useState, useEffect } from 'react';
import { Form, Button, Card, Alert, Container} from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

export default function MemberLogin() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        return () =>{ setLoading(false) }
    }, []);

    async function handleSubmit(event){
        event.preventDefault();

        if(emailRef.current.value === null || emailRef.current.value === ''){
            return setError('Email must be filled out');
        }
        if(passwordRef.current.value === null || passwordRef.current.value === ''){
            return setError('Password must be filled out');
        }

        try{
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value); 
            navigate("/member-portal");
        } catch(error){
            setError('Failed to log in: ' + error.message);
        }

        setLoading(false);
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "60vh"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">Member Log in</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} required />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handleSubmit}>Log in</Button>
                            </Form>
                            <div className="w-100 text-center mt-3">
                                <Link to="/member-forgot-password">Forgot Password?</Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        Need an account? <Link to="/member-signup">Sign up</Link>
                    </div>
                </div>
            </Container>
        </>
    )
}


