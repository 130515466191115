import React, { useEffect, useState } from 'react';
import { Image, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import QuantityPicker from '../Components/QuantityPicker';
import AddButton from '../Components/AddButton';
import NavigationButton from './NavigationButton';
import Magnifier from './Magnifier';

export default function ProductDetails({ selectedItem, setSelectedItem, databaseInfo , setSelectedDepartment, setSelectedSubDepartment, screenWidth, addToCartButtonClick, cartUpdated, setCartUpdated, cart}) {
    const [quantity, setQuantity] = useState(1);
    const [selectedImageNumber, setSelectedImageNumber] = useState(0);
    const [maxReached, setMaxReached] = useState('');
    const [cartItemNames, setCartItemNames] = useState([]);
    const [quantityInCart, setQuantityInCart] = useState(0);

    const navigate = useNavigate();

    useEffect(()=>{
        let itemNames = [];
        cart.forEach((item)=>{
            itemNames.push(item.productInfo.itemInfo.name);
        });
        setCartItemNames(itemNames);
    },[cart]);

    useEffect(()=>{
        if(cartItemNames.includes(selectedItem?.itemInfo?.name)){
            setQuantityInCart(cart[cartItemNames.indexOf(selectedItem.itemInfo.name)]?.Quantity);
        }else{
            setQuantityInCart(0);
        }
    }, [cart, cartItemNames, selectedItem]);

    useEffect(()=>{
        setCartUpdated(false);
    }, [setCartUpdated])

    useEffect(() => {
        window.scrollTo({top:0, behavior:'smooth'});
        // console.log(selectedItem)
    }, [selectedItem])

    const handleItemClick = (item, subDep, dep)=>{
        setQuantity(1);
        setMaxReached('');
        setCartUpdated(false);
        setSelectedImageNumber(0);
        setSelectedItem(
            {
                department: dep,
                subdepartment: subDep,
                itemInfo:{
                    name: item,
                    info: databaseInfo.data[dep][subDep][item]
                }
            });
        navigate(`/productdetails`);
    };

    const goToAddressLink = (address)=>{
        navigate(`/${address}`);
    };

    const itemInfo = {textAlign:"left", fontSize:"0.65em", marginTop:"10px"};
    const itemInfoPrice = {textAlign:"left", fontSize:"0.7em", marginTop:"2px"};
    return (
        Object.keys(selectedItem).length > 0 ?
        <>
            <Container fluid style={screenWidth > 992 ? {paddingLeft:"6rem", paddingTop:"2em", paddingBottom:"3em"} : {paddingLeft:"0.5em", paddingTop:"0.5em", paddingBottom:"0.5em"}}>
                <Row>
                    <Col>
                        <div>
                            <span 
                                className='addressSelector'
                                onClick={
                                    ()=>{
                                        setSelectedDepartment('');
                                        setSelectedSubDepartment('');
                                        navigate("/products");
                                    }
                                }
                            >Products</span>
                            {' > '}
                            <span
                                className='addressSelector'
                                onClick={
                                    ()=>{
                                        setSelectedDepartment(selectedItem?.department);
                                        setSelectedSubDepartment('');
                                        navigate(`/${encodeURI(selectedItem?.department)}`);
                                    }
                                }
                            >{selectedItem?.department}</span>
                            {' > '}
                            <span
                                className='addressSelector'
                                onClick={
                                    ()=>{
                                        setSelectedDepartment(selectedItem?.department);
                                        setSelectedSubDepartment(selectedItem?.subdepartment);
                                        navigate(`/${encodeURI(selectedItem?.department)}`);
                                    }
                                }
                            >{selectedItem?.subdepartment}</span>
                            {' > '}
                            <span className='addressSelected'>{selectedItem?.itemInfo?.name}</span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container fluid="lg" className={screenWidth > 992 ? "mt-1" : "mt-4"}>
                <Row>
                    <Col md={12} lg={6}>
                        <Container fluid className="mb-4">
                            <Row>
                                <Col style={{position:"relative"}}>
                                    <Magnifier src={selectedItem?.itemInfo?.info?.image_urls[selectedImageNumber]} alt={selectedItem?.itemInfo?.name} zoom={2} screenWidth={screenWidth} />
                                </Col>
                            </Row>
                            <Row>
                                <Container fluid>
                                <Row className="p-2">
                                {
                                    selectedItem?.itemInfo?.info?.image_urls.map((image_url, index)=>{
                                        if(selectedImageNumber === index){
                                            return(
                                            
                                                <Col xs={3} md={2} key={index} className="thumbnailPicSelected" onClick={()=>setSelectedImageNumber(index)}><Image src={image_url} alt={selectedItem.itemInfo.name} style={{width:"100%", height:"auto"}} /></Col>
                                            )
                                        }else{
                                            return(
                                            
                                                <Col xs={3} md={2} key={index} className="thumbnailPic" onClick={()=>setSelectedImageNumber(index)}><Image src={image_url} alt={selectedItem.itemInfo.name} style={{width:"100%", height:"auto"}} /></Col>
                                            )
                                        }
                                        
                                    })
                                }
                                </Row>
                                </Container>
                            </Row>
                        </Container>
                    </Col>
                    <Col md={12} lg={6}>
                        <Container fluid>
                            <Row >
                                <Col>
                                    {
                                        selectedItem?.itemInfo?.info?.quantity > 0 ?
                                            <div style={{fontSize:"0.8em"}}>{selectedItem?.itemInfo?.info.quantity} IN STOCK</div>
                                        :
                                            <div style={{fontSize:"0.8em"}}>OUT OF STOCK</div>
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3" style={{fontSize:"1.25em"}}>{selectedItem?.itemInfo?.name}</Col>
                            </Row>
                            <Row>
                                {
                                    selectedItem?.itemInfo?.info?.price ? 
                                        <Col className="mt-4 number" style={{fontSize:"0.9em"}}>$ {selectedItem?.itemInfo?.info?.price} <span style={{fontSize:"0.8em"}}>monthly</span></Col>
                                    :
                                        <Col className="mt-4" style={{fontSize:"0.9em"}}>Please enquire - (905) 885-2885</Col>
                                }
                            </Row>
                            <Row>
                                <Col md="auto" className="p-2">
                                    {
                                        (selectedItem?.itemInfo?.info?.quantity - quantityInCart) > 1 && <QuantityPicker quantity={quantity} setQuantity={setQuantity} instock={selectedItem.itemInfo.info.quantity} incart={quantityInCart} maxReached={maxReached} setMaxReached={setMaxReached} setCartUpdated={setCartUpdated} />
                                    }
                                </Col>
                                <Col className="p-2">
                                {
                                    (selectedItem?.itemInfo?.info?.quantity - quantityInCart) >= 1 && selectedItem?.itemInfo?.info?.price !== "" && parseInt(selectedItem?.itemInfo?.info?.price) >= 0 && <AddButton title="ADD TO CART" selectedItem={selectedItem} quantity={quantity} setQuantity={setQuantity} addToCartButtonClick={addToCartButtonClick} />
                                }
                                </Col>
                                {
                                cartItemNames.includes(selectedItem.itemInfo.name) && 
                                <Row md="auto" className='p-2'>
                                    <div style={{display:"flex", flexDirection:"column"}}>
                                        <div style={{fontWeight:"bold", fontSize:"0.8em", cursor:'pointer'}} onClick={()=>goToAddressLink('cart')}>Quantity in Cart: {cart[cartItemNames.indexOf(selectedItem.itemInfo.name)]?.Quantity}</div>
                                        <div style={{fontWeight:"bold", fontSize:"0.8em", cursor:'pointer'}} onClick={()=>goToAddressLink('cart')}><u>view cart</u></div>
                                    </div>
                                </Row>
                            }
                            </Row>
                            {
                                cartUpdated && 
                                <Row>
                                    <div className='checkAdded'>
                                        &#10003;ADDED
                                    </div>
                                    <div>
                                        <NavigationButton title="VIEW CART" address="cart" />
                                    </div>
                                    <div style={{marginTop: '20px'}}>
                                        <NavigationButton title="CONTINUE SHOPPING" address="products" />
                                    </div>
                                </Row>
                            }
                            {
                                selectedItem?.itemInfo?.info?.length || selectedItem?.itemInfo?.info?.width || selectedItem?.itemInfo?.info?.height ?
                                    <Row md="auto" className='mt-3'>
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Dimensions</div>
                                            <div style={{fontSize:"0.8em", display:"flex", flexDirection:"row"}}>
                                                {
                                                    selectedItem?.itemInfo?.info?.length &&
                                                        <div>{selectedItem?.itemInfo?.info?.length}"L</div>
                                                }
                                                {
                                                    selectedItem?.itemInfo?.info?.length && selectedItem?.itemInfo?.info?.width ?
                                                        <div>&nbsp;x&nbsp;{selectedItem?.itemInfo?.info?.width}"W</div>
                                                    :
                                                        selectedItem?.itemInfo?.info?.width &&
                                                            <div>{selectedItem?.itemInfo?.info?.width}"W</div>
                                                }
                                                {
                                                    (selectedItem?.itemInfo?.info?.length || selectedItem?.itemInfo?.info?.width) && selectedItem?.itemInfo?.info?.height ?
                                                        <div>&nbsp;x&nbsp;{selectedItem?.itemInfo?.info?.height}"H</div>
                                                    :
                                                        selectedItem?.itemInfo?.info?.height &&
                                                            <div>{selectedItem?.itemInfo?.info?.height}"H</div>
                                                }
                                            </div>
                                        </div>
                                    </Row>
                                :
                                    ''
                            }
                            {
                                selectedItem?.itemInfo?.info?.color &&
                                    <Row md="auto" className='mt-3'>
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Colour</div>
                                            <div style={{fontSize:"0.8em"}}>{selectedItem?.itemInfo?.info?.color}</div>
                                        </div>
                                    </Row>
                            }
                            {
                                selectedItem?.itemInfo?.info?.description &&
                                    <Row md="auto" className='mt-3'>
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Description</div>
                                            <div style={{fontSize:"0.8em"}}>{selectedItem?.itemInfo?.info?.description}</div>
                                        </div>
                                    </Row>
                            }
                            {
                                selectedItem?.itemInfo?.info?.material &&
                                    <Row md="auto" className='mt-3'>
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Material</div>
                                            <div style={{fontSize:"0.8em"}}>{selectedItem?.itemInfo?.info?.material}</div>
                                        </div>
                                    </Row>
                            }
                            {
                                selectedItem?.itemInfo?.info?.finish &&
                                    <Row md="auto" className='mt-3'>
                                        <div style={{display:"flex", flexDirection:"column"}}>
                                            <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Finish</div>
                                            <div style={{fontSize:"0.8em"}}>{selectedItem?.itemInfo?.info?.finish}</div>
                                        </div>
                                    </Row>
                            }
                            <Row md="auto" className='mt-3'>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Notes</div>
                                    <div style={{fontSize:"0.8em"}}>This image is for illustrative purposes only. The colours shown are accurate within the constraints of lighting, photography and the colour accuracy of your screen.</div>
                                </div>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {
                Object.keys(databaseInfo.data[selectedItem.department][selectedItem.subdepartment]).length > 1 &&
                    <Container fluid="lg" className="mt-4" style={{borderTop:"1px solid lightgrey"}}>
                        <Row>
                            <Col className="mt-3">
                                <div style={{fontWeight:"bold"}}>People Also Viewed</div>
                            </Col>
                        </Row>
                        <Row>
                            {
                                Object.keys(databaseInfo.data[selectedItem.department][selectedItem.subdepartment]).map((item, index)=>{
                                    if(item !== selectedItem?.itemInfo?.name && index < 4 && databaseInfo?.data?.[selectedItem.department]?.[selectedItem.subdepartment]?.[item]?.image_urls?.length > 0 && databaseInfo?.data?.[selectedItem.department]?.[selectedItem.subdepartment]?.[item]?.display === true){
                                        return(
                                            <Col className="mt-3" xs={6} lg={3} key={index}>
                                                <div className="productContainer" onClick={()=>handleItemClick(item, selectedItem.subdepartment, selectedItem.department)} style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                                                    <div style={{display:"flex", alignItems:"flex-start"}}>
                                                        <Image src={databaseInfo.data[selectedItem.department][selectedItem.subdepartment][item].image_urls[0]} alt={item} style={{width:"100%", height:"auto"}} />
                                                    </div>
                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                        {
                                                            databaseInfo.data[selectedItem.department][selectedItem.subdepartment][item]?.quantity <= 0 ?
                                                                <div style={itemInfo}>OUT OF STOCK</div>
                                                            :
                                                                <div style={itemInfo}>{databaseInfo.data[selectedItem.department][selectedItem.subdepartment][item]?.quantity} IN STOCK</div>
                                                        }
                                                        <div style={itemInfoPrice}>${databaseInfo.data[selectedItem.department][selectedItem.subdepartment][item]?.price}</div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }else{
                                        return ''
                                    }
                                })
                            }
                        </Row>
                    </Container>
            }
        </>
        :
        <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', width:'100%', fontSize:'1.2em', fontWeight:'bold', marginTop:'50px'}}>
          Please return to previous page and select an item. Thank you.
        </div>
    )
}

