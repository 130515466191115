export function columnAlgo(databaseInfoDepartmentsLength){
    const FOUR = 4;
    const THREE = 3;
    const TWO = 2;

    const indexRef = [4,3,2];

    // get remainders
    const remainderResults = [
        databaseInfoDepartmentsLength%FOUR,
        databaseInfoDepartmentsLength%THREE,
        databaseInfoDepartmentsLength%TWO
    ];

    const zeroTest = remainderResults.indexOf(0); // determine largest column with 0 remainder
    if(zeroTest >= 0){
        if(indexRef[zeroTest] === TWO && parseInt(databaseInfoDepartmentsLength) === TWO){ // edge case for 2
            return 12/indexRef[zeroTest];
        }
        if(indexRef[zeroTest] > TWO){
            return 12/indexRef[zeroTest];
        }
    };

    // 3. and 4.
    const highestRemainder = Math.max(...remainderResults);
    const highestTest = remainderResults.findIndex((elem)=> elem === highestRemainder); // determine column with the highest remainder
    const allEqual = remainderResults.every((elem)=> elem === highestRemainder); // determine if all remainders are equal
    if(allEqual){
        if(parseInt(databaseInfoDepartmentsLength) >= indexRef[highestTest]){
            return indexRef[highestTest]; // 3. return highest
        }
        return 12/indexRef[indexRef.length-1]; // 4. return smallest
    }

    // 2.
    return 12/indexRef[highestTest]; // return column with the highest remainder
}

// month helper functions
export const getDaysInMonth = (month, year)=>{
    // returns the total days in the month (Feb has 28 days)
    return new Date(year, month, 0).getDate();
};

export const getFirstDayInMonth = (month, year)=>{
    // returns the day of the week of the 1st day of the month (Feb 1 is the second day of the week (0 based))
    // Sunday - 0, Monday -1 , Tuesday - 2, Wednesday - 3, Thursday - 4, Friday - 5, Saturday - 6
    return new Date(year, month-1, 1).getDay();
};
// export const getLastDayInMonth = (month, year)=>{
//      // returns the day of the week of the last day of the month (Feb 28 is the first day of the week (0 based))
//      // Sunday - 0, Monday -1 , Tuesday - 2, Wednesday - 3, Thursday - 4, Friday - 5, Saturday - 6
//      return new Date(year, month, 0).getDay();
// };
export const getLastDateInMonth = (month, year)=>{
    // returns last date of the month (example Feb 28)
    return new Date(year, month, 0).getDate();
};
// const getDayInWeek = (day, month, year)=>{
//     // returns the day of the week of the specified day (Feb 11 is the fifth day of the week (0 based))
//     // Sunday - 0, Monday -1 , Tuesday - 2, Wednesday - 3, Thursday - 4, Friday - 5, Saturday - 6
//     return new Date(year, month-1, day).getDay();
// };
export const getWeekInMonth = (day, month, year)=>{
   // returns the week of the month of the specifed day (0 based)
   const d = new Date(year, month-1, day);
   const date = d.getDate();
   const day2 = d.getDay();
   return Math.abs(Math.ceil((date - 1 - day2) / 7)); 
};
export const getTodaysYear = ()=>{
    const today = new Date();
    const year = today.getFullYear();
    return year;
};
export const getTodaysMonth = ()=>{
    const today = new Date();
    const month = today.getMonth()+1;
    return month;
};
export const getTodaysDay = ()=>{
    const today = new Date();
    const day = today.getDate();
    return day;
};
export const getNumberOfTimeSlots = (timeEnd, timeStart, timeSlotDuration)=>{
    return (parseInt(timeEnd)-parseInt(timeStart))/100/timeSlotDuration;
};
export const getTwelveHourTime = (twentyFourHourTime)=>{
    let designation = 'pm';
    if(twentyFourHourTime < 1200){
        designation = 'am';
    }
    if(twentyFourHourTime < 1299){
        let hour = (Math.floor(twentyFourHourTime/100)).toFixed(0);
        let minutes = ((twentyFourHourTime/100) % 1).toFixed(2);

        if(minutes === "0.00"){
            minutes = parseInt(minutes).toFixed(0);
            if(minutes.length < 2){
                minutes = `${minutes}0`;
            }
        }else{
            minutes = parseFloat(minutes) * 100;
        }
        if(hour === "0"){
            hour = "12";
        }
        return `${hour}:${minutes}${designation}`;
    }else{
        let hour = (Math.floor(twentyFourHourTime/100)-12).toFixed(0);
        let minutes = ((twentyFourHourTime/100) % 1).toFixed(2);
        if(minutes === "0.00"){
            minutes = parseInt(minutes).toFixed(0);
            if(minutes.length < 2){
                minutes = `${minutes}0`;
            }
        }else{
            minutes = parseFloat(minutes) * 100;
        }
        if(hour === "12"){
            designation = "am";
        }
        return `${hour}:${minutes}${designation}`;
    }  
};

export const getHourFromTwentyFourHour = (twentyFourHourTime)=>{
    return (Math.floor(twentyFourHourTime/100)).toFixed(0);
};

export const getMinutesFromTwentyFourHour = (twentyFourHourTime)=>{
    let minutes = ((twentyFourHourTime/100) % 1).toFixed(2);

    if(minutes === "0.00"){
        minutes = parseInt(minutes).toFixed(0);
        if(minutes.length < 2){
            minutes = `${minutes}0`;
        }
    }else{
        minutes = parseFloat(minutes) * 100;
    }
    return minutes
};

export const getTwentyFourHourTime = (twelveHourTime)=>{
    let result;
    let splitMeridiem;
    if(twelveHourTime.includes('am')){
        splitMeridiem = twelveHourTime.split('a')[0];
        const hourMinutesArray = splitMeridiem.split(':');
        const hour = hourMinutesArray[0];
        const minutes = hourMinutesArray[1];
        if(parseInt(hour) === 12){
            result = parseInt(`${parseInt(hour)-12}${minutes}`);
        }else{
            result = parseInt(`${hour}${minutes}`);
        }
    }else{
        splitMeridiem = twelveHourTime.split('p')[0];
        const hourMinutesArray = splitMeridiem.split(':');
        const hour = hourMinutesArray[0];
        const minutes = hourMinutesArray[1];
        if(parseInt(hour) === 12){
            result = parseInt(`${hour}${minutes}`);
        }else{
            
            result = parseInt(`${parseInt(hour)+12}${minutes}`);
        }
        
    }
    return result;
};

export const formatChosenDeliveryDate = (writtenDate)=>{
    const date = new Date(writtenDate);
    return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
};

// from YYYY/m/d to YYYY-mm-dd (2022/4/2 to 2022-04-02)
export const convertToBootstrapDateFormat = (databaseDate)=>{
    if(databaseDate === undefined || databaseDate === null){
        return '';
    }
    const bootstrapFormat = databaseDate.split('/');
    if(bootstrapFormat.length < 3){
        return '';
    }
    return `${bootstrapFormat[0]}-${bootstrapFormat[1].length === 1 ? '0'+bootstrapFormat[1] : bootstrapFormat[1]}-${bootstrapFormat[2].length === 1 ? '0'+bootstrapFormat[2] : bootstrapFormat[2]}`;
};
export const convertToBootstrapDateFormatFromTimeStamp = (timestamp)=>{
    if(timestamp === undefined || timestamp === null){
        return '';
    }
    const date = new Date(timestamp);
    const year = `${date.getFullYear()}`;
    const month = `${date.getMonth()+1}`;
    const day = `${date.getDate()}`;
    return `${year}-${month.length === 1 ? '0'+month : month}-${day.length === 1 ? '0'+day : day}`;
};

export const convertMyDatePickerDateFormat = (boostrapFormat)=>{
    if(boostrapFormat === undefined || boostrapFormat === null){
        return '';
    }
    const databaseFormat = boostrapFormat.split('-');
    if(databaseFormat.length < 3){
        return '';
    }
    return `${databaseFormat[0]}/${databaseFormat[1].split('')[0] === '0' ? databaseFormat[1].split('')[1] : databaseFormat[1]}/${databaseFormat[2].split('')[0] === '0' ? databaseFormat[2].split('')[1] : databaseFormat[2]}`;
};
// end month helper functions

export const createTimeStamp = (partOfDay)=>{
    let tempDate = new Date();
    tempDate.setDate(tempDate.getDate());
    let stamp = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0,0,0);
    if(partOfDay === 'end'){
      stamp = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 23, 59,59,59);
    }
     
    stamp = Date.parse(stamp);
    return stamp;
};
// with conversion of adding a day
export const createTimeStamp2 = (dateSent, partOfDay)=>{
    let tempDate = new Date(dateSent);
    tempDate.setDate(tempDate.getDate() + 1);
    let stamp = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0,0,0);
    if(partOfDay === 'end'){
      stamp = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 23, 59,59,59);
    }
     
    stamp = Date.parse(stamp);
    return stamp;
};

// without adding a day
export const createTimeStamp3 = (dateSent, partOfDay)=>{
    let tempDate = new Date(dateSent);
    let stamp = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0,0,0);
    if(partOfDay === 'end'){
      stamp = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 23, 59,59,59);
    }
     
    stamp = Date.parse(stamp);
    return stamp;
};

export const createChosenDeliveryDateTimeStamp = (dateSent)=>{
    let tempDate = new Date(dateSent);
    let stamp = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate(), 0, 0,0,0);
    stamp = Date.parse(stamp);
    return stamp;
};

// SECURE API KEY - BY ONLY ALLOWING IT TO BE USED ON https://www.limitlessstagingrentals.ca

// video
// https://www.google.com/search?q=google+maps+api+key&oq=google+maps+api+k&gs_lcrp=EgZjaHJvbWUqBwgAEAAYgAQyBwgAEAAYgAQyBwgBEAAYgAQyBggCEEUYOTIHCAMQABiABDIHCAQQABiABDIHCAUQABiABDIHCAYQABiABDIHCAcQABiABDIHCAgQABiABDIHCAkQABiABKgCALACAA&sourceid=chrome&ie=UTF-8#fpstate=ive&vld=cid:5a8ad6c8,vid:hsNlz7-abd0,st:0


// SET DAILY LIMIT QUOTAS FOR API - DAILY BILLABLE QUOTA LIMITS

export const getDistanceFromLocation = (address) =>{
    return new Promise((resolve)=>{
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAtL0HCKCskEhzWUqm097jyWQgVNJbyJ78`;

        fetch(geocodingUrl)
            .then(response => response.json())
            .then(data => {
                if (data.status === "OK") {
                    console.log(data);
                    const location = data.results[0].geometry.location;
                    console.log(location);
                    return location;
                } else {
                    console.error('Geocoding failed:', data.status);
                    // alert('Unable to determine location. Please check the address and try again.');
                    return -1;
                }
            }).then((del_loc)=>{
                if(del_loc === -1){
                    resolve({status: false, distance: -1});
                }else{
                    const dist = calculateDistance(del_loc.lat, del_loc.lng, limitlessCoordinates.lat, limitlessCoordinates.lng);
                    resolve({status: true, distance: dist});
                }
            }).catch(error => {
                console.error('Error fetching geocoding data:', error);
                resolve({status: false, distance: -2});
            });
    });
    // return({status: true, distance: 39.9});
};

const calculateDistance = (lat1, lon1, lat2, lon2) =>{
    const R = 6371; // Radius of the Earth in km
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

const toRadians = (degrees) =>{
    return degrees * (Math.PI / 180);
}

const limitlessCoordinates = {
    "lat": 43.95230369999999,
    "lng": -78.27501939999999
};