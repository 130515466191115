import React, { useState, useEffect } from 'react';

import '../css/quantitypicker.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

export default function QuantityPickerCart({ quantity, instock, setCartUpdated, updateCartFromCartPage, selectedItem }) {
    const [localQuantity, setLocalQuantity] = useState(quantity);
    const [maxReached, setMaxReached] = useState('');

    useEffect(()=>{
        // console.log('assess')
        // console.log(quantity)
        // console.log(instock)
        if(quantity>instock){
            setMaxReached(`( ${instock} IN STOCK )`);
        }else{
            setMaxReached('');
        }
    }, [quantity, instock])
    
    const decrementQuantity = ()=>{
        setMaxReached('');
        let qty = localQuantity;
        qty--;
        if(qty > 0){
            setLocalQuantity(qty);
            updateCartFromCartPage(selectedItem.productInfo, qty);
            setCartUpdated(false);
        }
    };
    
    const incrementQuantity = ()=>{
        setMaxReached('');
        let qty = localQuantity;
        qty++;
        if(qty <= instock){
            setLocalQuantity(qty);
            // console.log(selectedItem);
            updateCartFromCartPage(selectedItem.productInfo, qty);
            setCartUpdated(false);
        }else{
            setMaxReached(`( ${instock} IN STOCK )`);
        }
    };

    const removeItemFromCart = ()=>{
        updateCartFromCartPage(selectedItem.productInfo, 0);
    };


    return (
        <div>
            <div style={{display:'flex'}}>
                <div className="decrement" style={{width:"60px", height:"60px"}} onClick={()=>decrementQuantity()}>
                    <FontAwesomeIcon style={{fontSize: '1em', margin:'5px', color:"grey"}} icon={faMinus} />
                </div>
                {
                    quantity>instock ? 
                        <div className="quantity errorColor" style={{fontSize:"1em",width:"60px", height:"60px"}}>{localQuantity}</div>
                    :
                        <div className="quantity" style={{fontSize:"1em",width:"60px", height:"60px"}}>{localQuantity}</div>
                }
                <div className="increment" style={{width:"60px", height:"60px"}} onClick={()=>incrementQuantity()}>
                    <FontAwesomeIcon style={{fontSize: '1em', margin:'5px', color:"grey"}} icon={faPlus} />
                </div>
                <div onClick={()=>removeItemFromCart()} className="remove">Remove</div>
            </div>
            {
                quantity>instock ? 
                    <div className="errorColor" style={{paddingLeft:"5px", fontSize:"0.8em"}}>{maxReached}</div>
                :
                    <div style={{paddingLeft:"5px", fontSize:"0.8em"}}>{maxReached}</div>
            }
        </div>
    )
}
