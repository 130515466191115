import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { db } from '../firebase-config';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';

import useHomePageStorage from '../CustomHooks/useHomePageStorage';

const ProgressBarHomePage = ( { file, setFile }) =>{
    const { url, progress } = useHomePageStorage(file);
    
    useEffect(() => {
        if(url){
            updateDoc(doc(db, "website_data", "home_page"), {picture: url}).then(()=>{
                setFile(null);
                toast.success('File Uploaded', {
                    style: {backgroundColor:'var(--success)'}
                });
            }).catch((error)=>{
                console.log(error);
                toast.error('Error Uploading File', {
                    style: {backgroundColor:'var(--error)'}
                })
            });
            updateDoc(doc(db, "website_data", "old_home_page_images"), {images: arrayUnion(url)}).then(()=>{
                setFile(null);
                toast.success('Images saved to history', {
                    style: {backgroundColor:'var(--success)'}
                });
            }).catch((error)=>{
                console.log(error);
                toast.error('Error Uploading File', {
                    style: {backgroundColor:'var(--error)'}
                })
            });

        }
    }, [url, setFile])

    return(
        <div className="progress-bar" style={{width: progress + '%', color:'black', backgroundColor: 'black'}}></div>
    )
};

export default ProgressBarHomePage;