import React, { useRef, useState, useEffect } from 'react';
import { Form, Button, Card, Alert, Container} from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import logo from '../images/limitless.png';

export default function EmployeeLogin() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { login, currentUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    let { from } = location.state || { from: { pathname: "/" } }
    useEffect(()=>{
        if((from.pathname === '/employee-portal' || from.pathname === '/employee-images' || from.pathname === '/employee-profile' || from.pathname === '/employee-settings' ) && !currentUser?.admin){
            setError('You do not have admin access, please contact your administrator');
        }else{
            setError('');
        }
        return () =>{ setLoading(false) }
    }, [from, currentUser]);

    async function handleSubmit(event){
        event.preventDefault();

        if(emailRef.current.value === null || emailRef.current.value === ''){
            toast.error('Email must be filled out', {
                style: {backgroundColor:'var(--error)'}
            });
            return setError('Email must be filled out');
        }
        if(passwordRef.current.value === null || passwordRef.current.value === ''){
            toast.error('Password must be filled out', {
                style: {backgroundColor:'var(--error)'}
            });
            return setError('Password must be filled out');
        }

        try{
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value); 
            navigate("/employee-portal");
        } catch(error){
            toast.error(`Faile to log in: ${error.message}`, {
                style: {backgroundColor:'var(--error)'}
            });
            setError('Failed to log in: ' + error.message);
        }

        setLoading(false);
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center mt-5" style={{minHeight: "60vh"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card>
                        <Card.Img variant="top" src={logo} style={{height:"100%"}}/>
                        <Card.Body>
                            <h2 className="text-center mb-4">Employee Log in</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} required />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handleSubmit}>Log in</Button>
                            </Form>
                            <div className="w-100 text-center mt-3">
                                <Link to="/employee-forgot-password">Forgot Password?</Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        Need an account? <Link to="/employee-signup">Sign up</Link>
                    </div>
                </div>
            </Container>
        </>
    )
}