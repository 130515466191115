import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome,faWarehouse, faImage, faCog, faUser, faSignOutAlt, faTachometerAlt, faFolderOpen, faTag } from '@fortawesome/free-solid-svg-icons'

import '@trendmicro/react-sidenav/dist/react-sidenav.css';

export default function EmployeePortal() {
    const { currentUser, logout} = useAuth();
    const navigate = useNavigate();

    const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);

    // Temporary
    // useEffect(()=>{
    //     getDocs(collection(db, 'users')).then((snapShot)=>{
    //         snapShot.forEach((doc)=>{
    //             console.log(doc.data());
    //         })
    //     })
    // }, []);
    // Temporary

    async function handleLogout(){
        try{
            await logout();
            navigate("/employee-login");
        }catch(error){
            toast.error(`Failed to log out: ${error.message}`, {
                style: {backgroundColor:'var(--error)'}
            });
        }
    }

    return (
        <>
            <SideNav
                expanded = {isSideNavExpanded}
                onSelect = {(selected)=>{
                    setIsSideNavExpanded(false);
                }}
                onToggle={(expanded)=>{
                    setIsSideNavExpanded(expanded);
                  }}  
                style={{backgroundColor: 'black', position: 'fixed'}}
            >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="Home">
                    <NavItem
                        eventKey="Home"
                        onClick={()=>{
                            navigate("/employee-portal");
                        }}
                    >
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faTachometerAlt} />
                        </NavIcon>
                        <NavText>
                            Dashboard
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="History">
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faFolderOpen} />
                        </NavIcon>
                        <NavText>
                            Order History
                        </NavText>
                        <NavItem
                            eventKey="EmployeeComplete"
                            onClick={()=>{
                                navigate("/employee-complete");
                            }}
                        >
                            <NavText>Completed Orders</NavText>
                        </NavItem>
                        <NavItem
                            eventKey="EmployeeCancel"
                            onClick={()=>{
                                navigate("/employee-cancel");
                            }}
                        >
                            <NavText>Canceled Orders</NavText>
                        </NavItem>
                    </NavItem>
                    <NavItem
                        eventKey="Warehouse"
                        onClick={()=>{
                            navigate("/employee-warehouse");
                        }}
                    >
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faWarehouse} />
                        </NavIcon>
                        <NavText>
                            Warehouse
                        </NavText>
                    </NavItem>
                    <NavItem
                        eventKey="Add Item Images"
                        onClick={()=>{
                            navigate("/employee-images");
                        }}
                    >
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faImage} />
                        </NavIcon>
                        <NavText>
                            Add Item Images
                        </NavText>
                    </NavItem>
                    <NavItem
                        eventKey="Homepage Info"
                        onClick={()=>{
                            navigate("/employee-homepageinfo");
                        }}
                    >
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faHome} />
                        </NavIcon>
                        <NavText>
                            Homepage Information
                        </NavText>
                    </NavItem>
                    <NavItem
                        eventKey="Coupons"
                        onClick={()=>{
                            navigate("/employee-coupons");
                        }}
                    >
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faTag} />
                        </NavIcon>
                        <NavText>
                            Coupons
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="Administration">
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faCog} />
                        </NavIcon>
                        <NavText>
                            Settings
                        </NavText>
                        <NavItem
                            eventKey="EmployeeCategorySettings"
                            onClick={()=>{
                                navigate("/category-settings");
                            }}
                        >
                            <NavText>Category Settings</NavText>
                        </NavItem>
                        <NavItem
                            eventKey="EmployeeCheckOutSettings"
                            onClick={()=>{
                                navigate("/checkout-settings");
                            }}
                        >
                            <NavText>Checkout Settings</NavText>
                        </NavItem>
                        <NavItem
                            eventKey="EmployeeHowItWorksSettings"
                            onClick={()=>{
                                navigate("/howitworks-settings");
                            }}
                        >
                            <NavText>How It Works Settings</NavText>
                        </NavItem>
                        <NavItem
                            eventKey="EmailSettings"
                            onClick={()=>{
                                navigate("/employee-settings");
                            }}
                        >
                            <NavText>Email Settings</NavText>
                        </NavItem>
                    </NavItem>
                    <NavItem
                        eventKey="EmployeeProfile"
                        onClick={()=>{
                            navigate("/employee-profile");
                        }}
                    >
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faUser} />
                        </NavIcon>
                        <NavText>
                            {currentUser?.email}
                        </NavText>
                    </NavItem>

                    <NavItem eventKey=""></NavItem>

                    <NavItem
                        eventKey="EmployeeLogin"
                        onClick={handleLogout}
                    >
                        <NavIcon>
                            <FontAwesomeIcon style={{fontSize: '1.75em'}} icon={faSignOutAlt} />
                        </NavIcon>
                        <NavText>
                            Log Out
                        </NavText>
                    </NavItem>
                </SideNav.Nav>
            </SideNav>
        </>
    )
}
