import React from 'react';

import '../css/addbutton.css';

export default function AddButton({ title, selectedItem, quantity, setQuantity, addToCartButtonClick }) {
    return (
        <div
            className="button"
            style={{
                width:"100%",
                fontSize:"0.8em",
                height:"60px",
                lineHeight:"40px",
            }}
            onClick={()=>{
                setQuantity(1)
                addToCartButtonClick(selectedItem, quantity)
            }}>
            {title}
        </div>
    )
}
