import React from 'react';
import { ReactComponent as Logo} from '../images/limitless.svg';
import { ReactComponent as SearchIcon } from '../images/search.svg';
import { ReactComponent as ShoppingBagIcon } from '../images/bag.svg';
import { Container, Nav, Navbar, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';

export default function TitleBar( { departments, setSelectedDepartment, searchBarSize, navExpanded, setNavExpanded, setSelectedSubDepartment, searchValue, setSearchValue, secondaryDepartments }){
    const navigate = useNavigate();

    const searchStyle = {
        "width100%": {width: "100%", paddingBottom:"5px", paddingRight:"20px", paddingLeft:"10px"},
        "width50%": {width: "50%", paddingBottom:"5px", paddingRight:"20px", paddingLeft:"10px"}
    }[searchBarSize];

    const search = ()=>{
        // toast.success(`Search for: ${searchValue}`, {
        //     style: {backgroundColor:'var(--success)'}
        // });
        navigate('/search');
        // setSearchValue('');
        setNavExpanded(false);
    };

    const handleDepartmentClick = (department)=>{
        console.log(encodeURI(department))
        setSelectedDepartment(department);
        setSelectedSubDepartment('');
        navigate(`/${encodeURI(department)}`);
        setNavExpanded(false);
    }
    /**
     * <img
            src={logo}
            width={searchBarSize === "width100%" ? "250" : "360"}
            height="60"
            style={{minWidth:"150px"}}
            className="d-inline-block align-top"
            alt="First Class Staging Rentals"
        />
     */

    return(
        <>
            <Navbar collapseOnSelect /*sticky="top" fixed="top" */ expanded={navExpanded} expand="lg" bg="white" variant="light" style={{padding:"0px", margin:"0px", borderBottom:"1px solid lightgrey"}}>
                <Container fluid style={{paddingRight:"0px"}}>
                    <Navbar.Toggle aria-controls="navbarScroll" onClick={()=> setNavExpanded(!navExpanded)} style={{marginTop:"1rem", padding:"0px"}}/>
                    <LinkContainer to="/" onClick={()=> setNavExpanded(false)} >
                        <Navbar.Brand>
                        
                        <Logo style={searchBarSize === "width100%" ? {margin:"10px", width:"240px"} : {margin:"10px", width:"100%", maxWidth:"360px"}} />
                        
                        </Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Collapse id="navbarScroll">
                        <Nav activeKey="/Home" className="me-auto my-2 my-lg-0" navbarScroll>
                            <NavDropdown title="PRODUCTS" id="navbarScrollingDropdown">
                                {
                                    departments.map((department)=>{
                                        return(
                                            <NavDropdown.Item key={department} onClick={()=>handleDepartmentClick(department)}>{department}</NavDropdown.Item>
                                        )
                                    })
                                }
                                {
                                    secondaryDepartments.map((department)=>{
                                    if(departments.includes(department)){
                                        return null;
                                    }else{
                                        return(
                                            <NavDropdown.Item key={department} onClick={()=>handleDepartmentClick(department)}>{department}</NavDropdown.Item>
                                        )
                                    }
                                    })
                                }
                            </NavDropdown>
                            <NavDropdown title="ABOUT" id="navbarScrollingDropdown">
                                <LinkContainer to="/how_it_works"><NavDropdown.Item onClick={() => setNavExpanded(false)}>How it Works</NavDropdown.Item></LinkContainer>
                                <LinkContainer to="/terms_of_rental_agreement"><NavDropdown.Item onClick={() => setNavExpanded(false)}>Terms of Rental Agreement</NavDropdown.Item></LinkContainer>
                            </NavDropdown>
                            <LinkContainer to="/member-portal" className="d-flex no-wrap"><Nav.Link onClick={() => setNavExpanded(false)}>MEMBERS</Nav.Link></LinkContainer>
                            <LinkContainer to="/cart" className="d-flex no-wrap"><Nav.Link onClick={() => setNavExpanded(false)}>CART<ShoppingBagIcon className="align-self-center" /></Nav.Link></LinkContainer>
                        </Nav>
                        <Form className="d-flex" style={searchStyle}>
                            <FormControl
                                type="search"
                                placeholder="Search Products"
                                className="me-2"
                                aria-label="Search"
                                style={{minWidth:"100px"}}
                                value={searchValue}
                                onChange={(event)=>{
                                    setSearchValue(event.target.value)
                                }}
                                onKeyDown={(event)=>{
                                    if(event.key === 'Enter') {
                                        event.preventDefault();
                                        search();
                                    }
                                }}
                            />
                            <Button variant="outline-dark" onClick={(event)=>{
                                    event.preventDefault();
                                    search();
                                }}><SearchIcon /></Button>
                        </Form>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}