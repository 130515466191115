import React, {useEffect, useState, useRef} from 'react';
import { useAuth } from '../Context/AuthContext';
import { Container, Row, Col, Button, Tab, Tabs, Accordion, Modal, Form} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { convertToBootstrapDateFormatFromTimeStamp, createTimeStamp3, createTimeStamp2, createTimeStamp } from '../HelperFunctions/helper_functions';

import { db } from '../firebase-config';
import { collection, getDocs, addDoc, doc, deleteDoc, setDoc } from 'firebase/firestore';

export default function EmployeeCoupons() {
    const { currentUser } = useAuth();
    const [couponList, setCouponList] = useState([]);
    const [addCouponModalShow, setAddCouponModalShow] = useState(false);
    const [deleteCouponModalShow, setDeleteCouponModalShow] = useState(false);
    const [editCouponModalShow, setEditCouponModalShow] = useState(false);
    const chosenCouponIndex = useRef(null);

    const [activeKey, setActiveKey] = useState(null);

    useEffect(()=>{
        getDocs(collection(db, "coupons")).then((querySnapshot)=>{
            let tempList = [];
            querySnapshot.forEach((coupon)=>{
                let tempData = coupon.data();
                if(tempData.status && tempData.expiry_type === "date"){
                    if(Math.ceil((tempData.expiry - Date.parse(new Date())) / 86400000) <= 0){
                        tempData.status = false;
                    }
                }
                if(tempData.status && tempData.expiry_type === "usage"){
                    if(tempData.expiry === tempData.usage){
                        tempData.status = false;
                    }
                }
                tempList.push({...tempData, ID: coupon.id});
            });
            tempList.sort((a, b) => b.status - a.status);
            return tempList;
        }).then((tempList)=>{
            
            return tempList;
        }).then((tempList)=>{
            setCouponList(tempList);
        }).catch((error)=>{
            toast.error(`Error loading coupon list: ${error}`, {
                style: {backgroundColor:'var(--error)'}
            });
        });
    }, []);

    const handleActiveKey = (key)=>{
        setActiveKey(key);
    };

    const EditCouponModal = (props) =>{
        const [couponName, setCouponName] = useState(couponList[chosenCouponIndex.current]?.name !== undefined ? couponList[chosenCouponIndex.current].name : "");
        const [couponCode, setCouponCode] = useState(couponList[chosenCouponIndex.current]?.code);
        const [discountType, setDiscountType] = useState(couponList[chosenCouponIndex.current]?.discount_type);
        const [discountAmount, setDiscountAmount] = useState(couponList[chosenCouponIndex.current]?.discount_amount);
        const [minimumSpend, setMinimumSpend] = useState(couponList[chosenCouponIndex.current]?.min_limit);
        const [expiryType, setExpiryType] = useState(couponList[chosenCouponIndex.current]?.expiry_type);
        const [expiry, setExpiry] = useState(couponList[chosenCouponIndex.current]?.expiry);
        const [membershipRequired, setMembershipRequired] = useState(couponList[chosenCouponIndex.current]?.member);
        const [member, setMember] = useState(couponList[chosenCouponIndex.current]?.member_email !== undefined ? couponList[chosenCouponIndex.current].member_email : "");
        const [memberCode, setMemberCode] = useState(couponList[chosenCouponIndex.current]?.member_code);
        const [status, setStatus] = useState(couponList[chosenCouponIndex.current]?.status);
        const [usage, setUsage] = useState(couponList[chosenCouponIndex.current]?.usage);

        const [memberChoices, setMemberChoices] = useState([]);

        const discountTypeChoices = ["dollar", "percent"];
        const expiryTypeChoices = ["date", "usage"];
        const membershipRequiredChoices = ["No", "Yes"];
        const statusChoices = ["Active", "Inactive"];

        useEffect(()=>{
            let isMounted = true;

            getDocs(collection(db, "users")).then((querySnapshot)=>{
                let tempList = [{email: "", ID: ""}];
                querySnapshot.forEach((user)=>{
                    tempList.push({email: user.data().email, ID: user.id});
                });
                tempList.sort((a, b) => b.email - a.email);
                return tempList;
            }).then((tempList)=>{
                // console.log(tempList)
                if(isMounted){
                    setMemberChoices(tempList);
                }
            }).catch((error)=>{
                toast.error(`Error loading user list: ${error}`, {
                    style: {backgroundColor:'var(--error)'}
                });
            });

            return ()=>{
                isMounted = false;
            }
        }, []);

        const findIDByEmail = (email) =>{
            for (let i = 0; i < memberChoices.length; i++) {
                if (memberChoices[i].email === email) {
                    return memberChoices[i].ID;
                }
            }
            return ""; // Return empty string if the email is not found
        };

        const handleMemberAssignment = (value) =>{
            // value === member email
            setMember(value);
            setMemberCode(findIDByEmail(value));
        };

        const handleStatus = (value) =>{
            if(value === 'Active'){
                setStatus(true)
            }else{
                setStatus(false);
            }
        };

        const handleMembershipRequired = (value) =>{
            setMember("");
            setMemberCode("");
            if(value === 'Yes'){
                setMembershipRequired(true);
            }else{
                setMembershipRequired(false);
            }
        };

        const handleExpiryType = (value) =>{
            if(value === 'date'){
                setExpiryType(value);
                if(couponList[chosenCouponIndex.current].expiry < 100000000000){
                    setExpiry(createTimeStamp('end'))
                }else{
                    setExpiry(createTimeStamp3(couponList[chosenCouponIndex.current].expiry, 'end'))
                }
            }else{
                setExpiryType(value);
                if(couponList[chosenCouponIndex.current].expiry > 100000000000){
                    setExpiry(1);
                }else{
                    setExpiry(couponList[chosenCouponIndex.current].expiry);
                }
            }
        };

        const editCoupon = async ()=>{
            if(expiryType === 'usage' && usage >= expiry && status === true){
                toast.error('Times used should not be greater than or equal to the Expiry unless the Status is Inactive. Either make Inactive or increase Expiry', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(expiryType === 'date' && status === true){
                if(Math.ceil((expiry - Date.parse(new Date())) / 86400000) <= 0){
                    toast.error('Days Left should not be less than one, unless the Status is Inactive. Either make Inactive or edit the Expiry date', {
                        style: {backgroundColor:'var(--error)'}
                    });
                    return null;
                }
            }
            if(isNaN(expiry)){
                toast.error('Expiry must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(usage)){
                toast.error('Times used must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(discountAmount)){
                toast.error('Discount Amount must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(minimumSpend)){
                toast.error('Minimum Spend must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(discountAmount)){
                toast.error('Discount Amount must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(discountType === 'dollar' && discountAmount > minimumSpend){
                toast.error('When Discount Type is "dollar", the Minimum Spend must be greater than or equal to the Discount Amount, otherwise a negative balance could result.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }

            const couponInfo = {
                _created: Date.parse(new Date()),
                code: couponCode,
                discount_amount: discountAmount,
                discount_type: discountType,
                expiry: expiry,
                expiry_type: expiryType,
                member: membershipRequired,
                member_code: memberCode,
                member_email: member,
                min_limit: minimumSpend,
                name: couponName,
                status: status,
                usage: usage,
                ID: couponList[chosenCouponIndex.current].ID
            }
            try{
                const couponRef = doc(db, 'coupons', couponList[chosenCouponIndex.current].ID);
                await setDoc(couponRef, couponInfo, {merge: true});
                let tempList = [...couponList];
                tempList[chosenCouponIndex.current] = couponInfo;
                tempList.sort((a, b) => b.status - a.status);
                setCouponList(tempList);
                toast.success(`Coupon has been saved: ${couponInfo.name}`, {
                    style: {backgroundColor:'var(--success)'}
                });
                setEditCouponModalShow(false);
                chosenCouponIndex.current = null;
                setActiveKey(null);
                return null;
            }catch(error){
                toast.error(`Error saving coupon: ${error} -> If error persists please contact your admin.`, {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
        };

        return(
            <Modal
                {...props}
                size="lg"
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit Coupon
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Name:</Row><Row>(min 3 characters)</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponName} onChange={(e) => setCouponName(e.target.value)} disabled />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Coupon Code:</Row><Row>(used to redeem)</Row><Row>(min 3 characters)</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} disabled />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Discount Type:</Row></Col>
                            <Col>
                                <Form.Select value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                                    {
                                         discountTypeChoices.map((type)=>{
                                            return <option key={`discount_type_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Discount Amount:</Row></Col>
                            <Col>
                                <Form.Control type="number" value={discountAmount} onChange={(e) => setDiscountAmount(parseInt(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Minimum Spend:</Row></Col>
                            <Col>
                                <Form.Control type="number" value={minimumSpend} onChange={(e) => setMinimumSpend(parseInt(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Example:</Row></Col>
                            <Col>
                                <Row>
                                    {
                                        discountAmount !== 0 && !isNaN(discountAmount) && !isNaN(minimumSpend) ?
                                            <div>
                                                <div>1. If Spend =&gt; ${minimumSpend} then Discount = {discountType === "dollar" && "$"}{discountAmount}{discountType === "percent" && "%"}</div>
                                                {
                                                    discountType === "dollar" ? 
                                                        <div>
                                                            <div>Customer Charged: ${minimumSpend-discountAmount}</div>
                                                            <div style={{color:"red"}}>{discountType === "dollar" && discountAmount > minimumSpend && "RECOMMENDED TO ENTER A MINIMUM SPEND"}</div>
                                                        </div>
                                                    :
                                                        <div>Customer Charged: ${minimumSpend-(minimumSpend*(discountAmount/100))} or discounted (-${minimumSpend*(discountAmount/100)})</div>
                                                }
                                            </div>
                                        :
                                            <div></div>
                                    }
                                    {
                                        discountAmount !== 0 && !isNaN(discountAmount) && !isNaN(minimumSpend) && minimumSpend < 1000 ?
                                            <div style={{marginTop:"10px"}}>
                                                <div>2. If Spend = $1000 then Discount = {discountType === "dollar" && "$"}{discountAmount}{discountType === "percent" && "%"}</div>
                                                {
                                                    discountType === "dollar" ? 
                                                        <div>Customer Charged: ${1000-discountAmount}</div>
                                                    :
                                                        <div>Customer Charged: ${1000-(1000*(discountAmount/100))} or discounted (-${1000*(discountAmount/100)})</div>
                                                }
                                            </div>
                                        :
                                            <div></div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Expiry Type:</Row></Col>
                            <Col>
                                <Form.Select value={expiryType} onChange={(e) => handleExpiryType(e.target.value)}>
                                    {
                                         expiryTypeChoices.map((type)=>{
                                            return <option key={`expiry_type_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        {
                            expiryType === "usage" ?
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Expiry:</Row></Col>
                                    <Col>
                                        <Form.Control type="number" value={expiry} onChange={(e) => setExpiry(parseInt(e.target.value))} />
                                    </Col>
                                </Row>
                            :
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Expiry:</Row></Col>
                                    <Col>
                                        <Form.Control type="date" value={convertToBootstrapDateFormatFromTimeStamp(expiry)} onChange={(e) => setExpiry(createTimeStamp2(e.target.value, 'end'))} />
                                    </Col>
                                </Row>
                        }
                        <Row className="mt-3">
                            <Col xs={4}><Row>Times used:</Row></Col>
                            <Col>
                                <Form.Control type="number" value={usage} onChange={(e) => setUsage(parseInt(e.target.value))} disabled />
                            </Col>
                        </Row>
                        {
                            expiryType === "usage" ?
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Usages Left:</Row></Col>
                                    <Col>
                                        {isNaN(expiry - usage) ? "" : expiry - usage}
                                    </Col>
                                </Row>
                            :
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Days Left:</Row></Col>
                                    <Col>
                                        {Math.ceil((expiry - Date.parse(new Date())) / 86400000)}
                                    </Col>
                                </Row>
                        }
                        <Row className="mt-3">
                            <Col xs={4}><Row>Membership Required:</Row></Col>
                            <Col>
                                <Form.Select value={membershipRequired ? 'Yes' : 'No'} onChange={(e) => handleMembershipRequired(e.target.value)}>
                                    {
                                         membershipRequiredChoices.map((type)=>{
                                            return <option key={`membershipRequired_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        {
                            membershipRequired &&
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Member:</Row><Row>(choose member</Row><Row>or leave blank</Row><Row>for all members)</Row></Col>
                                    <Col>
                                        <Form.Select value={member} onChange={(e) => handleMemberAssignment(e.target.value)}>
                                            {
                                                memberChoices.map((type)=>{
                                                    return <option key={`membership_${type.email}`} value={type.email}>{type.email}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                </Row>
                        }
                        <Row className="mt-3">
                            <Col xs={4}><Row>Status:</Row></Col>
                            <Col>
                                <Form.Select value={status ? 'Active' : 'Inactive'} onChange={(e) => handleStatus(e.target.value)}>
                                    {
                                         statusChoices.map((type)=>{
                                            return <option key={`status_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-4 justify-content-center">
                        <Button className="justify-content-center" style={{width:"200px"}} onClick={()=>editCoupon()}>Save Coupon</Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    };

    const DeleteCouponModal = (props) =>{
        if(chosenCouponIndex.current === null){
            return null;
        }
        const deleteCoupon = ()=>{
            const oldName = couponList[chosenCouponIndex.current].name
            deleteDoc(doc(db, 'coupons', couponList[chosenCouponIndex.current].ID)).then(()=>{
                setDeleteCouponModalShow(false);
            }).then(()=>{
                const tempList = [...couponList];
                if (chosenCouponIndex.current > -1 && chosenCouponIndex.current < tempList.length) {
                    tempList.splice(chosenCouponIndex.current, 1);
                }
                return tempList;
            }).then((tempList)=>{
                chosenCouponIndex.current = null;
                toast.success(`Successfully deleted coupon: ${oldName}`, {
                    style: {backgroundColor:'var(--success)'}
                });
                setCouponList(tempList);
                setActiveKey(null);
            }).catch((error)=>{
                toast.error(`Error deleting coupon: ${error}`, {
                    style: {backgroundColor:'var(--error)'}
                });
            })
        };
        return(
            <Modal
                {...props}
                size="lg"
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete Coupon
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Name:</Row><Row>(min 3 characters)</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponList[chosenCouponIndex.current].name} disabled />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Coupon Code:</Row><Row>(used to redeem)</Row><Row>(min 3 characters)</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponList[chosenCouponIndex.current].code} disabled />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Discount Type:</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponList[chosenCouponIndex.current].discount_type} disabled />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Discount Amount:</Row></Col>
                            <Col>
                                <Form.Control type="number" value={couponList[chosenCouponIndex.current].discount_amount} disabled />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Minimum Spend:</Row></Col>
                            <Col>
                                <Form.Control type="number" value={couponList[chosenCouponIndex.current].min_limit} disabled />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Expiry Type:</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponList[chosenCouponIndex.current].expiry_type} disabled />
                            </Col>
                        </Row>
                        {
                            couponList[chosenCouponIndex.current].expiry_type === "usage" ?
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Expiry:</Row></Col>
                                    <Col>
                                        <Form.Control type="number" value={couponList[chosenCouponIndex.current].expiry} disabled />
                                    </Col>
                                </Row>
                            :
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Expiry:</Row></Col>
                                    <Col>
                                        <Form.Control type="date" value={convertToBootstrapDateFormatFromTimeStamp(couponList[chosenCouponIndex.current].expiry)} disabled />
                                    </Col>
                                </Row>
                        }
                        <Row className="mt-3">
                            <Col xs={4}><Row>Membership Required:</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponList[chosenCouponIndex.current].member ? 'Yes' : 'No'} disabled />
                            </Col>
                        </Row>
                        {
                            couponList[chosenCouponIndex.current].member &&
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Member:</Row><Row>(choose member</Row><Row>or leave blank</Row><Row>for all members)</Row></Col>
                                    <Col>
                                        <Form.Control type="text" value={couponList[chosenCouponIndex.current].member_email} disabled />
                                    </Col>
                                </Row>
                        }
                        <Row className="mt-3">
                            <Col xs={4}><Row>Status:</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponList[chosenCouponIndex.current].status ? 'Active' : 'Inactive'} disabled />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-4 justify-content-center">
                        <Button className="justify-content-center" style={{width:"200px", backgroundColor:'var(--error)'}} onClick={()=>deleteCoupon()}>Delete Coupon</Button>
                    </Row>
                    <Row className="mt-4 justify-content-center">
                        Warning: Irreversible
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    };

    const AddCouponModal = (props)=>{
        const [couponName, setCouponName] = useState("");
        const [couponCode, setCouponCode] = useState("");
        const [discountType, setDiscountType] = useState("dollar");
        const [discountAmount, setDiscountAmount] = useState(0);
        const [minimumSpend, setMinimumSpend] = useState(0);
        const [expiryType, setExpiryType] = useState("date");
        const [expiry, setExpiry] = useState(createTimeStamp('end'));
        const [membershipRequired, setMembershipRequired] = useState(false);
        const [member, setMember] = useState("");
        const [memberCode, setMemberCode] = useState("");
        const [status, setStatus] = useState(true);

        const memberChoices = useRef([{email: "", ID: ""}]);

        const discountTypeChoices = ["dollar", "percent"];
        const expiryTypeChoices = ["date", "usage"];
        const membershipRequiredChoices = ["No", "Yes"];
        const statusChoices = ["Active", "Inactive"];

        useEffect(()=>{
            getDocs(collection(db, "users")).then((querySnapshot)=>{
                let tempList = [{email: "", ID: ""}];
                querySnapshot.forEach((user)=>{
                    tempList.push({email: user.data().email, ID: user.id});
                });
                tempList.sort((a, b) => b.email - a.email);
                return tempList;
            }).then((tempList)=>{
                // console.log(tempList)
                memberChoices.current = tempList;
            }).catch((error)=>{
                toast.error(`Error loading user list: ${error}`, {
                    style: {backgroundColor:'var(--error)'}
                });
            });
        }, []);

        const findIDByEmail = (email) =>{
            for (let i = 0; i < memberChoices.current.length; i++) {
                if (memberChoices.current[i].email === email) {
                    return memberChoices.current[i].ID;
                }
            }
            return ""; // Return empty string if the email is not found
        };

        const doesCouponNameExist = (name) =>{
            for (let i = 0; i < couponList.length; i++) {
                if (couponList[i].name === name) {
                    return true;
                }
            }
            return false; // Return false if the name is not found
        };

        const doesCouponCodeExist = (code) =>{
            for (let i = 0; i < couponList.length; i++) {
                if (couponList[i].code === code) {
                    return true;
                }
            }
            return false; // Return false if the code is not found
        };

        const handleMemberAssignment = (value) =>{
            // value === member email
            setMember(value);
            setMemberCode(findIDByEmail(value));
        };

        const handleStatus = (value) =>{
            if(value === 'Active'){
                setStatus(true)
            }else{
                setStatus(false);
            }
        };

        const handleMembershipRequired = (value) =>{
            setMember("");
            setMemberCode("");
            if(value === 'Yes'){
                setMembershipRequired(true);
            }else{
                setMembershipRequired(false);
            }
        };

        const handleExpiryType = (value) =>{
            if(value === 'date'){
                setExpiryType(value);
                setExpiry(createTimeStamp('end'));
            }else{
                setExpiryType(value);
                setExpiry(1);
            }
        };

        const saveNewCoupon = async ()=>{
            if(couponName.length < 3){
                toast.error('Coupon Name does not meet all requirements.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(couponCode.length < 3){
                toast.error('Coupon Code does not meet all requirements.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(doesCouponNameExist(couponName)){
                toast.error('Coupon Name already exists, choose a different Name.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(doesCouponCodeExist(couponCode)){
                toast.error('Coupon Code already exists, choose a different Code.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(expiry)){
                toast.error('Expiry must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(discountAmount)){
                toast.error('Discount Amount must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(minimumSpend)){
                toast.error('Minimum Spend must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(isNaN(discountAmount)){
                toast.error('Discount Amount must be filled out.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(discountType === 'dollar' && discountAmount > minimumSpend){
                toast.error('When Discount Type is "dollar", the Minimum Spend must be greater than or equal to the Discount Amount, otherwise a negative balance could result.', {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
            if(expiryType === 'date' && status === true){
                if(Math.ceil((expiry - Date.parse(new Date())) / 86400000) <= 0){
                    toast.error('The Expiry must be todays date or in the future, cannot be the past. Either make Inactive or edit the Expiry date', {
                        style: {backgroundColor:'var(--error)'}
                    });
                    return null;
                }
            }
            const couponInfo = {
                _created: Date.parse(new Date()),
                code: couponCode,
                discount_amount: discountAmount,
                discount_type: discountType,
                expiry: expiry,
                expiry_type: expiryType,
                member: membershipRequired,
                member_code: memberCode,
                member_email: member,
                min_limit: minimumSpend,
                name: couponName,
                status: status,
                usage: 0
            }
            try{
                const newCouponRef = await addDoc(collection(db, 'coupons'), couponInfo);
                let tempList = [...couponList];
                tempList.push({...couponInfo, ID: newCouponRef.id});
                tempList.sort((a, b) => b.status - a.status);
                setCouponList(tempList);
                toast.success(`New coupon has been saved: ${couponInfo.name}`, {
                    style: {backgroundColor:'var(--success)'}
                });
                setAddCouponModalShow(false);
                setActiveKey(null);
                return null;
            }catch(error){
                toast.error(`Error saving new coupon: ${error} -> If error persists please contact your admin.`, {
                    style: {backgroundColor:'var(--error)'}
                });
                return null;
            }
        };

        return(
            <Modal
                {...props}
                size="lg"
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Coupon
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Name:</Row><Row>(min 3 characters)</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponName} onChange={(e) => setCouponName(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Coupon Code:</Row><Row>(used to redeem)</Row><Row>(min 3 characters)</Row></Col>
                            <Col>
                                <Form.Control type="text" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Discount Type:</Row></Col>
                            <Col>
                                <Form.Select value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                                    {
                                         discountTypeChoices.map((type)=>{
                                            return <option key={`discount_type_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Discount Amount:</Row></Col>
                            <Col>
                                <Form.Control type="number" value={discountAmount} onChange={(e) => setDiscountAmount(parseInt(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Minimum Spend:</Row></Col>
                            <Col>
                                <Form.Control type="number" value={minimumSpend} onChange={(e) => setMinimumSpend(parseInt(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Example:</Row></Col>
                            <Col>
                                <Row>
                                    {
                                        discountAmount !== 0 && !isNaN(discountAmount) && !isNaN(minimumSpend) ?
                                            <div>
                                                <div>1. If Spend =&gt; ${minimumSpend} then Discount = {discountType === "dollar" && "$"}{discountAmount}{discountType === "percent" && "%"}</div>
                                                {
                                                    discountType === "dollar" ? 
                                                        <div>
                                                            <div>Customer Charged: ${minimumSpend-discountAmount}</div>
                                                            <div style={{color:"red"}}>{discountType === "dollar" && discountAmount > minimumSpend && "RECOMMENDED TO ENTER A MINIMUM SPEND"}</div>
                                                        </div>
                                                    :
                                                        <div>Customer Charged: ${minimumSpend-(minimumSpend*(discountAmount/100))} or discounted (-${minimumSpend*(discountAmount/100)})</div>
                                                }
                                            </div>
                                        :
                                            <div></div>
                                    }
                                    {
                                        discountAmount !== 0 && !isNaN(discountAmount) && !isNaN(minimumSpend) && minimumSpend < 1000 ?
                                            <div style={{marginTop:"10px"}}>
                                                <div>2. If Spend = $1000 then Discount = {discountType === "dollar" && "$"}{discountAmount}{discountType === "percent" && "%"}</div>
                                                {
                                                    discountType === "dollar" ? 
                                                        <div>Customer Charged: ${1000-discountAmount}</div>
                                                    :
                                                        <div>Customer Charged: ${1000-(1000*(discountAmount/100))} or discounted (-${1000*(discountAmount/100)})</div>
                                                }
                                            </div>
                                        :
                                            <div></div>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}><Row>Expiry Type:</Row></Col>
                            <Col>
                                <Form.Select value={expiryType} onChange={(e) => handleExpiryType(e.target.value)}>
                                    {
                                         expiryTypeChoices.map((type)=>{
                                            return <option key={`expiry_type_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        {
                            expiryType === "usage" ?
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Expiry:</Row></Col>
                                    <Col>
                                        <Form.Control type="number" value={expiry} onChange={(e) => setExpiry(parseInt(e.target.value))} />
                                    </Col>
                                </Row>
                            :
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Expiry:</Row></Col>
                                    <Col>
                                        <Form.Control type="date" value={convertToBootstrapDateFormatFromTimeStamp(expiry)} onChange={(e) => setExpiry(createTimeStamp2(e.target.value, 'end'))} />
                                    </Col>
                                </Row>
                        }
                        <Row className="mt-3">
                            <Col xs={4}><Row>Membership Required:</Row></Col>
                            <Col>
                                <Form.Select value={membershipRequired ? 'Yes' : 'No'} onChange={(e) => handleMembershipRequired(e.target.value)}>
                                    {
                                         membershipRequiredChoices.map((type)=>{
                                            return <option key={`membershipRequired_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        {
                            membershipRequired &&
                                <Row className="mt-3">
                                    <Col xs={4}><Row>Member:</Row><Row>(choose member</Row><Row>or leave blank</Row><Row>for all members)</Row></Col>
                                    <Col>
                                        <Form.Select value={member} onChange={(e) => handleMemberAssignment(e.target.value)}>
                                            {
                                                memberChoices.current.map((type)=>{
                                                    return <option key={`membership_${type.email}`} value={type.email}>{type.email}</option>
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                </Row>
                        }
                        <Row className="mt-3">
                            <Col xs={4}><Row>Status:</Row></Col>
                            <Col>
                                <Form.Select value={status ? 'Active' : 'Inactive'} onChange={(e) => handleStatus(e.target.value)}>
                                    {
                                         statusChoices.map((type)=>{
                                            return <option key={`status_${type}`} value={type}>{type}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Row className="mt-4 justify-content-center">
                        <Button className="justify-content-center" style={{width:"200px"}} onClick={()=>saveNewCoupon()}>Save New Coupon</Button>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    };

    return(
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        Coupons
                    </Row>
                    <Row className="mt-4 justify-content-center">
                        <Button className="justify-content-center" style={{width:"200px"}} onClick={()=>setAddCouponModalShow(true)}>Add New Coupon</Button>
                    </Row>
                </Container>
                <Container fluid className="mt-5" style={{fontSize:"1.0em", letterSpacing:"0.2em"}}>
                    <Accordion activeKey={activeKey} onSelect={handleActiveKey}>
                    {
                        couponList.map((coupon, index)=>{
                            // console.log(coupon)
                            return(
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header>{coupon.name} - <span style={{color:`${coupon.status ? "green" : "red"}`}}>{coupon.status ? "Active" : "Expired"}</span></Accordion.Header>
                                        <Accordion.Body>
                                            <Tabs
                                                defaultActiveKey="general"
                                                id="fill-tab-example"
                                                className="mb-5"
                                                fill
                                            >
                                                <Tab eventKey="general" title="General Info">
                                                    <Row>
                                                        <Col>Name: {coupon.name}</Col>
                                                        <Col>Code: {coupon.code}</Col>
                                                        <Col>Status: <span style={{color:`${coupon.status ? "green" : "red"}`}}>{coupon.status ? "Active" : "Expired"}</span></Col>
                                                        <Col>Usage: {coupon.usage}
                                                            {
                                                                coupon.expiry_type === 'usage' ?
                                                                    <div>(Usages Left: {coupon.expiry - coupon.usage})</div>
                                                                :
                                                                    <div>(Days Left: {Math.ceil((coupon.expiry - Date.parse(new Date())) / 86400000)})</div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Tab>
                                                <Tab eventKey="type" title="Dicsount Type">
                                                    <Row>
                                                        <Col>Discount Type: {coupon.discount_type}</Col>
                                                        <Col>Discount Amount: {coupon.discount_amount}</Col>
                                                        <Col>Minimum Spend: {coupon.min_limit}</Col>
                                                        <Col></Col>
                                                    </Row>
                                                </Tab>
                                                <Tab eventKey="restriction" title="Restrictions">
                                                    <Row>
                                                        <Col>Expiry Type: {coupon.expiry_type}</Col>
                                                        <Col>Expiry: {coupon.expiry_type === "date" ? convertToBootstrapDateFormatFromTimeStamp(coupon.expiry) : coupon.expiry}</Col>
                                                        <Col>Membership Required: {coupon.member ? "Yes" : "No"}</Col>
                                                        <Col>Email: {coupon.member_email}</Col>
                                                    </Row>
                                                </Tab>
                                            </Tabs>
                                            <Row className="mt-4 justify-content-center">
                                                <Button className="justify-content-center" style={{width:"200px", margin:"10px"}} onClick={()=>{
                                                    chosenCouponIndex.current = index;
                                                    setEditCouponModalShow(true);
                                                }}>Edit Coupon</Button>
                                                <Button className="justify-content-center" style={{width:"200px", margin:"10px"}} onClick={()=>{
                                                    chosenCouponIndex.current = index;
                                                    setDeleteCouponModalShow(true);
                                                }}>Delete Coupon</Button>
                                            </Row>
                                        </Accordion.Body>
                                </Accordion.Item>
                            )
                        })
                    }
                    </Accordion>
                </Container>
                <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "10vh"}}>
                    <div style={{maxWidth: "600px"}}>
                        {currentUser?.email}
                    </div>
                </Container>
            </Container>
            <AddCouponModal
                show={addCouponModalShow}
                onHide={()=>{
                    setAddCouponModalShow(false);
                }}
            />
            <DeleteCouponModal
                show={deleteCouponModalShow}
                onHide={()=>{
                    setDeleteCouponModalShow(false);
                }}
            />
            <EditCouponModal
                show={editCouponModalShow}
                onHide={()=>{
                    setEditCouponModalShow(false);
                }}
            />
        </>
    );
};