import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function Filter({ databaseInfo, selectedDepartment, selectedSubDepartment, handleDepartmentClick, handleSubDepartmentClick, handleChevronClick, openFilters, secondaryDepInfo}) {
    return (
        <>
            <div style={{borderTop:"1px solid grey", marginBottom:"10px"}}>
                <div style={{marginTop:"10px", marginRight:"10px", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    {
                        selectedDepartment === '' ?
                            <div style={{cursor:"pointer", fontWeight:"bold"}} onClick={()=>handleDepartmentClick('')}>All Products</div>
                        :
                            <div style={{cursor:"pointer"}} onClick={()=>handleDepartmentClick('')}>All Products</div>
                    }
                </div>
            </div>
            {
                databaseInfo?.departments?.map((department)=>{
                    return(
                        <div key={department} style={{borderTop:"1px solid grey"}}>
                            <div style={{marginTop:"10px", marginRight:"10px", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                {
                                    department === selectedDepartment ?
                                        <div style={{cursor:"pointer", fontWeight:"bold"}} onClick={()=>handleDepartmentClick(department)}>{department}</div>
                                    :
                                        <div style={{cursor:"pointer"}} onClick={()=>handleDepartmentClick(department)}>{department}</div>
                                }
                                <FontAwesomeIcon style={{fontSize: '0.75em', cursor:"pointer"}} onClick={()=>handleChevronClick(department)} icon={openFilters.includes(department) ? faChevronDown : faChevronUp} />
                            </div>
                            <ul style={{listStyleType:"none"}}>
                            {
                                openFilters.includes(department) &&
                                    Object.keys(databaseInfo.data[department]).map((subDepartment)=>{
                                        return(
                                            Object.keys(databaseInfo.data[department][subDepartment]).length > 0 ?
                                                subDepartment === selectedSubDepartment ?
                                                    <li key={subDepartment} id={subDepartment} onClick={()=>handleSubDepartmentClick(department, subDepartment)} style={{cursor:"pointer", fontWeight:"bold"}}>{subDepartment}</li>
                                                :
                                                    <li key={subDepartment} id={subDepartment} onClick={()=>handleSubDepartmentClick(department, subDepartment)} style={{cursor:"pointer"}}>{subDepartment}</li>
                                            :
                                                <div key={subDepartment}></div>
                                        )
                                    })
                            }
                            {
                                openFilters.includes(department) && Object.keys(secondaryDepInfo?.data)?.includes(department) &&
                                    Object.keys(secondaryDepInfo?.data?.[department])?.map((subDepartment)=>{
                                        if(Object.keys(databaseInfo?.data?.[department]?.[subDepartment])?.length > 0){
                                            return null;
                                        }else{
                                            return(
                                                Object.keys(secondaryDepInfo?.data?.[department]?.[subDepartment]).length > 0 ?
                                                    subDepartment === selectedSubDepartment ?
                                                        <li key={subDepartment} id={subDepartment} onClick={()=>handleSubDepartmentClick(department, subDepartment)} style={{cursor:"pointer", fontWeight:"bold"}}>{subDepartment}</li>
                                                    :
                                                        <li key={subDepartment} id={subDepartment} onClick={()=>handleSubDepartmentClick(department, subDepartment)} style={{cursor:"pointer"}}>{subDepartment}</li>
                                                :
                                                    <div key={subDepartment}></div>
                                            )
                                        }
                                    })
                            }
                            </ul>
                        </div>
                    )
                })
            }
            {
                secondaryDepInfo?.departments?.map((department)=>{
                    if(databaseInfo.departments.includes(department)){
                        return null;
                    }else{
                        return(
                            <div key={department} style={{borderTop:"1px solid grey"}}>
                                <div style={{marginTop:"10px", marginRight:"10px", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                    {
                                        department === selectedDepartment ?
                                            <div style={{cursor:"pointer", fontWeight:"bold"}} onClick={()=>handleDepartmentClick(department)}>{department}</div>
                                        :
                                            <div style={{cursor:"pointer"}} onClick={()=>handleDepartmentClick(department)}>{department}</div>
                                    }
                                    <FontAwesomeIcon style={{fontSize: '0.75em', cursor:"pointer"}} onClick={()=>handleChevronClick(department)} icon={openFilters.includes(department) ? faChevronDown : faChevronUp} />
                                </div>
                                <ul style={{listStyleType:"none"}}>
                                {
                                    openFilters.includes(department) &&
                                        Object.keys(secondaryDepInfo.data[department]).map((subDepartment)=>{
                                            return(
                                                Object.keys(secondaryDepInfo.data[department][subDepartment]).length > 0 ?
                                                    subDepartment === selectedSubDepartment ?
                                                        <li key={subDepartment} id={subDepartment} onClick={()=>handleSubDepartmentClick(department, subDepartment)} style={{cursor:"pointer", fontWeight:"bold"}}>{subDepartment}</li>
                                                    :
                                                        <li key={subDepartment} id={subDepartment} onClick={()=>handleSubDepartmentClick(department, subDepartment)} style={{cursor:"pointer"}}>{subDepartment}</li>
                                                :
                                                    <div key={subDepartment}></div>
                                            )
                                        })
                                }
                                </ul>
                            </div>
                        )
                    }
                })
            }
        </>
    )
}
