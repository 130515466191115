import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { columnAlgo } from '../HelperFunctions/helper_functions';
import { useNavigate } from 'react-router-dom';

export default function PageNotFound({databaseInfo, selectedDepartment, setSelectedDepartment}) {
    const navigate = useNavigate();

    const handleDepartmentClick = (department)=>{
        console.log(department)
        setSelectedDepartment(department);
        if(department === ''){
            navigate("/products");
        }else{
            navigate(`/${encodeURI(department)}`);
        }
    }
    return (
        <div className='text-center' style={{padding:'2rem'}}>
            <div style={{fontSize:'2rem', fontWeight:'bold'}}>
                Oops! This page could not be found. (404 error) 
            </div>
            <div style={{fontSize:'1.5rem', paddingTop:"2rem"}}>
                But maybe we can help you find what you are looking for. Check out these stunning products.
            </div>
            <Container fluid="lg" className="mt-5 mb-5">
                <Row>
                {
                    databaseInfo.departments.map((department)=>{
                        const subDepartments = Object.keys(databaseInfo.data[department]);
                        if(subDepartments.length){
                            for(let x=0; x<subDepartments.length; ++x){
                                const items = Object.keys(databaseInfo.data[department][subDepartments[x]]);
                                let displayed = false;
                                if(items.length){
                                    for(let y=0; y<items.length; ++y){
                                        if(databaseInfo.data[department][subDepartments[x]][items[y]].display && databaseInfo.data[department][subDepartments[x]][items[y]].image_urls?.length){
                                            displayed = true;
                                            return(
                                                <Col md={6} lg={columnAlgo(databaseInfo.departments.length)}  key={items[y]} className="departmentContainer" onClick={()=>handleDepartmentClick(department)}>
                                                    <div style={{marginBottom:"2rem"}}>{department}</div>
                                                    <div style={{width:"85%", height: "85%", margin:"auto", position:"relative", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                        <div style={{display:"flex", alignItems:"flex-start"}}>
                                                            <Image src={databaseInfo.data[department][subDepartments[x]][items[y]].image_urls[0]} alt={items[y]} style={{width:"100%", height:"auto"}} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    }
                                }
                                if(displayed){
                                    break;
                                }
                            }
                        }else{
                            return('')
                        }
                        return('');
                    })
                }
                
                </Row>
            </Container>
        </div>
    )
}
