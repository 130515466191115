import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

import { db } from '../firebase-config';

import { doc, getDoc } from 'firebase/firestore';



import NavigationButton from './NavigationButton';
import QuantityPickerCart from '../Components/QuantityPickerCart';

export default function Cart({ cart, setCartUpdated, updateCartFromCartPage, clearCart, setSelectedItem, doubleCheckCart }) {
    const [subtotal, setSubtotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [doubleCheckComplete, setDoubleCheckComplete] = useState(false);
    const [showCheckout, setShowCheckout] = useState(true);
    const { currentUser } = useAuth();

    const navigate = useNavigate();

    useEffect(()=>{
        window.scrollTo({top:0, behavior:'smooth'});
        const checkoutInfoRef = doc(db, 'website_data', 'checkout');
        getDoc(checkoutInfoRef).then((docSnap)=>{
            if(docSnap.exists()){
                setTax(docSnap.data().tax);
            }else{
                console.log('No such document!');
            }
        }).catch((error)=>{
            console.log(`Error geting website data: ${error}`);
        });
        setDoubleCheckComplete(false);
    }, []);

    useEffect(()=>{
        if(!doubleCheckComplete){
            console.log('checking')
            doubleCheckCart().then((result)=>{
                setShowCheckout(result);
            })
        }
        setDoubleCheckComplete(true);
    },[doubleCheckCart, doubleCheckComplete]);


    useEffect(()=>{
        let sub = 0;
        cart.forEach((item)=>{
            sub += Math.round((parseFloat(item?.productInfo?.itemInfo?.info?.price) * parseFloat(item?.Quantity))*100)/100
        })
        setSubtotal(sub);
    }, [cart, setSubtotal]);

    const handleItemClick = (item)=>{
        setSelectedItem(
            {
                department: item?.productInfo?.department,
                subdepartment: item?.productInfo?.subdepartment,
                itemInfo:{
                    name: item?.productInfo?.itemInfo?.name,
                    info: item?.productInfo?.itemInfo?.info
                }
            });
        navigate(`/productdetails`);
    };

    const checkShowCheckout = ()=>{
        let show = true;
        cart.forEach((item)=>{
            if(item?.Quantity>item?.productInfo?.itemInfo?.info?.quantity){
                show = false;
            }
        });
        setShowCheckout(show);
    }

    return (
        <>
            <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                <Row className="justify-content-center">
                    YOUR CART
                </Row>
                <Row className="justify-content-center" style={{fontSize:"0.6em"}}>
                    <div style={{textAlign:'center'}}>
                        <u onClick={()=>{navigate(`/products`)}} style={{cursor:'pointer'}}>CONTINUE SHOPPING</u>
                    </div>
                </Row>
            </Container>
            <Container fluid="lg">
                <Row>
                    <Col lg="8" className="mt-4">
                    {
                        cart.map((item)=>{
                            return(
                                <Row key={item?.productInfo?.itemInfo?.name} className="align-items-center" style={{padding:'20px', borderBottom:'1px solid grey'}}>
                                    <Col xs="3" lg="2">
                                        <Image src={item?.productInfo?.itemInfo?.info.image_urls[0]} alt={item?.productInfo?.itemInfo?.name} style={{width:"100%", height:"auto", cursor:'pointer'}}  onClick={()=>handleItemClick(item)} />
                                    </Col>
                                    <Col>
                                        <Row className="align-items-center">
                                            <Col>
                                                <Row style={{margin:"0px", cursor:'pointer'}} onClick={()=>handleItemClick(item)}>
                                                    {item?.productInfo?.itemInfo?.name}
                                                </Row>
                                                <Row style={{margin:"0px"}}>
                                                    <div>Price $<span className="number">{item?.productInfo?.itemInfo?.info?.price}</span></div>
                                                </Row>
                                                <Row style={{marginLeft:"0px", marginTop:"20px"}}>
                                                    <div onClick={()=>checkShowCheckout()}><QuantityPickerCart quantity={item?.Quantity} instock={item?.productInfo?.itemInfo?.info?.quantity} setCartUpdated={setCartUpdated} updateCartFromCartPage={updateCartFromCartPage} selectedItem={item} /></div>
                                                </Row>
                                            </Col>
                                            <Col md="2" style={{marginTop:'10px'}}>
                                                <div>Subtotal<br></br>$<span className="number">{(Math.round((parseFloat(item?.productInfo?.itemInfo?.info?.price) * parseFloat(item?.Quantity))*100)/100).toString()}</span></div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    </Col>
                    <Col  className="mt-4" style={{border:'1px solid grey'}}>
                        <Row className="justify-content-center" style={{margin:'20px 0 20px 0'}}>
                            CART SUMMARY
                        </Row>
                        <Row className="justify-content-center">
                            <Col>Subtotal</Col>
                            <Col className="number">{
                                cart.length > 0 && `$${Math.round(subtotal*100)/100}`     
                            }</Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col>Tax</Col>
                            <Col className="number">{
                                cart.length > 0 && `$${Math.round(((subtotal * tax)/100)*100)/100}`     
                            }</Col>
                        </Row>
                        <Row className="justify-content-center" style={{fontSize:'1.3em'}}>
                            <Col>Total</Col>
                            <Col className="number">{
                                cart.length > 0 && `$${Math.round((subtotal + ((subtotal * tax)/100))*100)/100}`     
                            }</Col>
                        </Row>
                        <Row className="justify-content-center" style={{margin:'20px 0 20px 0'}}>
                            {
                                cart.length > 0  && showCheckout ?
                                    currentUser ?
                                        <NavigationButton title="CHECKOUT" address="checkout" />
                                    :
                                        <NavigationButton title="CHECKOUT" address="guest-signin" />
                                :
                                    ''
                            } 
                        </Row>
                    </Col>
                    {
                        cart.length > 0 && <Row className='justify-content-md-center'><Col xs lg="2" onClick={()=>clearCart()} style={{textAlign:'center', cursor:'pointer',fontSize:"0.8em", margin:'10px'}}>Empty Cart</Col></Row>
                    }
                </Row>
            </Container>
        </>
    );
}

