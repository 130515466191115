import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebookF, faHouzz } from '@fortawesome/free-brands-svg-icons'

import '../css/footer.css';

export default function Footer({ scrollPosition, departments, setSelectedDepartment, setNavExpanded, setSelectedSubDepartment, secondaryDepartments }) {
  const navigate = useNavigate();

  const top = ()=>{
    window.scrollTo(0, 0);
  };
  const handleDepartmentClick = (department)=>{
    setNavExpanded(false);
    top();
    // console.log(encodeURI(department))
    setSelectedDepartment(department);
    setSelectedSubDepartment('');
    navigate(`/${encodeURI(department)}`);
  }
  return (
    <div className="mainFooter">
      {
        scrollPosition > 100 ?
          <div onClick={()=>top()} className="top">
            Back to Top ^
          </div>
        :
          ''
      }
      <div className="borderFooter"></div>
      <div className="noBorderFooter">
        <div className="categoryFooter">
          PRODUCTS
          {
            departments.map((department)=>{
                return(
                  <div className="itemFooter" key={department} onClick={()=>handleDepartmentClick(department)}><div className="itemSelect">{department}</div></div>
                )
            })
          }{
            secondaryDepartments.map((department)=>{
              if(departments.includes(department)){
                return null;
              }else{
                return(
                  <div className="itemFooter" key={department} onClick={()=>handleDepartmentClick(department)}><div className="itemSelect">{department}</div></div>
                )
              }
            })
          }
        </div>
         
        <div className="categoryFooter">
          CONTACT
          <div className="itemFooter">
            (905) 885-2885
          </div>
          <div className="itemFooter">
            143 Rose Glen Road South, Port Hope, Ontario L1A 1C6, Canada
          </div>
          <div className="itemFooter">
            Mon-Fri 9:00am to 5:00pm
          </div>
          <div className='itemFooter'>
            Sat-Sun By Appointment
          </div>
        </div>
        <div className="categoryFooter">
          RESOURCES
          <div className="itemFooter">
            <Link to={'/how_it_works'} className='decorationNone' onClick={()=>top()}>How it Works</Link>
          </div>
          <div className="itemFooter">
            <Link to={'/terms_of_rental_agreement'} className='decorationNone' onClick={()=>top()}>Terms of Rental Agreement</Link>
          </div>
          <div className="itemFooter">
            <Link to={'/privacy_policy'} className='decorationNone' onClick={()=>top()}>Privacy Policy</Link>
          </div>
          <div className="itemFooter">
            <Link to={'/terms_of_use'} className='decorationNone' onClick={()=>top()}>Terms of Use</Link>
          </div>
          <div className="itemFooter">
            <Link to={'/member-portal'} className='decorationNone' onClick={()=>top()}>Members</Link>
          </div>
        </div>
        
      </div>
      <div className="noBorderFooter">
        <div className="categoryFooter" style={{textAlign:'left'}}>
          Our Commitment to Accessibility
          <div className="itemFooter" style={{lineHeight: '1.6'}}>
            We strive to make Limitless Staging Rentals experience accessible and user-friendly. If you notice any content, feature or functionality that you believe is not fully accessible to all people, please email us at limitlessstagingrentals@gmail.com. Please provide a description of the specific feature you feel is not fully accessible or a suggestion for improvement.
          </div>
        </div>
      </div>
      <div className="noBorderFooter">
        <div className="categoryFooter" style={{textAlign:'left'}}>
          <div className="itemFooter" style={{lineHeight: '1.6'}}>
            Content copyright {new Date().getFullYear()}. LIMITLESSSTAGINGRENTALS.CA<div>All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * <div className="borderFooter">
        <div className="subscribeFooter">
          <div>
            <div className="emailTitle">
              Stay up to date...
            </div>
            <div className="emailSubTitle">
              Sign up for emails to be the first to know
            </div>
          </div>
          <div style={{flexGrow:'2', maxWidth: '350px', minWidth: '250px'}}>
            <div id="mc_embed_signup" className="text-normal width-80-percent">
               <form action="" method="post">
                  <div id="mc_embed_signup_scroll">
                    <input type="email" name="EMAIL" placeholder="ENTER YOUR EMAIL ADDRESS" className="joinEmailList" required />
                    <div><input className="w-100 btn-dark" type="submit" value="SUBMIT" name="subscribe" style={{width:'100%'}}/></div>
                   </div>
               </form>
           </div>
          </div>
        </div>
      </div>
 */

/**
 * <div className="categoryFooter">
          FOLLOW US
          <div className="itemFooter">
            <div className="iconcontainer text-center valign-top">
                <a href="https://www.facebook.com/vosburghhomedecor/" target="_blank" title="Facebook" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <FontAwesomeIcon className="icon" icon={faFacebookF} />
                    </div>
                </a>
                <a href="https://www.houzz.com/professionals/interior-designers-and-decorators/vosburgh-home-decor-pfvwus-pf~973622518" target="_blank" title="Houzz" rel="noopener noreferrer">
                    <div className="bubble_icon">
                        <FontAwesomeIcon className="icon" icon={faHouzz} />
                    </div>
                </a>
            </div>
          </div>
        </div>

 */