import React, { useState } from 'react';
import { Card, Alert, Container, Form } from 'react-bootstrap';
// import { toast } from 'react-toastify';
import ProgressBar from '../Components/ProgressBar';

export default function UploadPicForm( { databaseInfo, setDatabaseInfo }) {
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);
    const [newItemDepartmentName, setNewItemDepartmentName] = useState('');
    const [newItemSubDepartmentSelection, setNewItemSubDepartmentSelection] = useState([]);
    const [newItemSubDepartmentName, setNewItemSubDepartmentName] = useState('');
    const [newItemSelections, setNewItemSelections] = useState([]);
    const [newItemName, setNewItemName] = useState('');

    const types = ['image/png', 'image/jpeg'];

    const uploadPicHandler = (event)=>{
        event.preventDefault();
        console.log(event.target.value);
        if(newItemDepartmentName === ''){
            setError('Please enter a department name');
            event.target.value = '';
            setFile(null);
            return;
        }
        if(newItemSubDepartmentName === ''){
            setError('Please enter a subdepartment name');
            event.target.value = '';
            setFile(null);
            return;
        }
        if(newItemName === ''){
            setError('Please enter an item name');
            event.target.value = '';
            setFile(null);
            return;
        }

        let selected = event.target.files[0];
        console.log(selected);
        if(selected && types.includes(selected.type)){
            setError(null);
            setFile(selected);
            event.target.value = '';
        }else{
            setFile(null);
            event.target.value = '';
            setError('Please select an image file of (png or jpeg)');
        }
    };
    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "40vh"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card style={{margin: "40px 0 40px 0"}}>
                        <Card.Body>
                            <h2 className="text-center mb-4">Product Images</h2>
                            <Form.Label>Category</Form.Label>
                            <Form.Select value={newItemDepartmentName} name="category" onChange={(event)=>{
                                setError('');
                                setNewItemDepartmentName(event.target.value);
                                //setNewItemSubDepartmentSelection(databaseInfo?.departments.sort().map(cat => [...Object.keys(databaseInfo?.data?.[cat])]));
                                if(event.target.value === ''){
                                    setNewItemSubDepartmentSelection([]);
                                    return null;
                                }
                                setNewItemSubDepartmentSelection([...Object.keys(databaseInfo?.data?.[event.target.value])]);
                            }}>
                                <option value=""></option>
                                {
                                    databaseInfo?.departments?.sort().map((category)=>{
                                        return <option key={`select_${category}`} value={category}>{category}</option>
                                    })
                                }
                            </Form.Select>
                            <Form.Label className="mt-4">Sub-Category</Form.Label>
                            <Form.Select value={newItemSubDepartmentName} name="subcategory" onChange={(event)=>{
                                setError('');
                                setNewItemSubDepartmentName(event.target.value);
                                if(event.target.value === ''){
                                    setNewItemSelections([]);
                                    return null;
                                }
                                setNewItemSelections([...Object.keys(databaseInfo?.data?.[newItemDepartmentName]?.[event.target.value])])
                            }}>
                                <option value=""></option>
                                {
                                    newItemSubDepartmentSelection.map((sub)=>{
                                        return <option key={`sub_select_${sub}`} value={sub}>{sub}</option>
                                    })
                                }
                            </Form.Select>
                            <Form.Label className="mt-4">Item Name</Form.Label>
                            <Form.Select value={newItemName} name="itemName" onChange={(event)=>{
                                setError('');
                                setNewItemName(event.target.value)
                            }}>
                                <option value=""></option>
                                {
                                    newItemSelections.map((item)=>{
                                        return <option key={`item_select_${item}`} value={item}>{item}</option>
                                    })
                                }
                            </Form.Select>
                            {
                                newItemDepartmentName !== '' && newItemSubDepartmentName !== '' && newItemName !== '' ?
                                    <Form className="plus-form" onSubmit={uploadPicHandler}>
                                        <label className="plus-label">
                                            <input className="plus-label-input" type="file" onChange={uploadPicHandler} />
                                            <span>+</span>
                                        </label>
                                        <div>
                                            {error && <Alert variant="danger">{error}</Alert>}
                                            {file && <Alert variant="success">{file.name}</Alert>}
                                            {file && <ProgressBar
                                                file={file}
                                                setFile={setFile}
                                                newItemDepartmentName={newItemDepartmentName}
                                                newItemSubDepartmentName={newItemSubDepartmentName}
                                                newItemName={newItemName}
                                                setNewItemDepartmentName={setNewItemDepartmentName}
                                                setNewItemSubDepartmentName={setNewItemSubDepartmentName}
                                                setNewItemName={setNewItemName}
                                                databaseInfo={databaseInfo}
                                                setDatabaseInfo={setDatabaseInfo}
                                                from={"upload"}
                                            />}
                                        </div>
                                    </Form>
                                :
                                ''
                            }
                            
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    )
}
