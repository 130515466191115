import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { db } from '../firebase-config';
import { arrayUnion, doc, getDoc, updateDoc, setDoc } from "firebase/firestore";

import { convertMyDatePickerDateFormat, getHourFromTwentyFourHour, getTodaysYear } from '../HelperFunctions/helper_functions';

export default function EmployeeCheckOutSettings() {

    const [checkOutObject, setCheckOutObject] = useState({});
    //const [manualDaysOff, setManualDaysOff] = useState({});
    const [leadTime, setLeadTime] = useState("");
    const [limitDaysInAdvance, setLimitDaysInAdvance] = useState("");
    const [weekends, setWeekends] = useState("");
    const [selectedDaysOff, setSelectedDaysOff] = useState([]);
    const [deliveriesStartHours, setDeliveriesStartHours] = useState("");
    const [deliveriesEndHours, setDeliveriesEndHours] = useState("");
    const [timeslotDuration, setTimeslotDuration] = useState("");
    const [deliveriesPerTimeslot, setDeliveriesPerTimeslot] = useState("");
    const [tax, setTax] = useState("");
    const [deliveryArea, setDeliveryArea] = useState("");
    const [deliveryDisclaimer, setDeliveryDisclaimer] = useState("");
    const [deliveryCostOfDelivery, setDeliveryCostOfDelivery] = useState("");
    const [deliveryDistanceTolerance, setDeliveryDistanceTolerance] = useState("");
    const [deliveryTotalDistTolerance, setDeliveryTotalDistTolerance] = useState("");
    const [deliveryPriceTolerance, setDeliveryPriceTolerance] = useState("");

    const [defaultContractLength, setDefaultContractLength] = useState(0);

    const [manualDaysOffForSelectedYear, setManualDaysOffForSelectedYear] = useState({});
    const [currentSelectedYear, setCurrentSelectedYear] = useState(getTodaysYear().toString());
    const [newDayOff, setNewDayOff] = useState("");

    useEffect(()=>{
        const checkoutRef = doc(db, "website_data", "checkout");
        getDoc(checkoutRef).then((docSnap)=>{
            if(docSnap.exists()){
                const info = docSnap.data();
                // console.log(info);
                setCheckOutObject(info);
                setTax(info.tax);
                setDeliveryArea(info.delivery_area);
                setDeliveryDisclaimer(info.delivery_disclaimer);
                setLeadTime(info.leadTime);
                setLimitDaysInAdvance(info.limitDaysInAdvance);
                setSelectedDaysOff(info.daysOff);
                setDeliveriesStartHours(info.timeslotInfo.hoursOfOperationStart);
                setDeliveriesEndHours(info.timeslotInfo.hoursOfOperationEnd);
                setDeliveriesPerTimeslot(info.timeslotInfo.deliverysPerTimeslot);
                setTimeslotDuration(info.timeslotInfo.timeslotDuration);
                setWeekends(info.weekends);
                setDeliveryCostOfDelivery(info.deliveryCostOfDelivery);
                setDeliveryDistanceTolerance(info.deliveryDistanceTolerance);
                setDeliveryTotalDistTolerance(info.deliveryTotalDistTolerance);
                setDeliveryPriceTolerance(info.deliveryPriceTolerance);
            }else{
                toast.warning(`Database Connectivity Issues: data did not load properly`, {style: {backgroundColor:'var(--warning)'}});
            }
        }).catch((error)=>{
            toast.error(`Error getting checkout settings: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
        const defaultContractLengthRef = doc(db, "website_data", "general");
        getDoc(defaultContractLengthRef).then((doc)=>{
            if(doc.exists()){
                const data = doc.data();
                setDefaultContractLength(data['contract length']);
            }else{
                toast.warning('Database Connectivity Issues: data did not load properly', {
                    style: {backgroundColor:'var(--warning)'}
                });
            }
        }).catch((error)=>{
            toast.error('Error getting contract length setting: ' + error , {
                style: {backgroundColor:'var(--error)'}
            });
        });
    }, []);

    useEffect(()=>{
        const manualDaysOffRef = doc(db, "manual_days_off", currentSelectedYear);
        getDoc(manualDaysOffRef).then((docSnap)=>{
            if(docSnap.exists()){
                setManualDaysOffForSelectedYear({data: docSnap.data(), id: docSnap.id});
            }else{
                setManualDaysOffForSelectedYear({data: [], id: currentSelectedYear});
            }
        }).catch((error)=>{
            toast.error(`Error getting specific days booked off information: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    }, [currentSelectedYear]);

    const saveCheckOutObject = (newCheckOutObject) => {
        const updateObjRef = doc(db, "website_data", "checkout");
        updateDoc(updateObjRef, newCheckOutObject).then(()=>{
            toast.success('Information updated.', {style: {backgroundColor:'var(--success)'}});
        }).catch((error)=>{
            toast.error(`Error saving data: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    };

    const saveDefaultContractLength = (event) => {
        event.preventDefault();
        // check to see if input field is empty
        if(defaultContractLength === ""){
            toast.warning("Default Contract Length field is blank or not a number. For no Default Contract Length please enter 0", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        if(defaultContractLength.includes('.')){
            toast.warning("Default Contract Length has a decimal place. Default Contract Length must be a whole number", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        const tempContractLength = parseInt(defaultContractLength);
        if(tempContractLength < 0){
            toast.warning("Default Contract Length field is negative. Cannot be a negative number", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        const updateObjRef = doc(db, "website_data", "general");
        updateDoc(updateObjRef, {'contract length' : parseInt(defaultContractLength)}).then(()=>{
            toast.success('Default Contract Length updated.', {style: {backgroundColor:'var(--success)'}});
        }).catch((error)=>{
            toast.error(`Error saving data: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    };

    const saveManualDaysOffForSelectedYear = (newManualDaysOffArray, year) => {
        const updateObjRef = doc(db, "manual_days_off", year.toString());
        updateDoc(updateObjRef, newManualDaysOffArray).then(()=>{
            toast.success('Information updated.', {style: {backgroundColor:'var(--success)'}});
        }).catch((error)=>{
            toast.error(`Error saving data: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    };

    const addManualDaysOff = (event, year, month, day) => {
        event.preventDefault();
        const updateObjRef = doc(db, "manual_days_off", year.toString());
        setDoc(updateObjRef, {
            [month]: arrayUnion(day)
        },{ merge: true}).then(()=>{
            setCurrentSelectedYear(year.toString());
            if(currentSelectedYear.toString() === year.toString()){
                let tempManualDaysOff = {
                    ...manualDaysOffForSelectedYear,
                    data: {
                        ...manualDaysOffForSelectedYear.data,
                    },
                    id: manualDaysOffForSelectedYear.id,
                };
                if(tempManualDaysOff.data?.[month] !== undefined){
                    if(!tempManualDaysOff.data[month].includes(day)){
                        tempManualDaysOff.data[month].push(day);
                    }
                }else{
                    tempManualDaysOff.data[month] = [day];
                }
                setManualDaysOffForSelectedYear(tempManualDaysOff);
            }
            toast.success('Information updated.', {style: {backgroundColor:'var(--success)'}});
        }).catch((error)=>{
            console.log(error);
            toast.error(`Error saving data: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    };

    const updateSalesTax = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(tax === ""){
            toast.warning("Sales Tax field is blank. For no sales tax please enter 0", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            tax: parseFloat(tax)  
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateMaxDeliveryArea = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(deliveryTotalDistTolerance === ""){
            toast.warning("Max Delivery Area field is blank.", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            deliveryTotalDistTolerance: parseFloat(deliveryTotalDistTolerance)  
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateDeliveryChargeArea = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(deliveryDistanceTolerance === ""){
            toast.warning("Delivery Charge Area field is blank.", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            deliveryDistanceTolerance: parseFloat(deliveryDistanceTolerance)  
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updatePriceThreshold = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(deliveryPriceTolerance === ""){
            toast.warning("Price Threshold field is blank.", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            deliveryPriceTolerance: parseFloat(deliveryPriceTolerance)  
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateDeliveryCharge = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(deliveryCostOfDelivery === ""){
            toast.warning("Delivery Charge field is blank.", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            deliveryCostOfDelivery: parseFloat(deliveryCostOfDelivery)  
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateDeliveryArea = (event)=>{
        event.preventDefault();
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            delivery_area: deliveryArea 
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateDeliveryDisclaimer = (event)=>{
        event.preventDefault();
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            delivery_disclaimer: deliveryDisclaimer
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateLeadTime = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(leadTime === ""){
            toast.warning("Lead Time field is blank. For no lead time please enter 0", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            leadTime: parseFloat(leadTime)  
        };
       setCheckOutObject(tempCheckOutObject);
       // console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateLimitDaysInAdvance = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(limitDaysInAdvance === ""){
            toast.warning("Limit Days In Advance field is blank. For no days in advance please enter 0", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            limitDaysInAdvance: parseFloat(limitDaysInAdvance)  
        };
       setCheckOutObject(tempCheckOutObject);
       //console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };
    const updateWeekends = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(weekends === ""){
            toast.warning("Weekends field is blank. Must either be True or False", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {...checkOutObject.timeslotInfo},
            weekends: weekends.toUpperCase() === "TRUE" ? true : false  
        };
       setCheckOutObject(tempCheckOutObject);
       //console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const updateDaysOff = (event)=>{
        event.preventDefault();
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: selectedDaysOff,
            timeslotInfo: {...checkOutObject.timeslotInfo},
        };
       setCheckOutObject(tempCheckOutObject);
       //console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };

    const hours  = ["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
        const hoursDescription = [
            "12am (midnight)",
            "1 am",
            "2 am",
            "3 am",
            "4 am",
            "5 am",
            "6 am",
            "7 am",
            "8 am",
            "9 am",
            "10 am",
            "11 am",
            "12 pm (noon)",
            "1 pm",
            "2 pm",
            "3 pm",
            "4 pm",
            "5 pm",
            "6 pm",
            "7 pm",
            "8 pm",
            "9 pm",
            "10 pm",
            "11 pm",
        ];
    
    const updateDeliveryWindowInfo = (event) => {
        event.preventDefault();
        // update local object
        let tempCheckOutObject = {
            ...checkOutObject,
            daysOff: [...checkOutObject.daysOff],
            timeslotInfo: {
                ...checkOutObject.timeslotInfo,
                deliverysPerTimeslot: deliveriesPerTimeslot,
                hoursOfOperationEnd: deliveriesEndHours,
                hoursOfOperationStart: deliveriesStartHours,
                timeslotDuration: timeslotDuration,
            },
        };
       setCheckOutObject(tempCheckOutObject);
       //console.log(tempCheckOutObject)
        // save to database
        saveCheckOutObject(tempCheckOutObject);
    };

    const writtenMonths = {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
    };

    const yearsArray = [];
    for(let x=0; x<16; ++x){
        yearsArray.push(new Date().getFullYear() + x);
    }

    const updateManualDaysOff = (event, year) => {
        event.preventDefault();
        // console.log('update manual days off');
        // console.log(newDayOff);
        // console.log(manualDaysOffForSelectedYear);
        saveManualDaysOffForSelectedYear(manualDaysOffForSelectedYear.data, year);
    };
    
    return (
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        Checkout Settings
                    </Row>
                </Container>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Calendar - Lead time</Form.Label>
                        <div style={{fontSize:"0.65em"}}>The number of days out from the current day a customer can book an appointment.</div>
                        <div style={{fontSize:"0.65em"}}>e.g. 0 = customer can book and expect delivery the same day</div>
                        <div style={{fontSize:"0.65em"}}>e.g. 2 = customer cannot book same day or next day delivery</div>
                        <Form.Control type="number" value={leadTime} name="calendarleadtime" onChange={(event)=>{
                            setLeadTime(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateLeadTime(e)}>Update Lead Time</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Calendar - Limit Days In Advance</Form.Label>
                        <div style={{fontSize:"0.65em"}}>The number of days in advance from the current day a customer can book an appointment</div>
                        <div style={{fontSize:"0.65em"}}>e.g. 30 = days will start to be blocked off 30 days from now</div>
                        <div style={{fontSize:"0.65em"}}>e.g. 0 = no days will be available to book an appointment</div>
                        <Form.Control type="number" value={limitDaysInAdvance} name="calendardaysinadvance" onChange={(event)=>{
                            setLimitDaysInAdvance(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateLimitDaysInAdvance(e)}>Update Days in Advance Limit</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Calendar - Weekend Delivery</Form.Label>
                        <div style={{fontSize:"0.65em"}}>A quick way to block off weekends</div>
                        <div style={{fontSize:"0.65em"}}> True equals appointments on weekends, False equals no appointments on weekends</div>
                        <Form.Select type="number" value={weekends} name="calendarweekends" onChange={(event)=>{
                            setWeekends(event.target.value)
                        }}>
                            <option key="true" value="true">True</option>
                            <option key="false" value="false">False</option>
                        </Form.Select>
                        <Button className="mt-3" onClick={(e) => updateWeekends(e)}>Update Weekend Delivery</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Calendar - Block off days of the week</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Select the days of the week you will not be delivering on a weekly basis</div>
                        <div style={{fontSize:"0.65em"}}>Selecting all days will block off the entire calendar</div>
                        <div className='d-flex align-items-center justify-content-between'>
                        {
                            daysOfWeek.map((day, index)=>{
                                return(
                                    <div
                                        key={day}
                                        className='d-flex align-items-center justify-content-center'
                                        style={{
                                            border:"1px solid black",
                                            width:"2.5em",
                                            height:"2.5em",
                                            alignText:"center",
                                            userSelect:"none",
                                            cursor:"pointer",
                                            backgroundColor: selectedDaysOff.includes(index) ? "grey" : "white"
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if(selectedDaysOff.indexOf(index) > -1){
                                                let tempDaysOff = [...selectedDaysOff];
                                                tempDaysOff.splice(tempDaysOff.indexOf(index), 1)
                                                setSelectedDaysOff(tempDaysOff);
                                            }else{
                                                let tempDaysOff = [...selectedDaysOff];
                                                tempDaysOff.push(index)
                                                setSelectedDaysOff(tempDaysOff);
                                            }
                                        }}
                                        >
                                        {day}
                                    </div>
                                )
                            })
                        }
                        </div>
                        <Button className="mt-3" onClick={(e) => updateDaysOff(e)}>Update Blocked Days Off</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Calendar - Delivery Window Information</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Deliveries will start at:</div>
                        <Form.Select value={getHourFromTwentyFourHour(deliveriesStartHours)} onChange={(e) => {
                                if(parseInt(`${e.target.value}00`) < parseInt(deliveriesEndHours)){
                                    setDeliveriesStartHours(`${e.target.value}00`);
                                }else{
                                    toast.error(`Notice Start hours must be before Ending hours`, {style: {backgroundColor:'var(--error)'}});
                                }
                            }}>
                            {
                                hours.map((hour, index)=>{
                                    return(<option key={index} value={hour}>{hoursDescription[index]}</option>)
                                })
                            }
                        </Form.Select>
                        <div style={{fontSize:"0.65em", marginTop:"1em"}}>Deliveries will end at:</div>
                        <Form.Select value={getHourFromTwentyFourHour(deliveriesEndHours)} onChange={(e) => {
                                if(parseInt(`${e.target.value}00`) > parseInt(deliveriesStartHours)){
                                    setDeliveriesEndHours(`${e.target.value}00`);
                                }else{
                                    toast.error(`Notice Ending hours must be after Starting hours`, {style: {backgroundColor:'var(--error)'}});
                                }
                            }}>
                            {
                                hours.map((hour, index)=>{
                                    return(<option key={index} value={hour}>{hoursDescription[index]}</option>)
                                })
                            }
                        </Form.Select>
                        <div style={{fontSize:"0.65em", marginTop:"1em"}}>Timeslot duration:</div>
                        <Form.Control type="number" value={timeslotDuration} name="calendardaysinadvance" onChange={(event)=>{
                            if(event.target.value !== ''){
                                if(parseInt(event.target.value) > 0){
                                    setTimeslotDuration(parseInt(event.target.value));
                                }else{
                                    setTimeslotDuration(1);
                                }
                            }else{
                                setTimeslotDuration(1);
                            }
                            
                        }} />
                        <div style={{fontSize:"0.65em", marginTop:"1em"}}>Number of allowable deliveries per timeslot:</div>
                        <Form.Control type="number" value={deliveriesPerTimeslot} name="calendardaysinadvance" onChange={(event)=>{
                            if(event.target.value !== ''){
                                if(parseInt(event.target.value) > 0){
                                    setDeliveriesPerTimeslot(parseInt(event.target.value));
                                }else{
                                    setDeliveriesPerTimeslot(1);
                                }
                            }else{
                                setDeliveriesPerTimeslot(1);
                            }
                            
                        }} />
                        <Button className="mt-3" onClick={(e) => updateDeliveryWindowInfo(e)}>Update Window Delivery Information</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Calendar - Specific Days Booked Off</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Select specific days to book off then press Add Scheduled Day Off</div>
                        <div style={{fontSize:"0.65em"}}>e.g Stat Holidays</div>
                        <Form.Control type="date" value={newDayOff} onChange={(e)=> setNewDayOff(e.target.value)} />
                        <Button className="mt-3" onClick={(e) => {
                            // Adding days
                            // update local info setManualDaysOffForSelectedYear
                            if(newDayOff !== ""){
                                let slashNewDayOff = convertMyDatePickerDateFormat(newDayOff);
                                const date = new Date(slashNewDayOff);
                                const year = date.getFullYear().toString();
                                const month = date.getMonth()+1;
                                const day = date.getDate();
                                addManualDaysOff(e, year, month, day);
                                setNewDayOff("");
                            }else{
                                toast.warning(`Date not properly selected. Please double check the date input`, {style: {backgroundColor:'var(--warning)'}});
                            }
                        }}>Add Scheduled Day Off</Button>
                        <div style={{fontSize:"0.65em", marginTop:"2em"}}>Select days to be removed from being booked off then press Update Scheduled Days Off</div>
                        {
                            <Form.Select value={manualDaysOffForSelectedYear?.id} onChange={(e) => {
                                    setCurrentSelectedYear(e.target.value)
                                }}>
                                {
                                    yearsArray.map((year)=>{
                                        return <option key={year} value={year}>{year}</option>
                                    })
                                }
                            </Form.Select>
                        }
                        {manualDaysOffForSelectedYear?.data !== undefined &&
                            Object.keys(manualDaysOffForSelectedYear.data).sort((a,b)=>parseInt(a)-parseInt(b)).map((month)=>{
                                return(
                                    <div key={month} className='d-flex align-items-center'>
                                        {writtenMonths?.[month]}:
                                        {manualDaysOffForSelectedYear.data[month].sort().map((day)=>{
                                            return(
                                                <div
                                                    key={day}
                                                    className='d-flex align-items-center justify-content-center'
                                                    style={{
                                                        border:"1px solid black",
                                                        width:"2.5em",
                                                        height:"2.5em",
                                                        alignText:"center",
                                                        userSelect:"none",
                                                        cursor:"pointer",
                                                        margin:"10px",
                                                        backgroundColor: "grey"
                                                    }}
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        if(manualDaysOffForSelectedYear?.data?.[month].indexOf(day) > -1){
                                                            let tempManualDaysOff = {
                                                                ...manualDaysOffForSelectedYear,
                                                                data: {
                                                                    ...manualDaysOffForSelectedYear.data,
                                                                },
                                                                id: manualDaysOffForSelectedYear.id,
                                                            };
                                                            tempManualDaysOff.data[month].splice(tempManualDaysOff.data[month].indexOf(day), 1);
                                                            setManualDaysOffForSelectedYear(tempManualDaysOff);
                                                        }
                                                    }}
                                                >{day}</div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        <Button className="mt-3" onClick={(e) => {
                            updateManualDaysOff(e, currentSelectedYear);
                        }}>Update Scheduled Days Off</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Sales Tax %</Form.Label>
                        <Form.Control type="number" value={tax} name="salestax" onChange={(event)=>{
                            setTax(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateSalesTax(e)}>Update Sales Tax</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Max Delivery Area (km)</Form.Label>
                        <div style={{fontSize:"0.65em"}}>KM's away that we will not deliver greater than this number</div>
                        <Form.Control type="number" value={deliveryTotalDistTolerance} name="maxdeliveryarea" onChange={(event)=>{
                            setDeliveryTotalDistTolerance(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateMaxDeliveryArea(e)}>Update Max Delivery Area</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Delivery Charge Area (km)</Form.Label>
                        <div style={{fontSize:"0.65em"}}>KM's away that we will charge the delivery fee to if charges are below the price threshold</div>
                        <Form.Control type="number" value={deliveryDistanceTolerance} name="deliverychargearea" onChange={(event)=>{
                            setDeliveryDistanceTolerance(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateDeliveryChargeArea(e)}>Update Sales Tax</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Price Threshold ($)</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Price threshold for deliverys that are greater than the delivery charge area</div>
                        <Form.Control type="number" value={deliveryPriceTolerance} name="pricethreshold" onChange={(event)=>{
                            setDeliveryPriceTolerance(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updatePriceThreshold(e)}>Update Sales Tax</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Delivery Charge ($)</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Charge that will be applied to qualifying deliveries</div>
                        <Form.Control type="number" value={deliveryCostOfDelivery} name="deliverycharge" onChange={(event)=>{
                            setDeliveryCostOfDelivery(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateDeliveryCharge(e)}>Update Sales Tax</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Delivery Area Satement</Form.Label>
                        <Form.Control type="text" value={deliveryArea} name="salestax" onChange={(event)=>{
                            setDeliveryArea(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateDeliveryArea(e)}>Update Delivery Area Statement</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Delivery Area Disclaimer</Form.Label>
                        <Form.Control type="text" value={deliveryDisclaimer} name="salestax" onChange={(event)=>{
                            setDeliveryDisclaimer(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => updateDeliveryDisclaimer(e)}>Update Delivery Area Disclaimer</Button>
                    </div>
                    <hr />
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Default Contract Length</Form.Label>
                        <div style={{fontSize:"0.65em", color:"red"}}>This does not change any current contract dates!</div>
                        <div style={{fontSize:"0.65em"}}>This is the default number of days that gets added when you press the "add days button" from the dashboard modal</div>
                        <Form.Control type="number" value={defaultContractLength} name="salestax" onChange={(event)=>{
                            // setDeliveryDisclaimer(event.target.value)
                            setDefaultContractLength(event.target.value)
                        }} />
                        <Button className="mt-3" onClick={(e) => saveDefaultContractLength(e)}>Update Default Contract Length</Button>
                    </div>
                    <hr />
                </div>
            </Container>
        </>
    )
}
