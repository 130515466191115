import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { db } from '../firebase-config';
import { getDoc, updateDoc, setDoc, doc } from "firebase/firestore";

export default function EmployeeCategorySettings({ databaseInfo, setDatabaseInfo }) {

    const [categoryModalShow, setCategoryModalShow] = useState(false);
    const [subCategoryModalShow, setSubCategoryModalShow] = useState(false);
    const [chosenCategory, setChosenCategory] = useState("");
    const [chosenSubCategory, setChosenSubCategory] = useState("");

    const [categoriesAndSubs, setCategoriesAndSubs] = useState({});
    const [newCategoryInput, setNewCategoryInput] = useState("");
    const [newSubCategorySelect, setNewSubCategorySelect] = useState("");
    const [newSubCategoryInput, setNewSubCategoryInput] = useState("");

    useEffect(()=>{
        const catRef = doc(db, "website_data", "categoriesAndSubs");
        getDoc(catRef).then((docSnap)=>{
            if(docSnap.exists()){
                setCategoriesAndSubs(docSnap.data());
                // console.log(docSnap.data());
            }else{
                toast.warning(`No categories`, {style: {backgroundColor:'var(--warning)'}});
            }
        }).catch((error)=>{
            toast.error(`Error getting category list: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    }, []);

    const handleNewCategory = (event)=>{
        event.preventDefault();
        // check to see if input field is empty
        if(newCategoryInput === ""){
            toast.warning("New category field is blank. Nothing created.", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // check to see if category already exists
        if(categoriesAndSubs?.categoryArray?.includes(newCategoryInput)){
            toast.error(`${newCategoryInput} already exists, cannot duplicate.`, {
                style: {backgroundColor:'var(--error)'}
            });
            return null;
        }
        // check to see if category exists in sub categories (deny)
        for(let x=0; x<categoriesAndSubs?.categoryArray?.length; ++x){
            for(let y=0; y<categoriesAndSubs?.categoryObject?.[categoriesAndSubs?.categoryArray[x]].length; ++y){
                if(categoriesAndSubs?.categoryObject?.[categoriesAndSubs?.categoryArray[x]][y].includes(newCategoryInput)){
                    toast.error(`${newCategoryInput} exists as a subcategory, cannot create.`, {
                        style: {backgroundColor:'var(--error)'}
                    });
                    return null;
                }
            }
        }
        // update local object
        let tempCatAndSubCatObject = {
            ...categoriesAndSubs,
            "categoryArray": [...categoriesAndSubs.categoryArray, newCategoryInput],
            "categoryObject": {
                ...categoriesAndSubs.categoryObject,
                [newCategoryInput]: []
            }
        };

        let tempDatabaseInfoForSaving = {
            ...databaseInfo.data,
            [newCategoryInput]: {}
        }
        let tempDatabaseInfoForPortal = {
            ...databaseInfo,
            "departments":[
                ...databaseInfo.departments,
                `${newCategoryInput}`
            ],
            "data":{
                ...databaseInfo.data,
                [newCategoryInput]: {}
            }
        }
        // save to database - categoriesAndSubs and products
        const catRef = doc(db, "website_data", "categoriesAndSubs");
        const productRef = doc(db, "website_data", "products");
        updateDoc(catRef, tempCatAndSubCatObject).then(()=>{
            updateDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                toast.success(`New category: ${newCategoryInput} has been created`,  {style: {backgroundColor:'var(--success)'}});
                setCategoriesAndSubs(tempCatAndSubCatObject);
                setDatabaseInfo(tempDatabaseInfoForPortal);
                setNewCategoryInput("");
            }).catch((error)=>{
                toast.error(`Error saving new Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
            });
        }).catch((error)=>{
            toast.error(`Error saving new Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    };
    // create sub categories
    const handleNewSubCategory = (event)=>{
        event.preventDefault();
         // check to see if the main category is selected
         if(newSubCategorySelect === ""){
            toast.warning("Please select a parent category from the drop down. Nothing created.", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
         // check to see if input field is empty
         if(newSubCategoryInput === ""){
            toast.warning("New sub-category field is blank. Nothing created.", {
                style: {backgroundColor:'var(--warning)'}
            });
            return null;
        }
        // check to see if the subcategory exists as a category name
        if(categoriesAndSubs?.categoryArray?.includes(newSubCategoryInput)){
            toast.error(`${newSubCategoryInput} already exists as a category name, cannot duplicate.`, {
                style: {backgroundColor:'var(--error)'}
            });
            return null;
        }
        // check to see if subcategory exists in sub categories (deny)
        for(let x=0; x<categoriesAndSubs?.categoryArray?.length; ++x){
            for(let y=0; y<categoriesAndSubs?.categoryObject?.[categoriesAndSubs?.categoryArray[x]].length; ++y){
                if(categoriesAndSubs?.categoryObject?.[categoriesAndSubs?.categoryArray[x]][y].includes(newSubCategoryInput)){
                    toast.error(`${newSubCategoryInput} exists as a subcategory already, cannot create.`, {
                        style: {backgroundColor:'var(--error)'}
                    });
                    return null;
                }
            }
        }
        // update local object
        let tempCatAndSubCatObject = {
            ...categoriesAndSubs,
            "categoryArray": [...categoriesAndSubs.categoryArray],
            "categoryObject": {
                ...categoriesAndSubs.categoryObject,
                [newSubCategorySelect]: [...categoriesAndSubs.categoryObject[newSubCategorySelect], newSubCategoryInput]
            }
        };
        let tempDatabaseInfoForSaving = {
            ...databaseInfo.data,
            [newSubCategorySelect]: {
                ...databaseInfo.data[newSubCategorySelect],
                [newSubCategoryInput]: {}
            }
        }
        let tempDatabaseInfoForPortal = {
            ...databaseInfo,
            "departments":[
                ...databaseInfo.departments,
            ],
            "data":{
                ...databaseInfo.data,
                [newSubCategorySelect]: {
                    ...databaseInfo.data[newSubCategorySelect],
                    [newSubCategoryInput]: {}
                }
            }
        }
        // save to database - categoriesAndSubs and products
        const catRef = doc(db, "website_data", "categoriesAndSubs");
        const productRef = doc(db, "website_data", "products");
        updateDoc(catRef, tempCatAndSubCatObject).then(()=>{
            updateDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                toast.success(`New Sub-category: ${newSubCategoryInput} has been created, in ${newSubCategorySelect}`,  {style: {backgroundColor:'var(--success)'}});
                setDatabaseInfo(tempDatabaseInfoForPortal);
                setNewSubCategoryInput("");
                setNewSubCategorySelect("");
                setCategoriesAndSubs(tempCatAndSubCatObject);
            }).catch((error)=>{
                toast.error(`Error saving new Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
            });
        }).catch((error)=>{
            toast.error(`Error saving new Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    };

    const deleteCategory = ()=>{
        let newCatArray = categoriesAndSubs.categoryArray.filter((cat)=>cat !== chosenCategory);
        let newCatObject = {...categoriesAndSubs.categoryObject};
        delete newCatObject[chosenCategory];

        let tempDatabaseInfoForSaving = {...databaseInfo.data};
        delete tempDatabaseInfoForSaving[chosenCategory];
        
        let tempDatabaseInfoForPortal = {
            ...databaseInfo,
            "departments":[
                ...newCatArray,
            ],
            "data":{
                ...tempDatabaseInfoForSaving,
            }
        }
        // setDatabaseInfo(tempDatabaseInfoForPortal);
        // console.log(tempDatabaseInfoForPortal);

        // save to database - categoriesAndSubs and products
        const catRef = doc(db, "website_data", "categoriesAndSubs");
        const productRef = doc(db, "website_data", "products");
        updateDoc(catRef, {"categoryArray": [...newCatArray], "categoryObject": {...newCatObject}}).then(()=>{
            setDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                toast.success(`${chosenCategory} Category has been delete`,  {style: {backgroundColor:'var(--success)'}});
                setCategoriesAndSubs({"categoryArray": [...newCatArray], "categoryObject": {...newCatObject}})
                setDatabaseInfo(tempDatabaseInfoForPortal);
                setChosenCategory("");
                setChosenSubCategory("");
                setCategoryModalShow(false);
            }).catch((error)=>{
                toast.error(`Error deleting Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
                setCategoryModalShow(false);
            });
        }).catch((error)=>{
            toast.error(`Error deleting new Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
            setCategoryModalShow(false);
        });
    };

    const deleteSubCategory = ()=>{
        let newCategoryArray = [...categoriesAndSubs.categoryArray];
        let newCategoryObject = {...categoriesAndSubs.categoryObject};
        newCategoryObject[chosenCategory].splice(newCategoryObject[chosenCategory].indexOf(chosenSubCategory), 1);

        let tempDatabaseInfoForSaving = {...databaseInfo.data};
        delete tempDatabaseInfoForSaving[chosenCategory][chosenSubCategory];
        
        let tempDatabaseInfoForPortal = {
            ...databaseInfo,
            "departments":[
                ...newCategoryArray,
            ],
            "data":{
                ...tempDatabaseInfoForSaving,
            }
        }
        // save to database - categoriesAndSubs and products
        const catRef = doc(db, "website_data", "categoriesAndSubs");
        const productRef = doc(db, "website_data", "products");
        updateDoc(catRef, {"categoryArray": [...newCategoryArray], "categoryObject": {...newCategoryObject}}).then(()=>{
            setDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                toast.success(`${chosenSubCategory} Sub-Category has been deleted`,  {style: {backgroundColor:'var(--success)'}});
                setCategoriesAndSubs({"categoryArray": [...newCategoryArray], "categoryObject": {...newCategoryObject}})
                setDatabaseInfo(tempDatabaseInfoForPortal);
                setChosenCategory("");
                setChosenSubCategory("");
                setSubCategoryModalShow(false);
            }).catch((error)=>{
                toast.error(`Error deleting Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
                setSubCategoryModalShow(false);
            });
        }).catch((error)=>{
            toast.error(`Error deleting new Category: ${error}`, {style: {backgroundColor:'var(--error)'}});
            setSubCategoryModalShow(false);
        });
    };

    const CategoryModal = (props)=>{
        return(
            <Modal
                {...props}
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.category}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        databaseInfo?.data?.[props.category] !== undefined && Object.keys(databaseInfo?.data?.[props.category]).length === 0 ?
                            <Button onClick={()=>deleteCategory()}>
                                Delete Category
                            </Button>
                        :
                            <div>{props.category} has sub-categories, cannot delete</div>
                    }
                </Modal.Body>
            </Modal>
        )
    };
    const SubCategoryModal = (props)=>{
        return(
            <Modal
                {...props}
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.subcategory}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        databaseInfo?.data?.[props.category]?.[props.subcategory] !== undefined && Object.keys(databaseInfo?.data?.[props.category]?.[props.subcategory]).length === 0 ?
                            <Button onClick={()=>deleteSubCategory()}>
                                Delete Sub-Category
                            </Button>
                        :
                            <div>{props.subcategory} has items, cannot delete</div>
                    }
                </Modal.Body>
            </Modal>
        )
    };
    // only allow edit of categories/subcategories if there are no items in the current category/subcategory
    // change all open orders when changes occur to this object or item updates (when items being returned - double check where they are in the database and return there)

    return (
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        Admin Category Setup
                    </Row>
                </Container>
                <Container fluid className="mt-4">
                    <Row className="justify-content-center mt-4">
                    {
                        categoriesAndSubs?.categoryArray?.sort().map((category)=>{
                            return(
                                    <Col key={category} lg="2" md="4" className="justify-content-center" style={{border:"1px solid black", borderRadius:"5px", margin:"20px"}}>
                                        <Row
                                            className="justify-content-center bg-secondary text-white"
                                            style={{fontSize:"1.1em", cursor:"pointer"}}
                                            onClick={()=>{
                                                setChosenCategory(category)
                                                setCategoryModalShow(true);
                                            }}
                                        >
                                            {category}
                                        </Row>
                                        {
                                            categoriesAndSubs?.categoryObject?.[`${category}`].sort().map((subcategory)=>{
                                                return(
                                                    <Row
                                                        key={subcategory}
                                                        className="justify-content-center"
                                                        style={{fontSize:"0.9em", cursor:"pointer", borderTop:"1px solid grey", paddingTop:"5px", paddingBottom:"5px"}}
                                                        onClick={()=>{
                                                            setChosenCategory(category)
                                                            setChosenSubCategory(subcategory);
                                                            setSubCategoryModalShow(true);
                                                        }}
                                                    >
                                                        {subcategory} - {Object.keys(databaseInfo?.data?.[category]?.[subcategory]).length}
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Col>
                            )
                        })
                    }
                    </Row>
                </Container>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px", marginBottom:"200px"}}>
                    
                    <hr />
                    <div className="mt-3">
                        <Form.Label style={{fontSize:"0.9em"}}>New Category</Form.Label>
                        <Form.Control type="text" value={newCategoryInput} onChange={(e)=> setNewCategoryInput(e.target.value)} />
                        <Button className="mt-2" onClick={(e)=> handleNewCategory(e)}>Press to Add New Category</Button>
                    </div>
                    <hr />
                    <div className="mt-3">
                        <Form.Label style={{fontSize:"0.9em"}}>New Sub-Category</Form.Label>
                        <div><Form.Label style={{fontSize:"0.8em"}}>Select category for the new sub-category</Form.Label></div>
                        {
                            <Form.Select value={newSubCategorySelect} onChange={(e) => {
                                    setNewSubCategorySelect(e.target.value)
                                }}>
                                <option value=""></option>
                                {
                                    categoriesAndSubs?.categoryArray?.sort().map((category)=>{
                                        return <option key={`select_${category}`} value={category}>{category}</option>
                                    })
                                }
                            </Form.Select>
                        }
                        <div className="mt-3"><Form.Label style={{fontSize:"0.8em"}}>New sub-category name</Form.Label></div>
                        <Form.Control type="text" value={newSubCategoryInput} onChange={(e)=> setNewSubCategoryInput(e.target.value)} />
                        <Button className="mt-2" onClick={(e)=> handleNewSubCategory(e)}>Press to Add New Sub-Category</Button>
                    </div>
                </div>
            </Container>

            <CategoryModal
                show={categoryModalShow}
                onHide={()=> {
                    setCategoryModalShow(false);
                }}
                category={chosenCategory}
            />
            <SubCategoryModal
                show={subCategoryModalShow}
                onHide={()=> {
                    setSubCategoryModalShow(false);
                }}
                category={chosenCategory}
                subcategory={chosenSubCategory}
            />
        </>
    )
}
