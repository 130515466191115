import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { db } from '../firebase-config';
import { doc, setDoc } from 'firebase/firestore';

import useStorage from '../CustomHooks/useStorage';

const ProgressBar = ( { file, setFile, newItemDepartmentName, newItemSubDepartmentName, newItemName, setNewItemDepartmentName, setNewItemSubDepartmentName, setNewItemName, databaseInfo, setDatabaseInfo, from }) =>{
    const { url, progress } = useStorage(file);
    
    useEffect(() => {
        if(url){
            // place to run update and database save
            let databaseInfoToSave = {...databaseInfo.data};
            const departmentExists = databaseInfoToSave?.[`${newItemDepartmentName}`];
            if(departmentExists){
                const subDepartmentExists = databaseInfoToSave[`${newItemDepartmentName}`]?.[`${newItemSubDepartmentName}`];
                if(subDepartmentExists){
                    const nameExists = databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`]?.[`${newItemName}`];
                    if(nameExists){
                        const image_urls_arrayExists = databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`][`${newItemName}`]?.image_urls;
                        if(image_urls_arrayExists){
                            // push image to existing item
                            databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`][`${newItemName}`].image_urls = [...databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`][`${newItemName}`].image_urls, url];
                        }else{
                            // create image_urls array and push image
                            databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`][`${newItemName}`] = {...databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`][`${newItemName}`], image_urls:[url]};
                        }
                    }else{
                        // add name to existing subdepartment, etc.
                        databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`] = {...databaseInfoToSave[`${newItemDepartmentName}`][`${newItemSubDepartmentName}`], [`${newItemName}`]:{image_urls:[url], display: true, quantity: 0}};
                    }
                }else{
                    // add to new subdepartment to existing department, etc.
                    databaseInfoToSave[`${newItemDepartmentName}`] = {...databaseInfoToSave[`${newItemDepartmentName}`], [`${newItemSubDepartmentName}`]:{[`${newItemName}`]:{image_urls:[url], display: true, quantity: 0}}};
                }
            }else{
                // create all new department, subdepartment, name and apply info (image_urls array, timestamp, display:true, show between times)
                databaseInfoToSave = {...databaseInfoToSave, [`${newItemDepartmentName}`]:{[`${newItemSubDepartmentName}`]:{[`${newItemName}`]:{image_urls:[url], display: true, quantity: 0}}}};
            }
            console.log(databaseInfoToSave);
            setDoc(doc(db, "website_data", "products"), databaseInfoToSave).then(()=>{
                // console.log(from);
                if(from !== 'modal'){
                    setFile(null);
                    setNewItemDepartmentName('');
                    setNewItemSubDepartmentName('');
                    setNewItemName('');
                }
                toast.success('File Uploaded', {
                    style: {backgroundColor:'var(--success)'}
                });
                setDatabaseInfo({departments: Object.keys(databaseInfoToSave), data: databaseInfoToSave})
            }).catch((error)=>{
                console.log(error);
                toast.error('Error Uploading File', {
                    style: {backgroundColor:'var(--error)'}
                })
            });
        }
    }, [url, setFile, databaseInfo, setDatabaseInfo, newItemDepartmentName, newItemSubDepartmentName, newItemName, setNewItemDepartmentName, setNewItemSubDepartmentName, setNewItemName, from])

    return(
        <div className="progress-bar" style={{width: progress + '%', color:'black', backgroundColor: 'black'}}></div>
    )
};

export default ProgressBar;