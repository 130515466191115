import React, { useState } from 'react';
import { Image } from 'react-bootstrap';

export default function Magnifier({ src, alt, zoom, screenWidth }) {
    const [display, setDisplay] = useState(false);
    const [magStyle, setMagStyle] = useState({});
    const [imgStyle, setImgStyle] = useState({width:"100%", height:"auto"});
    const [bw, setBw] = useState(1);
    const [w, setW] = useState(null);
    const [h, setH] = useState(null);
    const [imgWidth, setImgWidth] = useState(null);
    const [imgHeight, setImgHeight] = useState(null);

    const magnify = (event)=>{
        setBw(0);
        setW(event.target.offsetWidth / 2);
        // console.log(w);
        setH(event.target.offsetHeight / 2);
        setImgWidth(event.target.width);
        setImgHeight(event.target.height);
        setImgStyle({width:"100%", height:"auto"})
        setMagStyle({
            position:"absolute",
            width:`100%`,
            height:`${event.target.height}px`,
            //left: "0",
            //top: "0",
            //border:"3px solid grey",
            cursor:"crosshair",
            backgroundImage:`url(${src})`,
            backgroundRepeat:'no-repeat',
            backgroundSize: `${(event.target.width * zoom)}px ${(event.target.height * zoom)}px`
        });
        setDisplay(true);
    };
    const moveMagnifier = (e)=>{
        //console.log('move magnifier');
        let pos, x, y;
        /*prevent any other actions that may occur when moving over the image*/
        e.preventDefault();
        /*get the cursor's x and y positions:*/
        pos = getCursorPos(e);
        //console.log(pos)
        x = pos.x;
        y = pos.y;
        /*prevent the magnifier glass from being positioned outside the image:*/
        if (x > imgWidth - (w / zoom)) {x = imgWidth - (w / zoom);}
        if (x < w / zoom) {x = w / zoom;}
        if (y > imgHeight - (h / zoom)) {y = imgHeight - (h / zoom);}
        if (y < h / zoom) {y = h / zoom;}
        /*display what the magnifier glass "sees":*/
        //glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";

        setMagStyle({...magStyle, "backgroundPosition": `-${((x * zoom) - w + bw)}px -${((y * zoom) - h)}px`, "left":`12px`, "top":'0px'})

        function getCursorPos(e) {
            var a, x = 0, y = 0;
            e = e || window.event;
            /*get the x and y positions of the image:*/
            a = e.target.getBoundingClientRect();
            /*calculate the cursor's x and y coordinates, relative to the image:*/
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /*consider any page scrolling:*/
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {x : x, y : y};
          }
    };
    const stopMagnify = (e)=>{
        // console.log('stop magnify');
        setDisplay(false);
    };
    return (
        <>
            {
                display &&
                    <div
                        style={magStyle}
                        onMouseMove={moveMagnifier}
                        //onPointerMove={moveMagnifier}
                        onMouseLeave={stopMagnify}
                        //onPointerUp={stopMagnify}
                    ></div>
            }
            <Image
                src={src}
                alt={alt}
                style={imgStyle}
                // onClick={(event)=>{
                //     if(screenWidth >= 992){
                //         magnify(event)
                //     }
                // }}
                //onPointerDown={magnify}
                //onPointerMove={magnify}
                onMouseMove={(event)=>{
                    if(screenWidth >= 992){
                        magnify(event)
                    }
                }}
            />
        </>
    )
}
