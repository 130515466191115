import React, { useState, useEffect } from 'react';

import { db } from './firebase-config';

import { doc, getDoc } from 'firebase/firestore';

import {
  BrowserRouter,
  Routes,
  Route,
  //useParams,
  // Link,
} from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import lunr from 'lunr';

// https://github.com/kevin-powell/responsive-made-easy/blob/master/css/main.css
import './App.css';
import { AuthProvider } from './Context/AuthContext';

import TitleBar from './Components/TitleBar';
import Home from './Components/Home';

import MemberSignup from './Components/MemberSignup';
import MemberLogin from './Components/MemberLogin';
import MemberForgotPassword from './Components/MemberForgotPassword';
import MemberPortal from './Components/MemberPortal';
import MemberPrivateRoute from './Components/MemberPrivateRoute';
import CheckoutMemberDetails from './Components/CheckoutMemberDetails';
import EmployeeSignup from './Components/EmployeeSignup';
import EmployeeLogin from './Components/EmployeeLogin';
import EmployeeForgotPassword from './Components/EmployeeForgotPassword';
import EmployeePrivateRoute from './Components/EmployeePrivateRoute';
import EmployeePortal from './Components/EmployeePortal';
import UploadPicForm from './Components/UploadPicForm';
import UploadHomePageForm from './Components/UploadHomePageForm';
import Products from './Components/Products';
import PageNotFound from './Components/PageNotFound';
import Footer from './Components/Footer';
import EmployeeHome from './Components/EmployeeHome';
import EmployeeProfile from './Components/EmployeeProfile';
import EmployeeSettings from './Components/EmployeeSettings';
import EmployeeCategorySettings from './Components/EmployeeCategorySettings';
import EmployeeCheckOutSettings from './Components/EmployeeCheckOutSettings';
import EmployeeHowItWorksSettings from './Components/EmployeeHowItWorksSettings';
import EmployeeWarehouse from './Components/EmployeeWarehouse';
import EmployeeCoupons from './Components/EmployeeCoupons';
import ProductDetails from './Components/ProductDetails';
import Cart from './Components/Cart';
import CheckoutGuestSignin from './Components/CheckoutGuestSignin';
import Search from './Components/Search';
import PrivacyPolicy from './Components/PrivacyPolicy';
import TermsOfUse from './Components/TermsOfUse';
import TermsOfRentalAgremment from './Components/TermsOfRentalAgreement';
import HowItWorks from './Components/HowItWorks';
import EmployeeComplete from './Components/EmployeeComplete';
import EmployeeCancel from './Components/EmployeeCancel';

function App() {
  const [databaseInfoWeb, setDatabaseInfoWeb] = useState({departments: [], data:{}});
  const [databaseInfoPortal, setDatabaseInfoPortal] = useState({departments: [], data:{}});
  const [secondaryDepInfo, setSecondaryInfo] = useState({departments: [], data: {}});
  const [homePageInfo, setHomePageInfo] = useState({});
  const [scrollPosition, setScrollPosition] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  //const [height, setHeight] = useState(window.innerHeight);

  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedSubDepartment, setSelectedSubDepartment] = useState('');
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [navExpanded, setNavExpanded] = useState(false);

  const [cart, setCart] = useState([]);
  const [cartUpdated, setCartUpdated] = useState(false);

  const [chosenDeliveryDate, setChosenDeliveryDate] = useState('');
  const [chosenDeliveryWindow, setChosenDeliveryWindow] = useState('');

  const [lunrIndex, setLunrIndex] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  
  useEffect(()=>{
    // load website data from firestore, then get all images from storage (getDownloadURL) (load all at once or lazy load?)
    const docRef = doc(db, 'website_data', 'products');
    getDoc(docRef).then((docSnap)=>{
      if(docSnap.exists()){
        let DATA = docSnap.data();
        // console.log(DATA);

        //console.log(docSnap.data());
        // console.log('WEB');
        let depWithASub = Object.keys(DATA).sort().filter(dep => Object.keys(DATA[dep]).length > 0);
        let depWithAnItem = [];
        depWithASub.forEach((dep)=>{
          Object.keys(DATA[dep]).forEach((sub)=>{
            if(Object.keys(DATA[dep][sub]).length > 0 && !depWithAnItem.includes(dep)){
              const items = Object.keys(DATA[dep][sub]);
              for(let x=0; x<items.length; ++x){
                if(DATA?.[dep]?.[sub]?.[items[x]]?.display === true && DATA[dep][sub][items[x]].image_urls.length > 0){
                  depWithAnItem.push(dep);
                  break;
                }
              }
            }
          });
        });
        // console.log(depWithAnItem);
        
        // console.log('PORTAL');
        // console.log(Object.keys(DATA).sort())
        setDatabaseInfoWeb({departments: depWithAnItem, data: DATA});
        // console.log({departments: depWithAnItem, data: DATA})
        
        setDatabaseInfoPortal({departments: Object.keys(DATA).sort(), data: DATA});

        // console.log(DATA);
        return DATA;
          
      }else{
          console.log('doc does not exist');
          return {};
      }
    }).then((DATA)=>{
        if(Object.keys(DATA).length > 0){
        // console.log('-----------------------------------------------------');

        let itemsWithSecondaryDep = {departments: [], data:{}};
        Object.keys(DATA).forEach((dep)=>{
            Object.keys(DATA[dep]).forEach((sub)=>{
                Object.keys(DATA[dep][sub]).forEach((item)=>{
                    if(DATA?.[dep]?.[sub]?.[item]?.other_departments.length > 0){
                        DATA?.[dep]?.[sub]?.[item]?.other_departments.forEach((sec)=>{
                            itemsWithSecondaryDep = {
                              ...itemsWithSecondaryDep,
                              departments: !itemsWithSecondaryDep.departments.includes(sec.department) ? [...itemsWithSecondaryDep.departments, sec.department] : [...itemsWithSecondaryDep.departments],
                              data: {
                                ...itemsWithSecondaryDep.data,
                                [sec.department]: {
                                    ...itemsWithSecondaryDep?.data?.[sec.department],
                                    [sec.subdepartment]:{
                                        ...itemsWithSecondaryDep?.data?.[sec.department]?.[sec.subdepartment],
                                        [item]: {
                                            ...DATA?.[dep]?.[sub]?.[item],
                                            original_department: dep,
                                            original_subdepartment: sub
                                        }
                                    }
                                }
                              }
                            }
                        });
                    }
                });
            });
        });
        setSecondaryInfo(itemsWithSecondaryDep);
        // console.log(itemsWithSecondaryDep);
        // console.log('-----------------------------------------------------');
      }else{
        throw Error('No data');
        // console.log('No such document!');
      }
    }).catch((error)=>{
      // console.log(`Error geting website data: ${error}`);
    });

    const homePageRef = doc(db, 'website_data', 'home_page');
    getDoc(homePageRef).then((docSnap)=>{
      if(docSnap.exists()){
        // console.log(docSnap.data());
        setHomePageInfo({data: docSnap.data()});
      }else{
        console.log('No such document!');
      }
    }).catch((error)=>{
      console.log(`Error geting website data: ${error}`);
    });
  }, []);

  // screen scroll position (currently not in use)
  useEffect(()=>{
    const handleScroll = ()=>{
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll, {passive:true});

    return ()=>{
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  // screen width and height
  useEffect(()=>{
    const updateDimension = ()=>{
      setWidth(window.innerWidth);
      //setHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateDimension);

    return ()=>{
      window.removeEventListener('resize', updateDimension)
    }
  }, []);

  useEffect(()=>{
    // set cart
    // console.log(cart)
    if(cart.length === 0){
      if(localStorage.getItem('cart') !== null && JSON.parse(localStorage.getItem('cart')).length > 0){
        // console.log('useEffect - update cart from localStorage');
        setCart(JSON.parse(localStorage.getItem('cart')));
        // JSON.parse(localStorage.getItem('cart')).forEach((item)=>{
        //   // set value for input - id = ASIN
        //   console.log(item);
        // });
      }
    }
  }, [cart]);

  useEffect(()=>{
    let idx = lunr(function(){
      this.ref("location");
      
      this.field("color");
      this.field("description");
      this.field("finish");
      this.field("material");
      this.field("height");
      this.field("length");
      this.field("width");
      this.field("name");
      this.field("department");
      this.field("subdepartment");
      
      // this may need to be server side if too slow (more items, slower it will be)
      // or web worker!
      databaseInfoWeb.departments.forEach((department) =>{
          Object.keys(databaseInfoWeb.data[department]).forEach((subdepartment)=>{
              Object.keys(databaseInfoWeb.data[department][subdepartment]).forEach((item)=>{
                if(databaseInfoWeb.data[department][subdepartment][item]?.display === true && databaseInfoWeb.data[department][subdepartment][item].image_urls.length > 0){
                  this.add({...databaseInfoWeb.data[department][subdepartment][item], department: `${department}`, subdepartment: `${subdepartment}`, name:`${item}`, location:`${department}(@#+1)${subdepartment}(@#+1)${item}`});
                }
              });
          });
      }, this);
    });
    setLunrIndex(idx);
  }, [databaseInfoWeb]);

  const addToCartButtonClick = (productInfo, quantity)=>{
    //console.log(productInfo);
    let tempCart = [...cart];
    let found = false;
    tempCart.forEach((item, index)=>{
      // console.log(item.productInfo.itemInfo.name);
      // console.log(productInfo);
      if(item.productInfo.itemInfo.name === productInfo.itemInfo.name){
        tempCart[index].Quantity += quantity;
        found = true;
        setCartUpdated(true);
        if(quantity === "0"){
          // console.log('splice');
          tempCart.splice(index, 1);
        }
      }
    });
    if(!found){
      tempCart.push({'productInfo': productInfo, 'Quantity': quantity});
      setCartUpdated(true);
    }
    setCart(tempCart);
    localStorage.setItem('cart',JSON.stringify(tempCart));
    // console.log(tempCart);
  };

  const updateCartFromCartPage = (productInfo, quantity)=>{
    let tempCart = [...cart];
    tempCart.forEach((item, index)=>{
      if(item.productInfo.itemInfo.name === productInfo.itemInfo.name){
        tempCart[index].Quantity = quantity;
        setCartUpdated(true);
        if(quantity === 0){
          // console.log('splice');
          tempCart.splice(index, 1);
        }
      }
    });
    setCart(tempCart);
    localStorage.setItem('cart',JSON.stringify(tempCart));
    // console.log(tempCart);
  };

  const clearCart = ()=>{
    let tempCart = [...cart];
    tempCart = [];
    setCart(tempCart);
    localStorage.setItem('cart', JSON.stringify(tempCart));
    // console.log(tempCart);
  };
  /////////////////////////////////////    Check cart item that has been removed from warehouse
  const doubleCheckCart = ()=>{
    return new Promise((resolve, reject)=>{
      const docRef = doc(db, 'website_data', 'products');
        getDoc(docRef).then((docSnap)=>{
          if(docSnap.exists()){
            let depWithASub = Object.keys(docSnap.data()).sort().filter(dep => Object.keys(docSnap.data()[dep]).length > 0);
            let depWithAnItem = [];
            depWithASub.forEach((dep)=>{
              Object.keys(docSnap.data()[dep]).forEach((sub)=>{
                if(Object.keys(docSnap.data()[dep][sub]).length > 0 && !depWithAnItem.includes(dep)){
                  const items = Object.keys(docSnap.data()[dep][sub]);
                  for(let x=0; x<items.length; ++x){
                    if(docSnap?.data()?.[dep]?.[sub]?.[items[x]]?.display === true && docSnap.data()[dep][sub][items[x]].image_urls.length > 0){
                      depWithAnItem.push(dep);
                      break;
                    }
                  }
                }
              });
            });
            // console.log(depWithAnItem);
            setDatabaseInfoWeb({departments: depWithAnItem, data: docSnap.data()});
            let allItemsGood = true;
            let tempCart = [...cart];
            for(let item=0; item<cart.length; ++item){
              tempCart[item].productInfo.itemInfo.info.quantity = docSnap.data()?.[cart[item].productInfo?.department]?.[cart?.[item]?.productInfo?.subdepartment]?.[cart[item].productInfo?.itemInfo.name]?.quantity;
              
              if(docSnap.data()?.[cart[item].productInfo?.department]?.[cart[item].productInfo?.subdepartment]?.[cart[item].productInfo?.itemInfo.name]?.quantity < cart[item].Quantity){
                allItemsGood = false;
              }
              if(docSnap.data()?.[cart[item].productInfo?.department]?.[cart[item].productInfo?.subdepartment]?.[cart[item].productInfo?.itemInfo.name] === undefined){
                tempCart.splice(item, 1);
              }
            }
            if(!allItemsGood){
              setCart(tempCart);
              localStorage.setItem('cart',JSON.stringify(tempCart));
              resolve(false);
            }else{
              setCart(tempCart);
              localStorage.setItem('cart',JSON.stringify(tempCart));
              resolve(true);
            }
          }else{
            console.log('No such document!');
            reject('No such document!');
          }
        }).catch((error)=>{
          console.log(`Error geting website data: ${error}`);
          reject(error);
        });
      });
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route exact path="/" element={ 
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <Home databaseInfo={databaseInfoWeb} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setSelectedSubDepartment={setSelectedSubDepartment} homePageInfo={homePageInfo} screenWidth={width < 992 ? 'lessThan992' : 'greaterThan992'} secondaryDepInfo={secondaryDepInfo} />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route exact path="/member-signup" element={ 
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <MemberSignup />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route exact path="/member-login" element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <MemberLogin />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path="/member-portal" element={
              <MemberPrivateRoute>
                <div>
                  <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                  <MemberPortal />
                  <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
                </div>
              </MemberPrivateRoute>
            } />

            <Route path="/member-forgot-password" element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <MemberForgotPassword />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path="/guest-signin" element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <CheckoutGuestSignin screenWidth={width}></CheckoutGuestSignin>
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path="/checkout" element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <CheckoutMemberDetails databaseInfo={databaseInfoWeb} setDatabaseInfo={setDatabaseInfoWeb} cart={cart} clearCart={clearCart} chosenDeliveryDate={chosenDeliveryDate} setChosenDeliveryDate={setChosenDeliveryDate} chosenDeliveryWindow={chosenDeliveryWindow} setChosenDeliveryWindow={setChosenDeliveryWindow} doubleCheckCart={doubleCheckCart} />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path="/cart" element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <Cart cart={cart} setCartUpdated={setCartUpdated} updateCartFromCartPage={updateCartFromCartPage} clearCart={clearCart} setSelectedItem={setSelectedItem} doubleCheckCart={doubleCheckCart} />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path={`/products`} element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <Products databaseInfo={databaseInfoWeb} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} screenWidth={width < 992 ? 'lessThan992' : 'greaterThan992'} setNavExpanded={setNavExpanded} setSelectedItem ={setSelectedItem} selectedSubDepartment={selectedSubDepartment} setSelectedSubDepartment={setSelectedSubDepartment} subDepartmentList={subDepartmentList} setSubDepartmentList={setSubDepartmentList} secondaryDepInfo={secondaryDepInfo} />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path={`/search`} element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <Search databaseInfo={databaseInfoWeb} lunrIndex={lunrIndex} searchValue={searchValue} setSelectedItem={setSelectedItem} />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path={`/privacy_policy`} element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <PrivacyPolicy />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            <Route path={`/terms_of_use`} element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <TermsOfUse />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />
            <Route path={`/how_it_works`} element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <HowItWorks />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />
            <Route path={`/terms_of_rental_agreement`} element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <TermsOfRentalAgremment />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

            
            {
              databaseInfoWeb.departments.map((department)=>{
                return(
                  <Route path={`/${department}`} key={department} element={
                    <div>
                      <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                      <Products databaseInfo={databaseInfoWeb} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} screenWidth={width < 992 ? 'lessThan992' : 'greaterThan992'} setNavExpanded={setNavExpanded} setSelectedItem ={setSelectedItem} selectedSubDepartment={selectedSubDepartment} setSelectedSubDepartment={setSelectedSubDepartment} subDepartmentList={subDepartmentList} setSubDepartmentList={setSubDepartmentList} secondaryDepInfo={secondaryDepInfo} />
                      <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
                    </div>
                  } />
                )
              })
            }
            {
              secondaryDepInfo.departments.map((department)=>{
                if(databaseInfoWeb.departments.includes(department)){
                  return null;
                }else{
                  return(
                    <Route path={`/${department}`} key={department} element={
                      <div>
                        <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                        <Products databaseInfo={databaseInfoWeb} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} screenWidth={width < 992 ? 'lessThan992' : 'greaterThan992'} setNavExpanded={setNavExpanded} setSelectedItem ={setSelectedItem} selectedSubDepartment={selectedSubDepartment} setSelectedSubDepartment={setSelectedSubDepartment} subDepartmentList={subDepartmentList} setSubDepartmentList={setSubDepartmentList} secondaryDepInfo={secondaryDepInfo} />
                        <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
                      </div>
                    } />
                  )
                }
              })
            }
            <Route path={`/productdetails`} element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <ProductDetails selectedItem={selectedItem} setSelectedItem={setSelectedItem} databaseInfo={databaseInfoWeb} setSelectedDepartment={setSelectedDepartment} setSelectedSubDepartment={setSelectedSubDepartment} screenWidth={width} addToCartButtonClick={addToCartButtonClick} cartUpdated={cartUpdated} setCartUpdated={setCartUpdated} cart={cart} />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />



            <Route path="/employee-signup" element={
                <EmployeeSignup />
            } />
            <Route path="/employee-login" element={
              <EmployeeLogin />
            } />
            <Route path="/employee-forgot-password" element={
              <EmployeeForgotPassword />
            } />
            <Route path="/employee-portal" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeHome />
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-complete" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeComplete />
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-cancel" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeCancel />
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-warehouse" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeWarehouse databaseInfo={databaseInfoPortal} setDatabaseInfo={setDatabaseInfoPortal}/>
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-coupons" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeCoupons/>
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-homepageinfo" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <UploadHomePageForm homePageInfo={homePageInfo} setHomePageInfo={setHomePageInfo} />
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-images" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <UploadPicForm databaseInfo={databaseInfoPortal} setDatabaseInfo={setDatabaseInfoPortal} />
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-profile" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeProfile />
              </EmployeePrivateRoute>
            } />
            <Route path="/employee-settings" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeSettings />
              </EmployeePrivateRoute>
            } />
            <Route path="/category-settings" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeCategorySettings databaseInfo={databaseInfoPortal} setDatabaseInfo={setDatabaseInfoPortal} />
              </EmployeePrivateRoute>
            } />
            <Route path="/checkout-settings" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeCheckOutSettings />
              </EmployeePrivateRoute>
            } />
            <Route path="/howitworks-settings" element={
              <EmployeePrivateRoute>
                <EmployeePortal />
                <EmployeeHowItWorksSettings />
              </EmployeePrivateRoute>
            } />




            <Route path="*" element={
              <div>
                <TitleBar departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} searchBarSize={width < 992 ? 'width100%' : 'width50%'} navExpanded={navExpanded} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} searchValue={searchValue} setSearchValue={setSearchValue} secondaryDepartments={secondaryDepInfo.departments} />
                <PageNotFound databaseInfo={databaseInfoWeb} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} />
                <Footer scrollPosition={scrollPosition} departments={databaseInfoWeb.departments} selectedDepartment={selectedDepartment} setSelectedDepartment={setSelectedDepartment} setNavExpanded={setNavExpanded} setSelectedSubDepartment={setSelectedSubDepartment} secondaryDepartments={secondaryDepInfo.departments} />
              </div>
            } />

          </Routes>

        </div>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </AuthProvider>
  );
}

export default App;
