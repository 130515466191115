import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Table, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { db, functions } from '../firebase-config';
import { httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore";

export default function EmployeeSettings() {

    const [userAdminStatusEmail, setUserAdminStatusEmail] = useState('');
    const [listOfAdminEmails, setListOfAdminEmails] = useState([""]);
    const [employeeInfo, setEmployeeInfo] = useState([]);

    useEffect(()=>{
        const q = query(collection(db, "users"), where("type", "==", "employee"));
        getDocs(q).then((snapShot)=>{
            let emailList = [""];
            let employeeTempInfo = [];
            snapShot.forEach((doc)=>{
                emailList.push(doc.data().email);
                employeeTempInfo.push({...doc.data(), id: doc.id});
            });
            return {emailList: emailList, empInfo: employeeTempInfo};
        }).then((info)=>{
            setListOfAdminEmails(info.emailList);
            setEmployeeInfo(info.empInfo);
        }).catch((error)=>{
            toast.error(`Error getting employee email list: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    }, []);

    function handleCreateAdminClick(){
        if(userAdminStatusEmail !== ''){
            const addAdminRole = httpsCallable(functions, 'addAdminRole');
            addAdminRole( { email: userAdminStatusEmail }).then((result)=>{
                const data = result.data;
                const keys = Object.keys(data);
                if(keys.includes('error')){
                    toast.error(`Error: ${data.error}`, {
                        style: {backgroundColor:'var(--error)'}
                    });
                }else{
                    const foundEmployee = employeeInfo.find(({email}) => email === userAdminStatusEmail);
                    const foundIndex = employeeInfo.findIndex(({email}) => email === userAdminStatusEmail);
                    if(foundEmployee !== undefined){
                        const ref = doc(db, "users", foundEmployee.id);
                        updateDoc(ref, {
                            status: "admin"
                        }).then(()=>{
                            let temp = [...employeeInfo];
                            temp[foundIndex].status = "admin";
                            setEmployeeInfo(temp);
                        }).catch((error)=>{
                            toast.error(`Error updating the database: ${error}`, {
                                style: {backgroundColor:'var(--error)'}
                            });
                        });
                    }

                    toast.success(`${data.message}`, {
                        style: {backgroundColor:'var(--success)'}
                    });
                }
            }).then(()=>{
                setUserAdminStatusEmail('');
            }).catch((error)=>{
                toast.error(`Error adding admin roll: ${error}`, {
                    style: {backgroundColor:'var(--error)'}
                });
            });
        }else{
            toast.warning('User email not defined, no further action', {
                style: {backgroundColor:'var(--warning)'}
            });
        }
    }

    function handleRevokeAdminClick(){
        if(userAdminStatusEmail !== ''){
            const revokeAdminRole = httpsCallable(functions, 'revokeAdminRole');
            revokeAdminRole( {email: userAdminStatusEmail} ).then((result)=>{
                const data = result.data;
                const keys = Object.keys(data);
                if(keys.includes('error')){
                    toast.error(`Error: ${data.error}`, {
                        style: {backgroundColor:'var(--error)'}
                    });
                }else{
                    const foundEmployee = employeeInfo.find(({email}) => email === userAdminStatusEmail);
                    const foundIndex = employeeInfo.findIndex(({email}) => email === userAdminStatusEmail);
                    if(foundEmployee !== undefined){
                        const ref = doc(db, "users", foundEmployee.id);
                        updateDoc(ref, {
                            status: "revoked"
                        }).then(()=>{
                            let temp = [...employeeInfo];
                            temp[foundIndex].status = "revoked";
                            setEmployeeInfo(temp);
                        }).catch((error)=>{
                            toast.error(`Error updating the database: ${error}`, {
                                style: {backgroundColor:'var(--error)'}
                            });
                        });
                    }
                    toast.success(`${data.message}`, {
                        style: {backgroundColor:'var(--success)'}
                    });
                }
            }).then(()=>{
                setUserAdminStatusEmail('');
            }).catch((error)=>{
                toast.error(`Error revoking admin roll: ${error}`, {
                    style: {backgroundColor:'var(--error)'}
                });
            })
        }else{
            toast.warning('User email not defined, no further action', {
                style: {backgroundColor:'var(--warning)'}
            });
        }
    }
    return (
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        Employee Email Settings
                    </Row>
                </Container>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-5">
                        <Form.Group id="admin-set-email">
                            <Form.Label>Email to add/revoke admin status</Form.Label>
                            <Form.Select value={userAdminStatusEmail} onChange={(event)=>{
                                setUserAdminStatusEmail(event.target.value)
                            }}>
                                {
                                    listOfAdminEmails.map((email, index)=>{
                                        return(<option key={index} value={email}>{email}</option>)
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </div>
                    {
                        employeeInfo.find(({email}) => email === userAdminStatusEmail)?.status === 'revoked' ?
                            <div className="mt-3"><Button onClick={handleCreateAdminClick}>Create admin</Button></div>
                        :
                            employeeInfo.find(({email}) => email === userAdminStatusEmail)?.status === 'admin' ?
                                <div className="mt-3"><Button onClick={handleRevokeAdminClick}>Revoke admin</Button></div>
                            :
                                ''
                    }
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-5">
                        <Table>
                            <thead>
                                <tr>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    employeeInfo.map((employee, index)=>{
                                        return(
                                            <tr
                                                key={index}
                                                style={{cursor:"pointer"}}
                                                onClick={() => setUserAdminStatusEmail(employee.email)}
                                            >
                                                <td>{employee?.first_name}</td>
                                                <td>{employee?.last_name}</td>
                                                <td>{employee?.email}</td>
                                                <td style={{color:
                                                        employee?.status === undefined ?
                                                            "red" 
                                                        :
                                                            employee.status === "admin" ?
                                                                "green"
                                                            :
                                                                "red"
                                                    }}
                                                >{employee?.status !== undefined ? employee.status : "revoked"}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        {

                        }
                    </div>
                </div>
            </Container>
        </>
    )
}
