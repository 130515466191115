import React, { useState, useRef, useEffect } from 'react';
import { Card, Button, Alert, Container, Form } from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase-config';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

import logo from '../images/limitless.png';

export default function EmployeeProfile() {
    const { currentUser, currentUserInfo, updateCurrentUserInfo, logout, updateUserPassword } = useAuth();
    const [userType, setUserType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    useEffect(()=>{
        (currentUserInfo?.type === undefined) ? setUserType('') : setUserType(currentUserInfo.type);
        (currentUserInfo?.first_name === undefined) ? setFirstName('') : setFirstName(currentUserInfo.first_name);
        (currentUserInfo?.last_name === undefined) ? setLastName('') : setLastName(currentUserInfo.last_name);
    }, [currentUserInfo]);

    async function handleLogout(){
        setUserType('');
        setFirstName('');
        setLastName('');
        setError('');
        try{
            await logout();
            navigate("/employee-login");
        }catch(error){
            setError(`Failed to log out: ${error.message}`);
        }
    }

    async function handlePersonalInformationSubmit(event){
        event.preventDefault();

        try{
            setError('');
            setLoading(true);
            const docRef = doc(db, 'users', currentUser.uid);
            await setDoc(docRef, {
                type: userType,
                email: currentUser.email,
                first_name: firstName,
                last_name: lastName,
                timestamp: serverTimestamp()
            });
            updateCurrentUserInfo({
                type: userType,
                email: currentUser.email,
                first_name: firstName,
                last_name: lastName,
                timestamp: serverTimestamp()
            })
            setLoading(false);
            setMessage('Information has been updated');
        }catch(error){
            setMessage('');
            setError(`Failed to update Information: ${error}`);
        }
    }
    function handleSubmit(event){
        event.preventDefault();

        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Passwords do not match');
        }
        if(passwordRef.current.value === ''){
            return setMessage('Password fields blank, no changes made');
        }
        const promises = [];
        setLoading(true);
        setMessage('');
        setError('');
        /*if(emailRef.current.value !== currentUser.email){
            promises.push(updateUserEmail(emailRef.current.value));
        }*/
        if(passwordRef.current.value){
            promises.push(updateUserPassword(passwordRef.current.value));
        }

        Promise.all(promises).then(()=>{
            setMessage('Password has been updated');
            passwordRef.current.value = '';
            passwordConfirmRef.current.value = '';
        }).catch(()=>{
            setError('Failed to update account password');
        }).finally(()=>{
            setLoading(false);
        });
    }
    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "60vh", marginTop:"30px", marginBottom:"30px"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card>
                        <Card.Img variant="top" src={logo} style={{height:"100%"}}/>
                        <Card.Body>
                            <h2 className="text-center mb-4">{userType.charAt(0).toUpperCase() + userType.slice(1)} Profile</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && <Alert variant="success">{message}</Alert>}
                            <strong>Email:</strong> {currentUser?.email}
                            <hr />
                            <h4 className="text-center mb-4">{userType.charAt(0).toUpperCase() + userType.slice(1)} Information</h4>
                            <Form onSubmit={handlePersonalInformationSubmit}>
                                <Form.Group id="first-name">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="text" value={firstName} name="firstName" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setFirstName(event.target.value)
                                    }} />
                                </Form.Group>
                                <Form.Group id="last-name">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control type="text" value={lastName} name="lastName" onChange={(event)=>{
                                        setMessage('');
                                        setError('');
                                        setLastName(event.target.value)
                                    }} />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handlePersonalInformationSubmit}>Update {userType.charAt(0).toUpperCase() + userType.slice(1)} Information</Button>
                            </Form>
                            <hr />
                            <h4 className="text-center mb-4">Password Update</h4>
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} placeholder="Leave blank to keep the same" />
                                </Form.Group>
                                <Form.Group id="password-confirm">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" ref={passwordConfirmRef} placeholder="Leave blank to keep the same" />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handleSubmit}>Update Password</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt2">
                        <Button variant="link" onClick={handleLogout}>Log Out</Button>
                    </div>
                </div>
            </Container>
        </>
    )
}
