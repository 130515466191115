import React, {useEffect, useState, useRef} from 'react';
import { useAuth } from '../Context/AuthContext';
import { Container, Row, Col, Modal, Form, Image, Button, Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { db } from '../firebase-config';
import { doc, setDoc, onSnapshot, query, where, collection, getDoc } from 'firebase/firestore';

import ProgressBar from '../Components/ProgressBar';

import { getStorage, ref, deleteObject } from "firebase/storage";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

export default function EmployeeWarhouse({databaseInfo, setDatabaseInfo}) {
    const { currentUser } = useAuth();

    const [modalShow, setModalShow] = useState(false);
    const [newModalShow, setNewModalShow] = useState(false);
    const [switchPosition, setSwitchPosition] = useState(false);
    const [imgSwitchPosition, setImgSwitchPosition] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedSubDepartment, setSelectedSubDepartment] = useState("");
    const [selectedItem, setSelectedItem] = useState("");

    const [rentedItems, setRentedItems] = useState({});

    const storage = getStorage();

    useEffect(()=>{
        window.scrollTo({top:0, behavior:'smooth'});
        const unsubscribe = onSnapshot(doc(db, "website_data", "products"), (doc) => {
            if(doc.exists()){
                setDatabaseInfo({departments: Object.keys(doc.data()).sort(), data: doc.data()});
                // console.log(doc.data());
            }else{
                console.log('No such document!');
            }
        }, (error)=> {
            toast.error(`Error loading new orders: ${error}`, {
                style: {backgroundColor:'var(--error)'}
            });
        });
        const q = query(collection(db, "bookings"), where("status", ">", 0), where("status", "<", 6));
        const unsubscribeBookings = onSnapshot(q, (snapshot)=>{
            if(snapshot.size > 0){
                let tempRentedItems = {};
                snapshot.forEach((snap) =>{
                    const docRef = doc(db, `bookings/${snap.id}/private`, "info");
                    getDoc(docRef).then((privateInfo)=>{
                        //return({public: snap.data(), private: privateInfo.data(), id: snap.id});
                        return privateInfo.data().cart;
                    }).then((cart)=>{
                        cart.forEach((item)=>{
                            tempRentedItems = {
                                ...tempRentedItems,
                                [item.productInfo.department]:{
                                    ...tempRentedItems?.[item.productInfo.department],
                                    [item.productInfo.subdepartment]:{
                                         ...tempRentedItems?.[item.productInfo.department]?.[item.productInfo.subdepartment],
                                        [item.productInfo.itemInfo.name]: ((tempRentedItems?.[item.productInfo.department]?.[item.productInfo.subdepartment]?.[item.productInfo.itemInfo.name] !== undefined ? tempRentedItems?.[item.productInfo.department]?.[item.productInfo.subdepartment]?.[item.productInfo.itemInfo.name] : 0) + item.Quantity),
                                    }
                                }
                            };
                        });
                        setRentedItems(tempRentedItems);
                    }).catch((error)=>{
                        toast.error(`Error loading private orders: ${error}`, {
                            style: {backgroundColor:'var(--error)'}
                        });
                    });
                });
            }else{
                setRentedItems({});
            }
        }, (error)=> {
            toast.error(`Error loading orders: ${error}`, {
                style: {backgroundColor:'var(--error)'}
            });
        });

        return ()=>{
            unsubscribe();
            unsubscribeBookings();
        }
    }, [setDatabaseInfo]);

    const NewItemModal = (props) =>{
        const [department, setDepartment] = useState(props?.department !== undefined && props.department !== "" ? props.department : databaseInfo.departments[0]);
        const [subdepartment, setSubDepartment] = useState(props?.subdepartment !== undefined && props.subdepartment !== "" ? props.subdepartment : [...Object.keys(databaseInfo?.data?.[databaseInfo.departments[0]])].sort()[0]);
        
        const [subDepartmentSelections, setSubDepartmentSelections] = useState(props?.department === "" ? databaseInfo.departments[0] === "" ? [] : [...Object.keys(databaseInfo?.data?.[databaseInfo.departments[0]])] : [...Object.keys(databaseInfo?.data?.[props?.department])]);
        const [item, setItem] = useState("");
        const [display, setDisplay] = useState(false);
        const [quantity, setQuantity] = useState(0);
        const [price, setPrice] = useState("");
        const [description, setDescription] = useState("");
        const [color, setColor] = useState("");
        const [material, setMaterial] = useState("");
        const [finish, setFinish] = useState("");
        const [height, setHeight] = useState("");
        const [width, setWidth] = useState("");
        const [length, setLength] = useState("");
        // eslint-disable-next-line no-unused-vars
        const [imgUrls, setImgUrls] = useState([]);

        const addItem = ()=>{
            if(item === ""){
                toast.error('Item Name must be filled out', {style: {backgroundColor:'var(--error)'}});
                return null;
            }
            if(department === "" || department === undefined){
                toast.error('Department has not been selected', {style: {backgroundColor:'var(--error)'}});
                return null;
            }
            if(subdepartment === "" || subdepartment === undefined){
                toast.error('Sub-Department has not been selected', {style: {backgroundColor:'var(--error)'}});
                return null;
            }
            let duplicate = false;
            databaseInfo.departments.forEach((dep)=>{
                Object.keys(databaseInfo.data[dep]).forEach((sub)=>{
                    Object.keys(databaseInfo.data[dep][sub]).forEach((databaseItem)=>{
                        if(item === databaseItem){
                            duplicate = true;
                        }
                    });
                });
            });
            if(duplicate){
                toast.error('Item Name already exists, please choose a different name', {style: {backgroundColor:'var(--error)'}});
                return null;
            }

            const oldTempInCaseOfError = {...databaseInfo}

            let temp = {
                ...databaseInfo,
                data: {
                    ...databaseInfo.data,
                    [department]:{
                        ...databaseInfo.data[department],
                        [subdepartment]:{
                            ...databaseInfo.data[department][subdepartment],
                            [item]:{
                                // get info from inputs
                                price: price === undefined ? "" : price.toString(),
                                quantity: quantity === undefined ? "" : quantity,
                                image_urls:[...imgUrls],
                                other_departments:[],
                                color: color === undefined ? "" : color,
                                description: description === undefined ? "" : description,
                                display: display === undefined ? false : typeof(display) === 'boolean' ? display : display.toLowerCase() === 'true',
                                finish: finish === undefined ? "" : finish,
                                height: height === undefined ? "" : height,
                                length: length === undefined ? "" : length,
                                material: material === undefined ? "" : material,
                                min_quantity: 0,
                                sale: false,
                                width: width === undefined ? "" : width
                            }
                        }
                    }
                },
            };

            let tempDatabaseInfoForSaving = {...temp.data};
            // console.log(tempDatabaseInfoForSaving)
            const productRef = doc(db, "website_data", "products");
            setDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                toast.success(`Item has been added`,  {style: {backgroundColor:'var(--success)'}});
                setNewModalShow(false);
            }).catch((error)=>{
                toast.error(`Error adding item: ${error}`, {style: {backgroundColor:'var(--error)'}});
                setDatabaseInfo(oldTempInCaseOfError);
                setNewModalShow(false);
            });
        }
        return(
            <Modal
                {...props}
                size="lg"
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Item
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={4}>Category:</Col>
                            <Col>
                                <Form.Select value={department} onChange={(e) => {
                                    if(e.target.value === ''){
                                        setSubDepartmentSelections([]);
                                        return null;
                                    }
                                    setSubDepartmentSelections([...Object.keys(databaseInfo?.data?.[e.target.value])].sort());
                                    setDepartment(e.target.value)
                                    setSubDepartment([...Object.keys(databaseInfo?.data?.[e.target.value])].sort()[0])
                                }} disabled={props?.department === "" && props?.subdepartment === "" ? false : true}>
                                    {
                                        databaseInfo.departments.map((dep)=>{
                                            return <option key={`department_${dep}`} value={dep}>{dep}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Sub-Category:</Col>
                            <Col>
                                <Form.Select value={subdepartment} onChange={(e) => setSubDepartment(e.target.value)}  disabled={props?.department === "" && props?.subdepartment === "" ? false : true}>
                                    {
                                         subDepartmentSelections.map((sub)=>{
                                            return <option key={`sub_select_${sub}`} value={sub}>{sub}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Item Name:</Col>
                            <Col>
                                <Form.Control type="text" value={item} onChange={(e) => setItem(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Display:</Col>
                            <Col>
                                <Form.Select value={display} onChange={(e) => setDisplay(e.target.value)}>
                                    <option key={`${true}`} value={true}>True</option>
                                    <option key={`${false}`} value={false}>False</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Quantity:</Col>
                            <Col>
                                <Form.Control type="number" value={quantity} onChange={(e) => setQuantity(e.target.value === "" ? 0 : parseInt(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Price $:</Col>
                            <Col>
                                <Form.Control type="number" value={price} onChange={(e) => setPrice(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Description:</Col>
                            <Col>
                                <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Color:</Col>
                            <Col>
                                <Form.Control type="text" value={color} onChange={(e) => setColor(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Material:</Col>
                            <Col>
                                <Form.Control type="text" value={material} onChange={(e) => setMaterial(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Finish:</Col>
                            <Col>
                                <Form.Control type="text" value={finish} onChange={(e) => setFinish(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Height (inches):</Col>
                            <Col>
                                <Form.Control type="number" value={height} onChange={(e) => setHeight(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Width (inches):</Col>
                            <Col>
                                <Form.Control type="number" value={width} onChange={(e) => setWidth(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Length (inches):</Col>
                            <Col>
                                <Form.Control type="number" value={length} onChange={(e) => setLength(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3" style={{display:"flex", justifyContent:"center"}}>
                            <Button
                                style={{width:"50%"}}
                                onClick={(e)=>addItem(e)}
                                disabled={department === "" || department === undefined || subdepartment === "" || subdepartment === undefined || item === ""}
                            >
                                Add new item
                            </Button>
                        </Row>
                        <hr />
                        <Row className="mt-3">
                            <Col xs={4}>Images:</Col>
                        </Row>
                        {
                            imgUrls.map((link, index) => {
                                return(
                                    <Row key={`img_${index}`}>
                                        <Col xs={4}>{index+1}.</Col>
                                        <Col>
                                            <div style={{display:"flex", alignItems:"flex-start"}}>
                                                <Image src={link} alt={item} style={{width:"100%", height:"auto"}} />
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Container>
                </Modal.Body>
            </Modal>
        )
    };
    
    const ItemModal = (props) =>{
        // console.log(databaseInfo.data);
        const [department, setDepartment] = useState(props.department);
        const [subdepartment, setSubDepartment] = useState(props.subdepartment);
        const [subDepartmentSelections, setSubDepartmentSelections] = useState(props?.department === "" ? [] : [...Object.keys(databaseInfo?.data?.[props?.department])]);
        const [item, setItem] = useState(props.item);
        const [display, setDisplay] = useState(databaseInfo?.data?.[props?.department]?.[props?.subdepartment]?.[props?.item]?.display);
        const [quantity, setQuantity] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.quantity);
        const [price, setPrice] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.price);
        const [description, setDescription] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.description);
        const [color, setColor] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.color);
        const [material, setMaterial] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.material);
        const [finish, setFinish] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.finish);
        const [height, setHeight] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.height);
        const [width, setWidth] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.width);
        const [length, setLength] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.length);
        const [imgUrls, setImgUrls] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.image_urls.length > 0 ? [...databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item].image_urls] : []);
        const [error, setError] = useState(null);
        const [file, setFile] = useState(null);

        const [otherDepartments, setOtherDepartments] = useState(databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.other_departments !== undefined ? databaseInfo?.data?.[props.department]?.[props.subdepartment]?.[props.item]?.other_departments : []);
        const [secondaryDepartmentSelection, setSecondaryDepartmentSelection] = useState("");
        const [secondarySubDepartmentSelection, setSecondarySubDepartmentSelection] = useState("");
        const [secondarySubDepartmentChoices, setSecondarySubDepartmentChoices] = useState(props?.department === "" ? [] : [...Object.keys(databaseInfo?.data?.[props?.department])]);

        const dragItem = useRef();
        const dragOverItem = useRef();
    
        const types = ['image/png', 'image/jpeg'];
    
        const uploadPicHandler = (event)=>{
            // console.log('called');
            event.preventDefault();
            // console.log(event.target.value);
            if(selectedDepartment === ''){
                setError('Please enter a department name');
                event.target.value = '';
                setFile(null);
                return;
            }
            if(selectedSubDepartment === ''){
                setError('Please enter a subdepartment name');
                event.target.value = '';
                setFile(null);
                return;
            }
            if(selectedItem === ''){
                setError('Please enter an item name');
                event.target.value = '';
                setFile(null);
                return;
            }
    
            let selected = event.target.files[0];
            // console.log(selected);
            if(selected && types.includes(selected.type)){
                setError(null);
                setFile(selected);
                event.target.value = '';
            }else{
                setFile(null);
                event.target.value = '';
                setError('Please select an image file of (png or jpeg)');
            }
        };

        const deleteImage = (event, imgUrl, index)=>{
            event.preventDefault();
            setImgSwitchPosition(!imgSwitchPosition);

            const oldTempInCaseOfError = {...databaseInfo};

            const newImgUrls = [...imgUrls];
            let tempDatabaseInfo = {...databaseInfo};

            newImgUrls.splice(index, 1);

            tempDatabaseInfo.data[selectedDepartment][selectedSubDepartment][selectedItem].image_urls = [...newImgUrls];
            setDatabaseInfo(tempDatabaseInfo);

            let tempDatabaseInfoForSaving = {...tempDatabaseInfo.data};

            const productRef = doc(db, "website_data", "products");
            setDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                // Create a reference to the file to delete
                const imgRef = ref(storage, `${imgUrl}`);

                // Delete the file
                deleteObject(imgRef).then(() => {
                // File deleted successfully
                    toast.success(`Img has been deleted`,  {style: {backgroundColor:'var(--success)'}});
                }).catch((error) => {
                    toast.error(`Error deleting image - contact admin: ${error}`, {style: {backgroundColor:'var(--error)'}});
                });

            }).catch((error)=>{
                toast.error(`Error updating inventory: ${error}`, {style: {backgroundColor:'var(--error)'}});
                setDatabaseInfo(oldTempInCaseOfError);
            });
        };
        const deleteItem = (event)=>{
            event.preventDefault();
            setSwitchPosition(!switchPosition);

            const oldTempInCaseOfError = {...databaseInfo};
            const oldImgUrls = [...imgUrls];
            let temp = {...databaseInfo};
            delete temp.data[selectedDepartment][selectedSubDepartment][selectedItem];

            setDatabaseInfo(temp);
            setSelectedDepartment("");
            setSelectedSubDepartment("");
            setSelectedItem("");

            let tempDatabaseInfoForSaving = {...temp.data};
            const productRef = doc(db, "website_data", "products");
            setDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                oldImgUrls.forEach((img, index)=>{
                    // Create a reference to the file to delete
                    const imgRef = ref(storage, `${img}`);

                    // Delete the file
                    deleteObject(imgRef).then(() => {
                    // File deleted successfully
                        toast.success(`Img ${index+1} has been deleted`,  {style: {backgroundColor:'var(--success)'}});
                    }).catch((error) => {
                        toast.error(`Error deleting images - contact admin: ${error}`, {style: {backgroundColor:'var(--error)'}});
                    });
                });
                toast.success(`Inventory item has been deleted`,  {style: {backgroundColor:'var(--success)'}});
                setModalShow(false);
            }).catch((error)=>{
                toast.error(`Error updating inventory: ${error}`, {style: {backgroundColor:'var(--error)'}});
                setDatabaseInfo(oldTempInCaseOfError);
                setModalShow(false);
            });
        }
        const updateItem = (event)=>{
            event.preventDefault();
            // make proper deep copy and add new item info to specified dep and sub
            const oldTempInCaseOfError = {...databaseInfo}
            const oldSelectedDepInCaseOfError = selectedDepartment;
            const oldSelectedSubInCaseOfError = selectedSubDepartment;
            const oldSelectedItemInCaseOfError = selectedItem;
            
            let temp = {
                ...databaseInfo,
                data: {
                    ...databaseInfo.data,
                    [department]:{
                        ...databaseInfo.data[department],
                        [subdepartment]:{
                            ...databaseInfo.data[department][subdepartment],
                            [item]:{
                                // get info from inputs
                                price: price === undefined ? "" : price.toString(),
                                quantity: quantity === undefined ? "" : quantity,
                                image_urls:[...imgUrls],
                                other_departments: otherDepartments,
                                color: color === undefined ? "" : color,
                                description: description === undefined ? "" : description,
                                display: display === undefined ? false : typeof(display) === 'boolean' ? display : display.toLowerCase() === 'true',
                                finish: finish === undefined ? "" : finish,
                                height: height === undefined ? "" : height,
                                length: length === undefined ? "" : length,
                                material: material === undefined ? "" : material,
                                min_quantity: 0,
                                sale: false,
                                width: width === undefined ? "" : width
                            }
                        }
                    }
                },
            };
            // check if department or subdepartment has changed - if yes delete item from original
            if(department !== selectedDepartment || subdepartment !== selectedSubDepartment || item !== selectedItem){
                delete temp.data[selectedDepartment][selectedSubDepartment][selectedItem];
                setDatabaseInfo(temp);
                setSelectedDepartment(department);
                setSelectedSubDepartment(subdepartment);
                setSelectedItem(item);
            }else{
                setDatabaseInfo(temp);
            }
            
            let tempDatabaseInfoForSaving = {...temp.data};
            // console.log(tempDatabaseInfoForSaving)
            const productRef = doc(db, "website_data", "products");
            setDoc(productRef, tempDatabaseInfoForSaving).then(()=>{
                toast.success(`Inventory has been updated`,  {style: {backgroundColor:'var(--success)'}});
                setImgSwitchPosition(false);
                setModalShow(false);
            }).catch((error)=>{
                toast.error(`Error updating inventory: ${error}`, {style: {backgroundColor:'var(--error)'}});
                setDatabaseInfo(oldTempInCaseOfError);
                setSelectedDepartment(oldSelectedDepInCaseOfError);
                setSelectedSubDepartment(oldSelectedSubInCaseOfError);
                setSelectedItem(oldSelectedItemInCaseOfError);
                setImgSwitchPosition(false);
                setModalShow(false);
            });
        };
        const updateImgPosition = (event, oldIndex, newIndex) =>{
            event.preventDefault();
            let tempUrls = [...imgUrls];
            const removed = tempUrls.splice(oldIndex, 1);
            tempUrls.splice(newIndex, 0, removed[0]);
            setImgUrls([...tempUrls]);
        };
        const allowDrop = (e) =>{
            e.preventDefault();
        };
        const dragStart = (e, oldIndex) =>{
            dragItem.current = oldIndex;
        };
        const dragEnter = (e, newIndex) =>{
            dragOverItem.current = newIndex;
        }
        const drop = (e) =>{
            e.preventDefault();
            updateImgPosition(e, dragItem.current, dragOverItem.current);
        };
        const addSecondaryCategory = (e) =>{
            e.preventDefault();

            if(secondaryDepartmentSelection === "" || secondaryDepartmentSelection === undefined){
                toast.error('Department has not been selected', {style: {backgroundColor:'var(--error)'}});
                return null;
            }
            if(secondarySubDepartmentSelection === "" || secondarySubDepartmentSelection === undefined){
                toast.error('Sub-Department has not been selected', {style: {backgroundColor:'var(--error)'}});
                return null;
            }
            let tempOtherDepartments = [...otherDepartments];
            tempOtherDepartments.push({department: secondaryDepartmentSelection, subdepartment: secondarySubDepartmentSelection});
            setOtherDepartments(tempOtherDepartments);
        };
        const removeSecondaryCategory = (e, index) =>{
            let tempOtherDepartments = [...otherDepartments];
            tempOtherDepartments.splice(index, 1);
            setOtherDepartments(tempOtherDepartments);
        };
        return(
            <Modal
                {...props}
                size="lg"
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.item}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={4}>Category:</Col>
                            <Col>
                                <Form.Select value={department} onChange={(e) => {
                                    if(e.target.value === ''){
                                        setSubDepartmentSelections([]);
                                        return null;
                                    }
                                    setSubDepartmentSelections([...Object.keys(databaseInfo?.data?.[e.target.value])].sort());
                                    setDepartment(e.target.value)
                                    setSubDepartment([...Object.keys(databaseInfo?.data?.[e.target.value])].sort()[0])
                                }} disabled>
                                    {
                                        databaseInfo.departments.map((dep)=>{
                                            return <option key={`department_${dep}`} value={dep}>{dep}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Sub-Category:</Col>
                            <Col>
                                <Form.Select value={subdepartment} onChange={(e) => setSubDepartment(e.target.value)} disabled>
                                    {
                                         subDepartmentSelections.map((sub)=>{
                                            return <option key={`sub_select_${sub}`} value={sub}>{sub}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Item Name:</Col>
                            <Col>
                                <Form.Control type="text" value={item} onChange={(e) => setItem(e.target.value)} disabled />
                            </Col>
                        </Row>
                        <hr />
                        <Col xs={4}>Add Image:</Col>
                        <Col>
                        {
                            selectedDepartment !== '' && selectedSubDepartment !== '' && selectedItem !== '' ?
                                <Form className="plus-form" onSubmit={uploadPicHandler}>
                                    <label className="plus-label-modal">
                                        <input className="plus-label-input" type="file" onChange={uploadPicHandler} />
                                        <span>+</span>
                                    </label>
                                    <div>
                                        {error && <Alert variant="danger">{error}</Alert>}
                                        {file && <Alert variant="success">{file.name}</Alert>}
                                        {file && <ProgressBar
                                            file={file}
                                            setFile={setFile}
                                            newItemDepartmentName={selectedDepartment}
                                            newItemSubDepartmentName={selectedSubDepartment}
                                            newItemName={selectedItem}
                                            setNewItemDepartmentName={setSelectedDepartment}
                                            setNewItemSubDepartmentName={setSelectedSubDepartment}
                                            setNewItemName={setItem}
                                            databaseInfo={databaseInfo}
                                            setDatabaseInfo={setDatabaseInfo}
                                            from={'modal'}
                                        />}
                                    </div>
                                </Form>
                            :
                            ''
                        }
                        </Col>
                        <hr />
                        <Row className="mt-3">
                            <Col xs={4}>Display:</Col>
                            <Col>
                                <Form.Select value={display} onChange={(e) => setDisplay(e.target.value)}>
                                    <option key={`${true}`} value={true}>True</option>
                                    <option key={`${false}`} value={false}>False</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Whse Qty:</Col>
                            <Col>
                                <Form.Control type="number" value={quantity} onChange={(e) => setQuantity(e.target.value === "" ? 0 : parseInt(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Rented Qty:</Col>
                            <Col>
                                {rentedItems?.[department]?.[subdepartment]?.[item] !== undefined ? rentedItems?.[department]?.[subdepartment]?.[item] : 0}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Total Qty:</Col>
                            <Col>
                                {quantity + (rentedItems?.[department]?.[subdepartment]?.[item] !== undefined ? rentedItems?.[department]?.[subdepartment]?.[item] : 0)}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Price $:</Col>
                            <Col>
                                <Form.Control type="number" value={price} onChange={(e) => setPrice(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Description:</Col>
                            <Col>
                                <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Color:</Col>
                            <Col>
                                <Form.Control type="text" value={color} onChange={(e) => setColor(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Material:</Col>
                            <Col>
                                <Form.Control type="text" value={material} onChange={(e) => setMaterial(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Finish:</Col>
                            <Col>
                                <Form.Control type="text" value={finish} onChange={(e) => setFinish(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Height (inches):</Col>
                            <Col>
                                <Form.Control type="number" value={height} onChange={(e) => setHeight(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Width (inches):</Col>
                            <Col>
                                <Form.Control type="number" value={width} onChange={(e) => setWidth(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Length (inches):</Col>
                            <Col>
                                <Form.Control type="number" value={length} onChange={(e) => setLength(e.target.value === "" ? "" : parseFloat(e.target.value))} />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={4}>Secondary Categories:</Col>
                            <Col>
                                <hr />
                                <Row>
                                    <Form.Select value={secondaryDepartmentSelection} onChange={(e) => {
                                        if(e.target.value === ''){
                                            setSecondaryDepartmentSelection(e.target.value);
                                            setSecondarySubDepartmentSelection("");
                                            setSecondarySubDepartmentChoices([]);
                                            return null;
                                        }
                                        setSecondarySubDepartmentChoices([...Object.keys(databaseInfo?.data?.[e.target.value])].sort());
                                        setSecondaryDepartmentSelection(e.target.value)
                                        setSecondarySubDepartmentSelection([...Object.keys(databaseInfo?.data?.[e.target.value])].sort()[0]);
                                    }} disabled={switchPosition}>
                                        <option value=""></option>
                                        {
                                            databaseInfo.departments.map((dep)=>{
                                                return <option key={`multi_dep_${dep}`} value={dep}>{dep}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Row>
                                <Row className="mt-3">
                                    <Form.Select value={secondarySubDepartmentSelection} onChange={(e) => setSecondarySubDepartmentSelection(e.target.value)} disabled={switchPosition}>
                                        <option value=""></option>
                                        {
                                            secondarySubDepartmentChoices.map((sub)=>{
                                                if(sub !== subdepartment){
                                                    return <option key={`multi_sub_select_${sub}`} value={sub}>{sub}</option>
                                                }else{
                                                    return ''
                                                }
                                            })
                                        }
                                    </Form.Select>
                                </Row>
                                <Row className="mt-3">
                                    <Button
                                        style={{width:"50%"}}
                                        onClick={(e)=>addSecondaryCategory(e)}
                                        disabled={switchPosition || secondaryDepartmentSelection === "" || secondaryDepartmentSelection === undefined || secondarySubDepartmentSelection === "" || secondarySubDepartmentSelection === undefined}
                                    >
                                        Add Secondary Category
                                    </Button>
                                </Row>
                                <hr />
                                <Row>Current Secondary Categories:</Row>
                                <ol>
                                {
                                    otherDepartments.map((set, index)=>{
                                        return(
                                            <li key={index}>{set.department} -> {set.subdepartment} <Button onClick={(e)=>removeSecondaryCategory(e, index)} style={{margin:"10px"}} disabled={switchPosition}><FontAwesomeIcon icon={faMinus} /></Button></li>
                                        )
                                    })
                                }
                                </ol>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="mt-3" style={{display:"flex", justifyContent:"center"}}>
                            <Button style={{width:"50%"}} onClick={(e)=>updateItem(e)} disabled={switchPosition}>Update Data</Button>
                        </Row>
                        <hr />
                        <Row className="mt-3">
                            <Col xs={4}>Images:</Col>
                        </Row>
                        {
                            imgUrls.map((link, index) => {
                                return(
                                    <Row key={`img_${index}`}>
                                        <Col xs={4}>
                                            <Row>{index+1}.</Row>
                                            <Row>Choose new position:</Row>
                                            <Row>
                                            {
                                                imgUrls.map((link, newIndex) => {
                                                    if(newIndex !== index){
                                                        return(
                                                            <Col key={`button_${newIndex}`}><Button onClick={(e)=>updateImgPosition(e, index, newIndex)}>{newIndex+1}</Button></Col>
                                                        )
                                                    }else{
                                                        return ''
                                                    }
                                                })
                                            }
                                            </Row>
                                            <Row style={{marginTop:"20px"}}>
                                                <Button onClick={(e)=>deleteImage(e, link, index)} disabled={!imgSwitchPosition}>Delete Image</Button>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <div onDragOver={(e)=>allowDrop(e)} onDragEnter={(e)=>dragEnter(e, index)} onDrop={(e)=>drop(e)} style={{display:"flex", alignItems:"flex-start"}}>
                                                <Image src={link} alt={item} style={{width:"100%", height:"auto"}} draggable="true" onDragStart={(e)=>dragStart(e, index)} />
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Container>
                    <hr />
                    {
                        imgUrls.length > 0 && 
                            <Container>
                                <div>
                                    <Form.Check
                                        type="switch"
                                        id="custom-img-switch"
                                        label="Enable image delete buttons"
                                        checked={imgSwitchPosition}
                                        onChange={()=>setImgSwitchPosition(!imgSwitchPosition)}
                                    />
                                </div>
                            </Container>
                    }
                    <hr />
                    <Container>
                        <div>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Enable delete button"
                                checked={switchPosition}
                                onChange={()=>setSwitchPosition(!switchPosition)}
                            />
                        </div>
                        <div><Button disabled={!switchPosition} onClick={(e)=>deleteItem(e)}>Delete Entire Item</Button></div>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    };

    return(
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        Warehouse
                    </Row>
                    <Row className="mt-4 justify-content-center">
                        <Button className="justify-content-center" style={{width:"200px"}} onClick={()=>setNewModalShow(true)}>Add New Item</Button>
                    </Row>
                </Container>
                <Container fluid className="mt-4">
                    {
                        databaseInfo.departments.sort().map((department, index)=>{
                            return(
                                <Row key={`deparment_${index}`}className="justify-content-center mt-4">
                                    <Row className="justify-content-center bg-secondary text-white" style={{marginBottom:"20px"}}>{department}</Row>
                                    {
                                        Object.keys(databaseInfo.data[department]).sort().map((subDepartment, index)=>{
                                            return(
                                                <Row key={`subdepartment_${index}`} className="justify-content-center">
                                                    <Row className="justify-content-center">
                                                        <Col className="justify-content-center" style={{paddingLeft:"20px", borderBottom:"1px solid black"}}>
                                                            <div
                                                                style={{display:"inline-block", padding:"5px", cursor:"pointer"}}
                                                                onClick={()=>{
                                                                    setSelectedDepartment(department);
                                                                    setSelectedSubDepartment(subDepartment);
                                                                    setNewModalShow(true);
                                                                }}
                                                            >
                                                                {subDepartment} - {Object.keys(databaseInfo?.data?.[department]?.[subDepartment]).length}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                    {
                                                        Object.keys(databaseInfo.data[department][subDepartment]).sort().map((item, index)=>{
                                                            return(
                                                                <Col
                                                                    key={`item_${index}`}
                                                                    md="5"
                                                                    lg="3"
                                                                    className="justify-content-center"
                                                                    style={{border:"1px solid black", borderRadius:"5px", margin:"20px", cursor:"pointer"}}
                                                                    onClick={(e)=>{
                                                                        // onclick send currentDepartment, currentSubDepartment, currentItem (strings), then update databaseInfo from props 
                                                                        // deleteItem(e, department, subDepartment, item); // temp test
                                                                        // changeItemsSubdepartment(e, department, subDepartment, item, "Lamps"); // temp test
                                                                        // addItem(e, "Outdoor", "Bistro Sets", "Swing Set");
                                                                        setSelectedDepartment(department);
                                                                        setSelectedSubDepartment(subDepartment);
                                                                        setSelectedItem(item);
                                                                        setModalShow(true);
                                                                    }}
                                                                >
                                                                    <div style={{fontWeight:"bold"}}>
                                                                        {item}
                                                                        {
                                                                            databaseInfo.data?.[department]?.[subDepartment]?.[item]?.["image_urls"]?.length > 0 && databaseInfo.data?.[department]?.[subDepartment]?.[item]?.["display"] === true ?
                                                                                <div className="greenDot"></div>
                                                                            :
                                                                                <div className="redDot"></div>
                                                                        }
                                                                        {
                                                                            databaseInfo.data?.[department]?.[subDepartment]?.[item]?.["other_departments"]?.length > 0 ?
                                                                                <FontAwesomeIcon style={{marginLeft:"5px", color:"green"}} icon={faPlus} />
                                                                            :
                                                                                ''
                                                                        }
                                                                    </div>
                                                                    {
                                                                       Object.keys(databaseInfo.data[department][subDepartment][item]).sort().map((infoTitle, index)=>{
                                                                            // if image_urls show image 0
                                                                            // if quantity show quantity
                                                                            // if price show price
                                                                            if(infoTitle === "quantity"){
                                                                                return(
                                                                                    <div key={`infoTitle_${index}`}>
                                                                                        <div key={`infoTitle_whseqty_${index}`}>whse qty: {databaseInfo.data[department][subDepartment][item][infoTitle]}</div>
                                                                                        <div key={`infoTitle_rentedqty_${index}`} style={rentedItems?.[department]?.[subDepartment]?.[item] !== undefined ? {color:'red'} : {color:'black'}}>rented qty: {rentedItems?.[department]?.[subDepartment]?.[item] !== undefined ? rentedItems?.[department]?.[subDepartment]?.[item] : 0}</div>
                                                                                        <div key={`infoTitle_total_${index}`}>total qty: {databaseInfo.data[department][subDepartment][item][infoTitle] + (rentedItems?.[department]?.[subDepartment]?.[item] !== undefined ? rentedItems?.[department]?.[subDepartment]?.[item] : 0)}</div>
                                                                                    </div>
                                                                                )
                                                                            }else if(infoTitle === "price"){
                                                                                return(
                                                                                    <div key={`infoTitle_${index}`}>{infoTitle}: {databaseInfo.data[department][subDepartment][item][infoTitle]}</div>
                                                                                )
                                                                            }
                                                                            else if(infoTitle === "display"){
                                                                                return(
                                                                                    <div key={`infoTitle_${index}`}>{infoTitle}: {databaseInfo.data[department][subDepartment][item][infoTitle] === true ? 'True' : 'False'}</div>
                                                                                )
                                                                            }else if(infoTitle === "image_urls"){
                                                                                return(
                                                                                    <div key={`infoTitle_${index}`}># of Images: {databaseInfo.data[department][subDepartment][item][infoTitle].length}</div>
                                                                                )
                                                                            }else{
                                                                                return(
                                                                                    <div key={`infoTitle_${index}`}></div>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Col>
                                                            )
                                                        })
                                                    }
                                                    </Row>
                                                </Row>
                                            )
                                        })
                                    }
                                </Row>
                            )
                        })
                    }
                </Container>
                <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "10vh"}}>
                    <div style={{maxWidth: "600px"}}>
                        {currentUser?.email}
                    </div>
                </Container>
            </Container>
            <ItemModal
                show={modalShow}
                onHide={()=> {
                    setSwitchPosition(false);
                    setImgSwitchPosition(false);
                    setSelectedDepartment("");
                    setSelectedSubDepartment("");
                    setModalShow(false);
                }}
                department={selectedDepartment}
                subdepartment={selectedSubDepartment}
                item={selectedItem}
            />
            <NewItemModal
                show={newModalShow}
                onHide={()=>{
                    setSwitchPosition(false);
                    setImgSwitchPosition(false);
                    setSelectedDepartment("");
                    setSelectedSubDepartment("");
                    setNewModalShow(false);
                }}
                department={selectedDepartment}
                subdepartment={selectedSubDepartment}
            />
        </> 
    );
};