import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { db } from '../firebase-config';
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default function EmployeeHowItWorksSettings() {

    const [howItWorksInfo, setHowItWorksInfo] = useState({});
    
    useEffect(()=>{
        const checkoutRef = doc(db, "website_data", "how_it_works");
        getDoc(checkoutRef).then((docSnap)=>{
            if(docSnap.exists()){
                const info = docSnap.data();
                // console.log(info);
                setHowItWorksInfo(info);
            }else{
                toast.warning(`Database Connectivity Issues: data did not load properly`, {style: {backgroundColor:'var(--warning)'}});
            }
        }).catch((error)=>{
            toast.error(`Error getting checkout settings: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    }, []);

    const saveHowItWorksInfo = (e) => {
        e.preventDefault();
        const updateObjRef = doc(db, "website_data", "how_it_works");
        updateDoc(updateObjRef, howItWorksInfo).then(()=>{
            toast.success('Information updated.', {style: {backgroundColor:'var(--success)'}});
        }).catch((error)=>{
            toast.error(`Error saving data: ${error}`, {style: {backgroundColor:'var(--error)'}});
        });
    };
    
    return (
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        How It Works Settings
                    </Row>
                </Container>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>How It Works Title</Form.Label>
                        <div style={{fontSize:"0.65em"}}>The title of the How It Works page.</div>
                        <Form.Control type="text" value={howItWorksInfo?.title !== undefined ? howItWorksInfo?.title : ""} name="title" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                title: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Display Numbers</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Choose if numbers should display</div>
                        <div style={{fontSize:"0.65em"}}> True will display numbers, False will not display numbers</div>
                        <Form.Select type="number" value={howItWorksInfo?.display_numbers !== undefined ? howItWorksInfo?.display_numbers : false} name="display_numbers" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                display_numbers: event.target.value.toUpperCase() === "TRUE" ? true : false  
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }}>
                            <option key="true" value="true">True</option>
                            <option key="false" value="false">False</option>
                        </Form.Select>
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Step One Title</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Title for step one</div>
                        <div style={{fontSize:"0.65em"}}>Leave blank for no title</div>
                        <Form.Control type="text" value={howItWorksInfo?.step_one_title !== undefined ? howItWorksInfo?.step_one_title : ""} name="step_one_title" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                step_one_title: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Step One Information</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Information for step one</div>
                        <Form.Control as="textarea" rows="3" value={howItWorksInfo?.step_one !== undefined ? howItWorksInfo?.step_one : ""} name="step_one" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                step_one: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Step Two Title</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Title for step two</div>
                        <div style={{fontSize:"0.65em"}}>Leave blank for no title</div>
                        <Form.Control type="text" value={howItWorksInfo?.step_two_title !== undefined ? howItWorksInfo?.step_two_title : ""} name="step_two_title" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                step_two_title: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Step Two Information</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Information for step two</div>
                        <Form.Control as="textarea" rows="3" value={howItWorksInfo?.step_two !== undefined ? howItWorksInfo?.step_two : ""} name="step_two" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                step_two: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Step Three Title</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Title for step three</div>
                        <div style={{fontSize:"0.65em"}}>Leave blank for no title</div>
                        <Form.Control type="text" value={howItWorksInfo?.step_three_title !== undefined ? howItWorksInfo?.step_three_title : ""} name="step_three_title" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                step_three_title: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Step Three Information</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Information for step three</div>
                        <Form.Control as="textarea" rows="3" value={howItWorksInfo?.step_three !== undefined ? howItWorksInfo?.step_three : ""} name="step_three" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                step_three: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Note 1</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Note 1 Information</div>
                        <div style={{fontSize:"0.65em"}}>Leave blank for no note</div>
                        <Form.Control type="text" value={howItWorksInfo?.note_1 !== undefined ? howItWorksInfo?.note_1 : ""} name="note_1" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                note_1: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Note 2</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Note 2 Information</div>
                        <div style={{fontSize:"0.65em"}}>Leave blank for no note</div>
                        <Form.Control type="text" value={howItWorksInfo?.note_2 !== undefined ? howItWorksInfo?.note_2 : ""} name="note_2" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                note_2: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Form.Label>Note 3</Form.Label>
                        <div style={{fontSize:"0.65em"}}>Note 1 Information</div>
                        <div style={{fontSize:"0.65em"}}>Leave blank for no note</div>
                        <Form.Control type="text" value={howItWorksInfo?.note_3 !== undefined ? howItWorksInfo?.note_3 : ""} name="note_3" onChange={(event)=>{
                            event.preventDefault();
                            let tempHowItWorksInfo = {
                                ...howItWorksInfo,
                                note_3: event.target.value,
                            };
                            setHowItWorksInfo(tempHowItWorksInfo);
                        }} />
                    </div>
                </div>
            </Container>
            
            <Container className="d-flex align-items-center justify-content-center">
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <div className="mt-4">
                        <Button className="mt-3" onClick={(e) => saveHowItWorksInfo(e)}>Update How It Works Page</Button>
                    </div>
                </div>
            </Container>
        </>
    )
}