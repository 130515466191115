// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth/*, connectAuthEmulator*/  } from "firebase/auth";
import { getFirestore/*, connectFirestoreEmulator*/ } from "firebase/firestore";
import { getStorage/*, connectStorageEmulator*/ } from "firebase/storage";
import { getFunctions/*, connectFunctionsEmulator*/ } from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlb4RHjZrfhMG9tEXlzALa-hdw8486DW4",
  authDomain: "vosburghrentals-fb0b8.firebaseapp.com",
  projectId: "vosburghrentals-fb0b8",
  storageBucket: "vosburghrentals-fb0b8.appspot.com",
  messagingSenderId: "306599496874",
  appId: "1:306599496874:web:46a0e84a2069320bda94d3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
// connectAuthEmulator(auth, "http://127.0.0.1:9099");

export const db = getFirestore(app);
// connectFirestoreEmulator(db, '127.0.0.1', 8080);

export const storage = getStorage(app);
// connectStorageEmulator(storage, '127.0.0.1', 9199)

export const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "127.0.0.1", 5001);