import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Image, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'

import Filter from '../Components/Filter';

export default function Products({databaseInfo, selectedDepartment, setSelectedDepartment, screenWidth, setNavExpanded, setSelectedItem, selectedSubDepartment, setSelectedSubDepartment, subDepartmentList, setSubDepartmentList, secondaryDepInfo}) {
    const [titlePosition, setTitlePosition] = useState(0);
    const [productsPosition, setProductsPosition] = useState(0);
    const [filterHeight, setFilterHeight] = useState(500);
    const [openFilters, setOpenFilters] = useState([selectedDepartment]);

    const [info, setInfo] = useState({data:{}, departments: []});

    const [showFilter, setShowFilter] = useState(false);
    const handleCloseFilter = () => setShowFilter(false);
    const handleShowFilter = () => {
        setShowFilter(true);
        setNavExpanded(false);
    };

    const merge = require('deepmerge');

    const selectSubDepartmentHandled = useRef(false);

    const navigate = useNavigate();
    
    /*useEffect(()=>{
        window.scrollTo({top:0, behavior:'smooth'});
        //if(!selectSubDepartmentHandled.current){
            //setSelectedSubDepartment('');
        //}
        //selectSubDepartmentHandled.current = false;
    }, [setSelectedSubDepartment]);*/

    useEffect(()=>{
        window.scrollTo({top:0, behavior:'smooth'});
        // if(!selectSubDepartmentHandled.current){
        //     setSelectedSubDepartment('');
        // }
        setOpenFilters((previous)=>[...previous, selectedDepartment]);
        if(selectedDepartment !== ''){
            setSubDepartmentList(Object.keys(databaseInfo.data[selectedDepartment]));
        }else{
            setSubDepartmentList([]);
        }
        selectSubDepartmentHandled.current = false;
        window.scrollTo({top:0, behavior:'smooth'});
        // console.log(databaseInfo);
        // console.log(secondaryDepInfo);
        // console.log(selectedDepartment);
        // console.log(selectedSubDepartment);
        let tempInfo = merge(databaseInfo, secondaryDepInfo);
        const tempDepartments = [...new Set([...databaseInfo.departments, ...secondaryDepInfo.departments])];
        tempInfo = {
            ...tempInfo,
            departments: [
                ...tempDepartments
            ]
        };
        setInfo(tempInfo);
    }, [databaseInfo, merge, secondaryDepInfo, selectedDepartment, selectedSubDepartment, setSelectedSubDepartment, setSubDepartmentList])

    const handleDepartmentClick = (department)=>{
        if(department === ''){
            setSelectedDepartment('');
            setSubDepartmentList([]);
            setSelectedSubDepartment('');
            navigate(`/products`);
        }else{
            console.log(encodeURI(department));
            if(!openFilters.includes(department)){
                setOpenFilters([...openFilters, department]);
            }
            setSelectedDepartment(department);
            setSubDepartmentList(Object.keys(databaseInfo.data[department]));
            setSelectedSubDepartment('');
            selectSubDepartmentHandled.current = true;
            navigate(`/${encodeURI(department)}`);
        }
        //handleCloseFilter();
    };

    const handleSubDepartmentClick = (department, subDepartment)=>{
        setSelectedDepartment(department);
        setSubDepartmentList(Object.keys(databaseInfo.data[department]));
        navigate(`/${encodeURI(department)}`);
        setSelectedSubDepartment(subDepartment);
        selectSubDepartmentHandled.current = true;
        handleCloseFilter();
    };

    const handleItemClick = (item, subDep=selectedSubDepartment, dep=selectedDepartment)=>{
        let newDep = dep;
        let newSubDep = subDep;
        if(info?.data?.[dep]?.[subDep]?.[item]?.original_department !== undefined){
            newDep  = info?.data?.[dep]?.[subDep]?.[item]?.original_department;
            newSubDep  = info?.data?.[dep]?.[subDep]?.[item]?.original_subdepartment;
        }
        setSelectedItem(
            {
                department: newDep,
                subdepartment: newSubDep,
                itemInfo:{
                    name: item,
                    info: info.data[newDep][newSubDep][item]
                }
            });
        navigate(`/productdetails`);
    };

    const handleChevronClick = (department)=>{
        if(openFilters.includes(department)){
            setOpenFilters(openFilters.filter(dep => dep !== department));
        }else{
            setOpenFilters([...openFilters, department]);
        }
    };

    const productsStyle = {
        "greaterThan992": {marginLeft:"300px", minHeight:`${filterHeight*2}px`},
        "lessThan992": {marginLeft:"0px", minHeight:`${filterHeight*2}px`}
    }[screenWidth];

    const itemInfo = {textAlign:"left", fontSize:"0.8em", marginTop:"10px"};
    const itemInfoBold = {textAlign:"left", fontSize:"0.8em", fontWeight:"bold", marginTop:"10px"};
    const itemInfoPrice = {textAlign:"left", fontSize:"0.8em", marginTop:"2px"};
    const categoryMarginTop = 80;

    return (
        <>
            <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}
                ref={element =>{
                    if(!element) return;
                    setTitlePosition(element.getBoundingClientRect().bottom);
                }}
            >
                <Row className="justify-content-center">
                    {
                        selectedDepartment === '' ?
                            'products'.toUpperCase()
                        :
                            (selectedDepartment).toUpperCase()
                    }
                </Row>
                <Row  className="justify-content-center" style={{fontSize:"0.7em"}}>{
                            selectedSubDepartment !== '' ?
                                selectedSubDepartment
                            :
                                `All ${selectedDepartment}`
                }</Row>
            </Container>
            {
                screenWidth === 'lessThan992' ?
                    <div style={{display:"flex", alignItems:"center", marginLeft:"10px"}}>
                        <div
                            ref={element =>{
                                if(!element) return;
                                let elTop = element.getBoundingClientRect().top;
                                let elHeight = element.getBoundingClientRect().height;
                                setFilterHeight(elHeight);
                                if(titlePosition > 0 && (elTop+elHeight) < productsPosition){
                                    element.style.position = 'absolute';
                                    element.style.top = '';
                                    element.style.bottom = '';
                                    element.style.borderBottom = '';     
                                }
                                if(productsPosition > 0){
                                    if(titlePosition < 0 && (elTop+elHeight+elHeight) < productsPosition){
                                        element.style.position = 'fixed';
                                        element.style.top = `-${categoryMarginTop}px`;
                                        element.style.borderBottom = '1px solid lightgrey';
                                    }
                                }
                                if(productsPosition < 0){
                                    if((elTop+elHeight) >= productsPosition){
                                        element.style.position = 'fixed';
                                        element.style.top = `${productsPosition-elHeight-categoryMarginTop}px`;
                                        element.style.borderBottom = '1px solid lightgrey';
                                    }
                                }
                            }} 
                            onClick={handleShowFilter}
                            style={{cursor:"pointer", marginTop:`${categoryMarginTop}px`, paddingTop:"10px", height:"60px", backgroundColor:"white", width: "97%"}}
                        >
                            Categories
                            <FontAwesomeIcon style={{fontSize: '1em', marginLeft:'5px'}} icon={faChevronCircleRight} />     
                        </div>
                        <Offcanvas show={showFilter} onHide={handleCloseFilter}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Categories</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Filter databaseInfo={databaseInfo} selectedDepartment={selectedDepartment} selectedSubDepartment={selectedSubDepartment} handleDepartmentClick={handleDepartmentClick} handleSubDepartmentClick={handleSubDepartmentClick} handleChevronClick={handleChevronClick} openFilters={openFilters} secondaryDepInfo={secondaryDepInfo} />
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                :
                    <div
                        ref={element =>{
                            if(!element) return;
                            let elTop = element.getBoundingClientRect().top;
                            let elHeight = element.getBoundingClientRect().height;
                            setFilterHeight(elHeight);
                            if(titlePosition > 0 && (elTop+elHeight) < productsPosition){
                                element.style.position = 'absolute';
                                element.style.top = '';
                                element.style.bottom = '';              
                            }
                            if(titlePosition < 0 && (elTop+elHeight+elHeight) < productsPosition){
                                element.style.position = 'fixed';
                                element.style.top = '0px';
                            }
                            if((elTop+elHeight) >= productsPosition){
                                element.style.position = 'fixed';
                                element.style.top = `${productsPosition-elHeight}px`;
                            }
                        }}
                        style={{position:"absolute", left:"0px", width:"300px", paddingTop:"30px", paddingLeft:"20px"}}
                    >
                        <div style={{marginBottom:"20px"}}>Categories</div>
                        <Filter databaseInfo={databaseInfo} selectedDepartment={selectedDepartment} selectedSubDepartment={selectedSubDepartment} handleDepartmentClick={handleDepartmentClick} handleSubDepartmentClick={handleSubDepartmentClick} handleChevronClick={handleChevronClick} openFilters={openFilters} secondaryDepInfo={secondaryDepInfo} />     
                    </div>
            }
            
            <div style={productsStyle}
                ref={element =>{
                    if(!element) return;
                    setProductsPosition(element.getBoundingClientRect().bottom);
                }}
            >
                <div style={{textAlign:"center", padding:"20px", marginTop:"40px"}}>
                    <div>
                        
                        <Container fluid="lg" className="mt-3 mb-5">
                            <Row>
                            {
                                subDepartmentList.length ?
                                    //  Department
                                    selectedSubDepartment === '' ?
                                        subDepartmentList.map((subDep)=>{
                                            if(databaseInfo?.data?.[selectedDepartment]?.[subDep] || secondaryDepInfo?.data?.[selectedDepartment]?.[subDep]){
                                                const tempItems_1 = databaseInfo?.data?.[selectedDepartment]?.[subDep] !== undefined ? Object.keys(databaseInfo?.data?.[selectedDepartment]?.[subDep] ): [];
                                                const tempItems_2 = secondaryDepInfo?.data?.[selectedDepartment]?.[subDep] !== undefined ? Object.keys(secondaryDepInfo?.data?.[selectedDepartment]?.[subDep]) : [];
                                                // const items = [...tempItems_1];

                                                const items = [...new Set([...tempItems_1, ...tempItems_2])];
                                                // console.log(items);
                                                
                                                // console.log(info);
                                                if(items.length){
                                                    return(
                                                        items.map((item)=>{
                                                            //if(databaseInfo?.data?.[selectedDepartment]?.[subDep])
                                                            if(info?.data?.[selectedDepartment]?.[subDep]?.[item]?.display && info?.data?.[selectedDepartment]?.[subDep]?.[item]?.image_urls?.length){
                                                                return(
                                                                    <Col md={6} lg={4}  key={item} style={{marginBottom:"2rem"}}>
                                                                        <div className="productContainer" onClick={()=>handleItemClick(item, subDep)} style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                                                                            <div style={{display:"flex", alignItems:"flex-start"}}>
                                                                                <Image src={info.data[selectedDepartment][subDep][item].image_urls[0]} alt={item} style={{width:"100%", height:"auto"}} />
                                                                            </div>
                                                                            <div style={{display:"flex", flexDirection:"column"}}>
                                                                                {
                                                                                    info.data[selectedDepartment][subDep][item]?.quantity <= 0 ?
                                                                                        <div style={itemInfo}>OUT OF STOCK</div>
                                                                                    :
                                                                                        <div style={itemInfo}>{info.data[selectedDepartment][subDep][item]?.quantity} IN STOCK</div>
                                                                                }
                                                                                <div style={itemInfoBold}>{item}</div>
                                                                                <div style={itemInfoPrice} className="number">${info.data[selectedDepartment][subDep][item]?.price}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                )
                                                            }else{
                                                                return('')
                                                            }
                                                        })
                                                    )
                                                }else{
                                                    return('')
                                                }
                                            }else{
                                                return('')
                                            }  
                                        })                                      
                                    :
                                        // Subdepartment
                                        info?.data?.[selectedDepartment]?.[selectedSubDepartment] &&
                                            Object.keys(info.data[selectedDepartment][selectedSubDepartment]).length &&
                                                Object.keys(info.data[selectedDepartment][selectedSubDepartment]).map((item)=>{
                                                    if(info.data[selectedDepartment][selectedSubDepartment][item].display && info.data[selectedDepartment][selectedSubDepartment][item].image_urls?.length){
                                                        return(
                                                            <Col md={6} lg={4}  key={item} style={{marginBottom:"2rem"}}>
                                                                <div className="productContainer" onClick={()=>handleItemClick(item)} style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                                                                    <div style={{display:"flex", alignItems:"flex-start"}}>
                                                                        <Image src={info.data[selectedDepartment][selectedSubDepartment][item].image_urls[0]} alt={item} />
                                                                    </div>
                                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                                        {
                                                                            info.data[selectedDepartment][selectedSubDepartment][item]?.quantity <= 0 ?
                                                                                <div style={itemInfo}>OUT OF STOCK</div>
                                                                            :
                                                                                <div style={itemInfo}>{info.data[selectedDepartment][selectedSubDepartment][item]?.quantity} IN STOCK</div>
                                                                        }
                                                                        <div style={itemInfoBold}>{item}</div>
                                                                        <div style={itemInfoPrice} className="number">${info.data[selectedDepartment][selectedSubDepartment][item]?.price}</div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    }else{
                                                        return ''
                                                    }
                                                })
                                :
                                    // All Products
                                    databaseInfo.departments.map((department)=>{
                                        const subDepartments = Object.keys(databaseInfo.data[department]);
                                        if(subDepartments.length){
                                            return(
                                                subDepartments.map((subDep)=>{
                                                    if(databaseInfo?.data?.[department]?.[subDep]){
                                                        const items = Object.keys(databaseInfo.data[department][subDep]);
                                                        if(items.length){
                                                            return(
                                                                items.map((item)=>{
                                                                    if(databaseInfo.data[department][subDep][item].display && databaseInfo.data[department][subDep][item].image_urls?.length){
                                                                        return(
                                                                            <Col md={6} lg={4}  key={item} style={{marginBottom:"2rem"}}>
                                                                                <div className="productContainer" onClick={()=>handleItemClick(item, subDep, department)} style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                                                                                    <div style={{display:"flex", alignItems:"flex-start"}}>
                                                                                        <Image src={databaseInfo.data[department][subDep][item].image_urls[0]} alt={item} />
                                                                                    </div>
                                                                                    <div style={{display:"flex", flexDirection:"column"}}>
                                                                                        {
                                                                                            databaseInfo.data[department][subDep][item]?.quantity <= 0 ?
                                                                                                <div style={itemInfo}>OUT OF STOCK</div>
                                                                                            :
                                                                                                <div style={itemInfo}>{databaseInfo.data[department][subDep][item]?.quantity} IN STOCK</div>
                                                                                        }
                                                                                        <div style={itemInfoBold}>{item}</div>
                                                                                        <div style={itemInfoPrice} className="number">${databaseInfo.data[department][subDep][item]?.price}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </Col>
                                                                        )
                                                                    }else{
                                                                        return('')
                                                                    }
                                                                })
                                                            )
                                                        }else{
                                                            return('')
                                                        }
                                                    }else{
                                                        return('')
                                                    }
                                                })
                                            )
                                        }else{
                                            return('');
                                        }
                                    })
                            }
                            
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    )
}
