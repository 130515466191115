import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { columnAlgo } from '../HelperFunctions/helper_functions';

import one from '../images/one.jpg';

export default function Home({databaseInfo, selectedDepartment, setSelectedDepartment, setSelectedSubDepartment, homePageInfo, screenWidth, secondaryDepInfo}){
    const navigate = useNavigate();

    const titleStyle = {
        "greaterThan992": {marginTop:"30px", marginBottom:"20px", fontSize:"2.25em"},
        "lessThan992": {marginTop:"20px", marginBottom:"20px", fontSize:"1.3em"}
    }[screenWidth];

    const handleDepartmentClick = (department)=>{
        setSelectedDepartment(department);
        if(department === ''){
            setSelectedDepartment('');
            setSelectedSubDepartment('');
            navigate("/products");
        }else{
            navigate(`/${encodeURI(department)}`);
        }
    }

    return(
        <div>
            <div style={{position:"relative", textAlign:"center", color:`${homePageInfo?.data?.text_color}`}}>
                {
                    homePageInfo?.data?.picture !== "" && homePageInfo?.data?.picture !== undefined ?
                        <Image fluid className="w-100" src={homePageInfo.data.picture} alt="Limitless Staging Rentals" style={{filter: `brightness(${homePageInfo?.data?.picture_brightness}%)`, maxHeight: "40rem", minHeight: "20rem"}}/>
                    :
                        <Image fluid className="w-100" src={one} alt="Limitless Staging Rentals" style={{filter: `brightness(${homePageInfo?.data?.picture_brightness}%)`, maxHeight: "40rem", minHeight: "20rem"}}/>
                }
                <Container fluid style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)", padding:"30px"}}>
                    {
                        homePageInfo?.data?.small_title !== "" && homePageInfo?.data?.small_title !== undefined &&
                            <div style={{fontSize:"1.2rem", letterSpacing: "0.2em", textTransform: "uppercase"}}>{homePageInfo.data.small_title}</div>
                    }
                    <div>
                        {
                            homePageInfo?.data?.main_title !== "" && homePageInfo?.data?.main_title !== undefined &&
                                <div style={titleStyle}>{homePageInfo.data.main_title}</div>
                        }
                        { 
                            homePageInfo?.data?.slogan !== "" && homePageInfo?.data?.slogan !== undefined &&
                            screenWidth === "greaterThan992" ?
                                <div>{homePageInfo.data.slogan}</div>
                            :
                                <div></div>
                        }
                    </div>
                    {
                        homePageInfo?.data?.button_text !== "" && homePageInfo?.data?.button_text !== undefined &&
                            <Button variant={homePageInfo?.data?.button_color} style={{marginTop:"20px"}} onClick={()=>handleDepartmentClick(`${homePageInfo.data.button_link}`)}>{homePageInfo.data.button_text}</Button>
                    }
                    <div>
                        {
                            homePageInfo?.data?.delivery_area !== "" && homePageInfo?.data?.delivery_area !== undefined &&
                            screenWidth === "greaterThan992" ?
                                <div style={{marginTop:"60px", fontSize:"1.5rem"}}>{homePageInfo?.data?.delivery_area}</div>
                            :
                                <div style={{marginTop:"40px", fontSize:"1.2rem"}}>{homePageInfo?.data?.delivery_area}</div>
                        }
                    </div>
                </Container>
            </div>
            <Container fluid="lg" className="mt-5 mb-5">
                <Row>
                {
                    databaseInfo.departments.map((department)=>{
                        const subDepartments = Object.keys(databaseInfo.data[department]);
                        if(subDepartments.length){
                            for(let x=0; x<subDepartments.length; ++x){
                                const items = Object.keys(databaseInfo.data[department][subDepartments[x]]);
                                let displayed = false;
                                if(items.length){
                                    for(let y=0; y<items.length; ++y){
                                        if(databaseInfo.data[department][subDepartments[x]][items[y]].display && databaseInfo.data[department][subDepartments[x]][items[y]].image_urls?.length){
                                            displayed = true;
                                            return(
                                                <Col md={6} lg={columnAlgo(databaseInfo.departments.length + secondaryDepInfo.departments.length)}  key={items[y]} className="departmentContainer" onClick={()=>handleDepartmentClick(department)}>
                                                    <div style={{marginBottom:"2rem"}}>{department}</div>
                                                    <div style={{width:"85%", height: "85%", margin:"auto", position:"relative", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                        <div style={{display:"flex", alignItems:"flex-start"}}>
                                                            <Image src={databaseInfo.data[department][subDepartments[x]][items[y]].image_urls[0]} alt={items[y]} style={{width:"100%", height:"auto"}} />
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    }
                                }
                                if(displayed){
                                    break;
                                }
                            }
                        }else{
                            return('')
                        }
                        return('');
                    })
                }
                {
                    secondaryDepInfo.departments.map((department)=>{
                        if(databaseInfo.departments.includes(department)){
                            return null;
                        }else{
                            const subDepartments = Object.keys(secondaryDepInfo.data[department]);
                            if(subDepartments.length){
                                for(let x=0; x<subDepartments.length; ++x){
                                    const items = Object.keys(secondaryDepInfo.data[department][subDepartments[x]]);
                                    let displayed = false;
                                    if(items.length){
                                        for(let y=0; y<items.length; ++y){
                                            if(secondaryDepInfo.data[department][subDepartments[x]][items[y]].display && secondaryDepInfo.data[department][subDepartments[x]][items[y]].image_urls?.length){
                                                displayed = true;
                                                return(
                                                    <Col md={6} lg={columnAlgo(databaseInfo.departments.length + secondaryDepInfo.departments.length)}  key={items[y]} className="departmentContainer" onClick={()=>handleDepartmentClick(department)}>
                                                        <div style={{marginBottom:"2rem"}}>{department}</div>
                                                        <div style={{width:"85%", height: "85%", margin:"auto", position:"relative", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                            <div style={{display:"flex", alignItems:"flex-start"}}>
                                                                <Image src={secondaryDepInfo.data[department][subDepartments[x]][items[y]].image_urls[0]} alt={items[y]} style={{width:"100%", height:"auto"}} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                )
                                            }
                                        }
                                    }
                                    if(displayed){
                                        break;
                                    }
                                }
                            }else{
                                return('')
                            }
                            return('');
                        }
                    })
                }
                </Row>
            </Container>
        </ div>
    )
}