import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function Search({ databaseInfo, lunrIndex, searchValue, setSelectedItem }) {
    const [searchResult, setSearchResult] = useState([]);

    const navigate = useNavigate();

    useEffect(()=>{
        setSearchResult(lunrIndex.search(searchValue));
    }, [lunrIndex, searchValue]);

    const handleItemClick = (item, subDep, dep)=>{
        setSelectedItem(
            {
                department: dep,
                subdepartment: subDep,
                itemInfo:{
                    name: item,
                    info: databaseInfo.data[dep][subDep][item]
                }
            });
        navigate(`/productdetails`);
    };
  return (
    <>
        <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
            <Row className="justify-content-center">
                SEARCH
            </Row>
            <Row className="justify-content-center" style={{fontSize:"0.6em"}}>
                {
                    searchValue !== '' ? <div style={{textAlign:'center'}}>{searchValue}</div> : <div style={{textAlign:'center'}}>please start typing...</div>
                }
            </Row>
        </Container>
        <Container fluid="lg">
            {
                searchValue !== '' ?
                    searchResult.sort((a,b)=>{
                        if(a.score > b.score) { return -1; }
                        if(a.score < b.score) { return 1; }
                        return 0;
                    }).map((item, index)=>{
                        const name = item.ref.split('(@#+1)')[2];
                        const sub = item.ref.split('(@#+1)')[1];
                        const dep = item.ref.split('(@#+1)')[0]
                        return(
                            <Row key={index}  style={{cursor:"pointer", borderBottom: '1px solid lightgrey', paddingLeft:"1em", paddingRight:"1em"}} onClick={() => handleItemClick(name, sub, dep)}>
                                <Col lg="9" className="mt-4 mb-4">
                                    <Row style={{fontSize:"1.2em"}}>{name}</Row>
                                    <Row >
                                        <Col>
                                            {
                                                databaseInfo?.data?.[dep]?.[sub]?.[name]?.quantity > 0 ?
                                                    <div style={{fontSize:"0.8em"}}>{databaseInfo?.data?.[dep]?.[sub]?.[name]?.quantity} IN STOCK</div>
                                                :
                                                    <div style={{fontSize:"0.8em"}}>OUT OF STOCK</div>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        {
                                            databaseInfo?.data?.[dep]?.[sub]?.[name]?.price ? 
                                                <Col className="mt-4 number" style={{fontSize:"0.9em"}}>$ {databaseInfo?.data?.[dep]?.[sub]?.[name]?.price} <span style={{fontSize:"0.8em"}}>monthly</span></Col>
                                            :
                                                <Col className="mt-4" style={{fontSize:"0.9em"}}>Please enquire - (905) 885-2885</Col>
                                        }
                                    </Row>
                                    {
                                        databaseInfo?.data?.[dep]?.[sub]?.[name]?.length || databaseInfo?.data?.[dep]?.[sub]?.[name]?.width || databaseInfo?.data?.[dep]?.[sub]?.[name]?.height ?
                                            <Row md="auto" className='mt-3'>
                                                <div style={{display:"flex", flexDirection:"column"}}>
                                                    <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Dimensions</div>
                                                    <div style={{fontSize:"0.8em", display:"flex", flexDirection:"row"}}>
                                                        {
                                                            databaseInfo?.data?.[dep]?.[sub]?.[name]?.length &&
                                                                <div>{databaseInfo?.data?.[dep]?.[sub]?.[name]?.length}"L</div>
                                                        }
                                                        {
                                                            databaseInfo?.data?.[dep]?.[sub]?.[name]?.length && databaseInfo?.data?.[dep]?.[sub]?.[name]?.width ?
                                                                <div>&nbsp;x&nbsp;{databaseInfo?.data?.[dep]?.[sub]?.[name]?.width}"W</div>
                                                            :
                                                                databaseInfo?.data?.[dep]?.[sub]?.[name]?.width &&
                                                                    <div>{databaseInfo?.data?.[dep]?.[sub]?.[name]?.width}"W</div>
                                                        }
                                                        {
                                                            (databaseInfo?.data?.[dep]?.[sub]?.[name]?.length || databaseInfo?.data?.[dep]?.[sub]?.[name]?.width) && databaseInfo?.data?.[dep]?.[sub]?.[name]?.height ?
                                                                <div>&nbsp;x&nbsp;{databaseInfo?.data?.[dep]?.[sub]?.[name]?.height}"H</div>
                                                            :
                                                                databaseInfo?.data?.[dep]?.[sub]?.[name]?.height &&
                                                                    <div>{databaseInfo?.data?.[dep]?.[sub]?.[name]?.height}"H</div>
                                                        }
                                                    </div>
                                                </div>
                                            </Row>
                                        :
                                            ''
                                    }
                                    {
                                        databaseInfo?.data?.[dep]?.[sub]?.[name]?.description &&
                                            <Row md="auto" className='mt-3'>
                                                <div style={{display:"flex", flexDirection:"column"}}>
                                                    <div style={{fontWeight:"bold", fontSize:"0.8em"}}>Description</div>
                                                    <div style={{fontSize:"0.8em"}}>{databaseInfo?.data?.[dep]?.[sub]?.[name]?.description}</div>
                                                </div>
                                            </Row>
                                    }
                                </Col>
                                <Col lg="3" className="mt-4">
                                    <Row style={{display:"flex", alignItems:"center", paddingTop:"0", paddingLeft:"1em", paddingRight:"1em", paddingBottom:"1em"}}>
                                        <Image src={databaseInfo?.data?.[dep]?.[sub]?.[name]?.image_urls[0]} alt={name} style={{width:"100%", height:"auto"}} />
                                    </Row>
                                </Col>
                            </Row>
                        )
                    })
                :
                    <div></div>
            }
        </Container>
    </>
  )
}
