import React, { useContext, useState, useEffect } from 'react';
import { auth } from '../firebase-config';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updateEmail, updatePassword } from 'firebase/auth';

import { db } from '../firebase-config';
import { doc, getDoc } from 'firebase/firestore';

const AuthContext = React.createContext();

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider( { children }) {
    const [currentUser, setCurrentUser] = useState();
    const [currentUserInfo, setCurrentUserInfo] = useState();
    const [loading, setLoading] = useState(true);

    function login(email, password){
        return signInWithEmailAndPassword(auth, email, password);
    }

    function signup(email, password){
        return createUserWithEmailAndPassword(auth, email, password);
    }

    function logout(){
        return signOut(auth);
    }

    function resetPassword(email){
        return sendPasswordResetEmail(auth, email);
    }

    function updateUserEmail(email){
        return updateEmail(currentUser, email);
    }

    function updateUserPassword(password){
        return updatePassword(currentUser, password);
    }

    function updateCurrentUserInfo(newUserInfo){
        return setCurrentUserInfo(newUserInfo);
    }

    // update email, update password with PromiseAll https://www.youtube.com/watch?v=PKwu15ldZ7k 52:30

    useEffect(()=>{
        const unsubscribe = auth.onAuthStateChanged(user =>{
            setCurrentUser(user);
            if(user){
                user.getIdTokenResult().then((idTokenResult)=>{
                    user.admin = idTokenResult.claims.admin;
                });
                const docRef = doc(db, 'users', user.uid);
                getDoc(docRef).then((docSnap)=>{
                    if(docSnap.exists()){
                        setCurrentUserInfo({
                            email: docSnap.data().email,
                            type: docSnap.data().type,
                            first_name: docSnap.data().first_name,
                            last_name: docSnap.data().last_name,
                            company: docSnap.data().company,
                            phonenumber: docSnap.data().phonenumber,
                            country: docSnap.data().country,
                            city: docSnap.data().city,
                            address: docSnap.data().address,
                            postalcode: docSnap.data().postalcode,
                            province: docSnap.data().province,
                            timestamp: docSnap.data().timestamp});
                    }else{
                        setCurrentUserInfo({});
                    }
                }).catch((error)=>{
                    console.log(error);
                    setCurrentUserInfo({});
                });
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        currentUserInfo,
        updateUserEmail,
        updateUserPassword,
        updateCurrentUserInfo,
        login,
        signup,
        logout,
        resetPassword
    }
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
