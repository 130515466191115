import React from 'react';
import { Container, Row } from 'react-bootstrap';

export default function PrivacyPolicy() {
  return (
    <>
        <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
            <Row className="justify-content-center">
                Privacy Policy
            </Row>
        </Container>
        <Container fluid="lg" className="mt-4">
            <Row style={{paddingLeft:"1em", paddingRight:"1em"}}>
                Limitless Staging Rentals protects personal information and will never sell, rent or share your information in any way and under any situation without your own authorization unless required to by law. We are firmly committed to our customers' privacy.
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                We use your personal information only for providing and improving the site. By using the site, you agree to the collection and use of information in accordance with this policy.
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                <Row className='mt-2' style={{fontSize:"1.3em"}}>
                    Cookies and IP Addresses
                </Row>
                <Row>
                    Your IP address is used to help identify you and your cart and gather general demographic information. We use cookies to keep track of your cart and for other purposes like personalizing the site. Cookies are a small piece of information stored by your internet browser to your computer's hard drive. Your browser is usually set up to initially accept cookies. If you set your browser to reject cookies, the site will not be able to recognize your cart, or any personalization. We do not and cannot use cookies to gather your confidential information that may be stored on your hard drive. Links to Third Party Company’s display their own privacy policy’s in regards to cookies and IP Addresses for your review.
                </Row>
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                <Row className='mt-2' style={{fontSize:"1.3em"}}>
                    Personal Information
                </Row>
                <Row>
                    Our site uses forms for you to request information and services. We collect your contact information, which may include but not limited to, your name, email addresses, postal address and phone numbers when provided by you. All collected information is used for the benefit of Limitless Staging Rentals to send information to customers about services we provide, and promotional marketing material about our company.
                </Row>
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                <Row className='mt-2' style={{fontSize:"1.3em"}}>
                    Business Transactions
                </Row>
                <Row>
                    If we are involved in a merger, acquisition or asset sale, your personal information may be transferred to our merchant sevices to complete the transaction. At that time your personal information becomes subject to a different privacy policy.
                </Row>
            </Row>
            <Row className="mt-3" style={{paddingLeft:"1em", paddingRight:"1em"}}>
                If you have any questions about this privacy policy, please contact us.
            </Row>
        </Container>
    </>
  )
}
