import React from 'react';

import '../css/quantitypicker.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

export default function QuantityPicker({ quantity, setQuantity, instock, incart, maxReached, setMaxReached, setCartUpdated }) {
    
    const decrementQuantity = ()=>{
        setMaxReached('');
        let qty = quantity;
        qty--;
        if(qty > 0){
            setQuantity(qty);
            setCartUpdated(false);
        }
    };
    
    const incrementQuantity = ()=>{
        setMaxReached('');
        let qty = quantity;
        qty++;
        if((qty+incart) <= instock){
            setQuantity(qty);
            setCartUpdated(false);
        }else{
            if(incart > 0){
                setMaxReached(`( ${instock} IN STOCK )`);
            }else{
                setMaxReached(`( ${instock} IN STOCK )`);
            }
            
        }
    };


    return (
        <div>
            <div style={{display:'flex'}}>
                <div className="decrement" style={{width:"60px", height:"60px"}} onClick={()=>decrementQuantity()}>
                    <FontAwesomeIcon style={{fontSize: '1em', margin:'5px', color:"grey"}} icon={faMinus} />
                </div>
                <div className="quantity" style={{fontSize:"1em",width:"60px", height:"60px"}}>{quantity}</div>
                <div className="increment" style={{width:"60px", height:"60px"}} onClick={()=>incrementQuantity()}>
                    <FontAwesomeIcon style={{fontSize: '1em', margin:'5px', color:"grey"}} icon={faPlus} />
                </div>
            </div>
            <div style={{paddingLeft:"5px", fontSize:"0.8em"}}>{maxReached}</div>
        </div>
    )
}
