import React, { useRef, useState } from 'react';
import { Form, Button, Card, Alert, Container} from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { Link } from 'react-router-dom';

export default function EmployeeForgotPassword() {
    const emailRef = useRef();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const { resetPassword } = useAuth();


    async function handleSubmit(event){
        event.preventDefault();

        if(emailRef.current.value === null || emailRef.current.value === ''){
            return setError('Email must be filled out');
        }

        try{
            setMessage('');
            setError('');
            setLoading(true);
            await resetPassword(emailRef.current.value); 
            setMessage('Check your inbox for further instructions');
        } catch(error){
            setError('Failed to reset password: ' + error.message);
        }

        setLoading(false);
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "60vh"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">Password Reset</h2>
                            {message && <Alert variant="success">{message}</Alert>}
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handleSubmit}>Reset Password</Button>
                            </Form>
                            <div className="w-100 text-center mt-3">
                                <Link to="/employee-login">Login</Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        Need an account? <Link to="/employee-signup">Sign up</Link>
                    </div>
                </div>
            </Container>
        </>
    )
}
