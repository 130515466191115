import React from 'react';
import { useNavigate } from 'react-router-dom';

import '../css/addbutton.css';

export default function NavigationButton({ title, address}) {

    const navigate = useNavigate();

    const goToAddressLink = (address)=>{
        navigate(`/${address}`);
    };

    return (
        <div
            className="button"
            style={{
                width:"80%",
                fontSize:"0.8em",
                height:"60px",
                lineHeight:"40px",
            }}
            onClick={()=>goToAddressLink(address)}>
            {title}
        </div>
    )
}
