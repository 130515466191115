import React, { useRef, useState, useEffect } from 'react';
import { Form, Button, Card, Alert, Container} from 'react-bootstrap';
import { useAuth } from '../Context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

import logo from '../images/limitless.png';

import { db } from '../firebase-config';
import { doc, setDoc , serverTimestamp } from 'firebase/firestore';

export default function EmployeeSignup() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { signup } = useAuth();
    const navigate = useNavigate();

    useEffect(()=>{
        return () =>{ setLoading(false) }
    }, []);

    async function handleSubmit(event){
        event.preventDefault();

        if(passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError('Passwords do not match');
        }
        if(emailRef.current.value === null || emailRef.current.value === ''){
            return setError('Email must be filled out');
        }
        if(passwordRef.current.value === null || passwordRef.current.value === ''){
            return setError('Password must be filled out');
        }
        if(passwordConfirmRef.current.value === null || passwordConfirmRef.current.value === ''){
            return setError('Password Confirmation must be filled out');
        }

        try{
            setError('');
            setLoading(true);
            let credentials = await signup(emailRef.current.value, passwordRef.current.value);
            const docRef = doc(db, 'users', credentials.user.uid);
            await setDoc(docRef, {
                type: "employee",
                email: emailRef.current.value,
                timestamp: serverTimestamp(),
                first_name: "",
                last_name: "",
                status: "revoked"
            });
            navigate("/employee-portal");
        } catch(error){
            setError('Failed to create an account: ' + error.message);
        }

        setLoading(false);
    }

    return (
        <>
            <Container className="d-flex align-items-center justify-content-center mt-5" style={{minHeight: "60vh"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card>
                        <Card.Img variant="top" src={logo} style={{height:"100%"}}/>
                        <Card.Body>
                            <h2 className="text-center mb-4">Employee Sign up</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required />
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} required />
                                </Form.Group>
                                <Form.Group id="password-confirm">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control type="password" ref={passwordConfirmRef} required />
                                </Form.Group>
                                <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handleSubmit}>Sign up</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        Already have an account? <Link to="/employee-login">Log_in</Link>
                    </div>
                </div>
            </Container>
        </>
    )
}
