import React, { useEffect, useState } from 'react';
import { Card, Alert, Container, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ProgressBarHomePage from './ProgressBarHomePage';

import { db } from '../firebase-config';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

export default function UploadHomePageForm( { homePageInfo, setHomePageInfo }) {
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);

    const [oldHomePageImages, setOldHomePageImages] = useState([]);

    const [buttonColor, setButtonColor] = useState('');
    const [buttonLink, setButtonLink] = useState('');
    const [buttonText, setButtonText] = useState('');
    const [mainTitle, setMainTitle] = useState('');
    const [deliveryAreaTitle, setDeliveryAreaTitle] = useState('');
    const [pictureBrightness, setPictureBrightnes] = useState('');
    const [pictureUrl, setPictureUrl] = useState('');
    const [slogan, setSlogan] = useState('');
    const [smallTitle, setSmallTitle] = useState('');
    const [textColor, setTextColor] = useState('');

    useEffect(()=>{
        const oldHomePageImgsRef = doc(db, 'website_data', 'old_home_page_images');
        getDoc(oldHomePageImgsRef).then((docSnap)=>{
          if(docSnap.exists()){
            console.log(docSnap.data());
            setOldHomePageImages(docSnap.data().images);
          }else{
            console.log('No such document!');
          }
        }).catch((error)=>{
          console.log(`Error geting old images data: ${error}`);
        });
    }, []);

    useEffect(()=>{
        setButtonColor(homePageInfo?.data?.button_color);
        setButtonLink(homePageInfo?.data?.button_link);
        setButtonText(homePageInfo?.data?.button_text);
        setMainTitle(homePageInfo?.data?.main_title);
        setDeliveryAreaTitle(homePageInfo?.data?.delivery_area);
        setPictureBrightnes(homePageInfo?.data?.picture_brightness);
        setSlogan(homePageInfo?.data?.slogan);
        setSmallTitle(homePageInfo?.data?.small_title);
        setTextColor(homePageInfo?.data?.text_color);
        setPictureUrl(homePageInfo?.data?.picture);
    }, [homePageInfo]);

    const types = ['image/png', 'image/jpeg'];

    const uploadHomePagePicHandler = (event)=>{
        event.preventDefault();
        console.log(event.target.value);
        let selected = event.target.files[0];
        console.log(selected);
        if(selected && types.includes(selected.type)){
            setError(null);
            setFile(selected);
            event.target.value = '';
        }else{
            setFile(null);
            event.target.value = '';
            setError('Please select and image file of (png or jpeg)');
        }
    };
    const updateHomePageInformation = ()=>{
        let document = {
            button_color: buttonColor,
            button_link: buttonLink,
            button_text: buttonText,
            main_title: mainTitle,
            delivery_area: deliveryAreaTitle,
            picture_brightness: pictureBrightness,
            slogan: slogan,
            small_title: smallTitle,
            text_color: textColor,
            picture: pictureUrl
        };
        setHomePageInfo({data: document});
        updateDoc(doc(db, "website_data", "home_page"), document).then(()=>{
            toast.success('Home Page Information Updated', {
                style: {backgroundColor:'var(--success)'}
            });
        }).catch((error)=>{
            console.log(error);
            toast.error('Error Uploading Home Page Information', {
                style: {backgroundColor:'var(--error)'}
            })
        });
    };
    return (
        <>
            <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "40vh"}}>
                <div className="w-100" style={{maxWidth: "600px"}}>
                    <Card style={{margin: "40px 0 40px 0"}}>
                        <Card.Body>
                            <h2 className="text-center mb-4">Home Page Image</h2>
                            <Form className="plus-form" onSubmit={uploadHomePagePicHandler}>
                                <label className="plus-label">
                                    <input className="plus-label-input" type="file" onChange={uploadHomePagePicHandler} />
                                    <span>+</span>
                                </label>
                                <div>
                                    Recommended image size 1500px by 680px
                                </div>
                                <div>
                                    2 : 1 ratio
                                </div>
                                <div>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    {file && <Alert variant="success">{file.name}</Alert>}
                                    {file && <ProgressBarHomePage
                                        file={file}
                                        setFile={setFile}
                                    />}
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card style={{margin: "40px 0 40px 0"}}>
                        <Card.Body>
                            <h2 className="text-center mb-4">Home Page Image Information</h2>
                            <Form.Label>Image Brightness % (Blank = 100%)</Form.Label>
                            <Form.Control type="text" value={pictureBrightness} name="imageBrightness" onChange={(event)=>{
                                setError('');
                                setPictureBrightnes(event.target.value);
                            }} />
                            <Form.Label className="mt-4">Small Title (Blank = no small title) (auto capitalized)</Form.Label>
                            <Form.Control type="text" value={smallTitle} name="smallTitle" onChange={(event)=>{
                                setError('');
                                setSmallTitle(event.target.value);
                            }} />
                            <Form.Label className="mt-4">Main Title (Blank = no main title)</Form.Label>
                            <Form.Control type="text" value={mainTitle} name="mainTitle" onChange={(event)=>{
                                setError('');
                                setMainTitle(event.target.value);
                            }} />
                            <Form.Label className="mt-4">Delivery Area Title (Blank = no delivery area title)</Form.Label>
                            <Form.Control type="text" value={deliveryAreaTitle} name="mainTitle" onChange={(event)=>{
                                setError('');
                                setDeliveryAreaTitle(event.target.value);
                            }} />
                            <Form.Label className="mt-4">Slogan (Blank = no slogan)</Form.Label>
                            <Form.Control type="text" value={slogan} name="slogan" onChange={(event)=>{
                                setError('');
                                setSlogan(event.target.value);
                            }} />
                            <Form.Label className="mt-4">Text Color</Form.Label>
                            <Form.Select value={textColor} name="textColor" onChange={(event)=>{
                                setError('');
                                setTextColor(event.target.value);
                            }} >
                                <option value="white">white</option>
                                <option value="black">black</option>
                            </Form.Select>
                            <Form.Label className="mt-4">Button Text (Blank = no button)</Form.Label>
                            <Form.Control type="text" value={buttonText} name="buttonText" onChange={(event)=>{
                                setError('');
                                setButtonText(event.target.value);
                            }} />
                            <Form.Label className="mt-4">Button Link (Blank links to products page)</Form.Label>
                            <Form.Control type="text" value={buttonLink} name="buttonLink" onChange={(event)=>{
                                setError('');
                                setButtonLink(event.target.value);
                            }} />
                            <Form.Label  className="mt-4">Button Color</Form.Label>
                            <Form.Select value={buttonColor} name="buttonColor" onChange={(event)=>{
                                setError('');
                                setButtonColor(event.target.value);
                            }} >
                                <option value="light">white</option>
                                <option value="dark">black</option>
                            </Form.Select>
                            <Form.Label  className="mt-4">Old Home Page Images</Form.Label>
                            <Form.Select value={pictureUrl} name="pictureUrl" onChange={(event)=>{
                                setError('');
                                setPictureUrl(event.target.value);
                            }}>
                                <option value=""></option>
                                {
                                    oldHomePageImages.map((imgUrl)=>{
                                        return(
                                            <option key={imgUrl} value={imgUrl}>{imgUrl.split('images')[1].split('?')[0]}</option>
                                        )
                                    })
                                }
                            </ Form.Select>
                            <Button variant="primary" className="mt-5" onClick={()=>{updateHomePageInformation()}}>Update Home Page Information</Button>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    )
}
