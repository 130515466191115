import React, { useRef, useState, useEffect } from 'react'
import { Container, Row, Col, Button, Card, Form, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../Context/AuthContext';

export default function CheckoutGuestSignin( { screenWidth }) {
    const navigate = useNavigate();
    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { login } = useAuth();

    useEffect(()=>{
        window.scrollTo({top:0, behavior:'smooth'});
        return () =>{ setLoading(false) }
    }, []);

    const handleGuestButtonClick = ()=>{
        navigate("/checkout");
    };

    async function handleSubmit(event){
        event.preventDefault();

        if(emailRef.current.value === null || emailRef.current.value === ''){
            return setError('Email must be filled out');
        }
        if(passwordRef.current.value === null || passwordRef.current.value === ''){
            return setError('Password must be filled out');
        }

        try{
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value); 
            navigate("/checkout");
        } catch(error){
            setError('Failed to log in: ' + error.message);
        }

        setLoading(false);
    }
    return (
        <>
            <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        CHECKOUT
                    </Row>
            </Container>
            <Container fluid="lg">
                <Row style={{marginLeft: "5px", marginRight: "5px"}}>
                    <Col lg="6" className={screenWidth < 992 ? "mt-4 ps-3" : "mt-4 ps-5"}>
                        <Row style={{fontSize:"1.25em"}}>Guest Checkout</Row>
                        <Row style={{fontSize:"0.8em"}}>No account? No problem. Create an account later for faster checkouts.</Row>
                        <Row><Button className="w-50 btn-dark" style={{marginTop: "15px"}} onClick={()=>{handleGuestButtonClick()}}>Continue as Guest</Button></Row>
                    </Col>
                    <Col className={screenWidth < 992 ? "mt-4 ps-3" : "mt-4 ps-5"}>
                        {
                            screenWidth < 992 && <hr></hr>
                        }
                        <Row style={{fontSize:"1.25em"}}>Login</Row>
                        <Row style={{fontSize:"0.8em"}}>Checkout faster by signing into your account.</Row>
                        <div className="w-100" style={{maxWidth: "600px"}}>
                            <Card>
                                <Card.Body>
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group id="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" ref={emailRef} required />
                                        </Form.Group>
                                        <Form.Group id="password">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" ref={passwordRef} required />
                                        </Form.Group>
                                        <Button disabled={loading} className="w-100 btn-dark" style={{marginTop: "15px"}} type="submit" onClick={handleSubmit}>Log in</Button>
                                    </Form>
                                    <div className="w-100 text-center mt-3">
                                        <Link to="/member-forgot-password">Forgot Password?</Link>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div className="w-100 text-center mt-2">
                                Need an account? <Link to="/member-signup">Sign up</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5" style={{marginLeft: "5px", marginRight: "5px"}}>
                    <Row style={{fontSize:"0.7em", display: "inline"}}><Link to={'/cart'} className="p-0">Back to cart</Link></Row>
                    <hr></hr>
                </Row>
                <Row style={{margin:"20px 10px 20px 10px"}}>
                    <Col lg="12">
                        <Row>Security & Privacy</Row>
                        <Row style={{fontSize:"0.7em", display: "inline"}}>Every transaction on Limitless Staging Rentals is secure. Any personal information you give us will be handled according to our <Link to={'/privacy_policy'} className="p-0">Privacy Policy</Link>.</Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
