import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Form, Image, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { db } from '../firebase-config';
import { collection, query, getDocs, where, orderBy, limit } from "firebase/firestore";
import { formatChosenDeliveryDate, convertToBootstrapDateFormat, createTimeStamp2 } from '../HelperFunctions/helper_functions';

export default function EmployeeComplete() {

    const [listOfOrders, setListOfOrders] = useState([]);
    const [currentHeading, setCurrentHeading] = useState(''); // will update with new search

    const [chosenOrder, setChosenOrder] = useState({});
    const [modalShow, setModalShow] = useState(false);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    
    useEffect(()=>{
        const q = query(collection(db, "finalized"), orderBy("complete_timestamp", "desc"), limit(10));
        getDocs(q).then((querySnapshot)=>{
            let tempListOfOrders = [];
            querySnapshot.forEach((order)=>{
                tempListOfOrders = [...tempListOfOrders, {data: order.data(), id: order.id}];
            });
            setListOfOrders(tempListOfOrders);
            setCurrentHeading('Last 10 Completed Orders');
        }).catch((error)=>{
            toast.error(`Failed to load data: ${error.message}`);
        });
    }, []);

    const dateRangeSearch = ()=>{
        if(fromDate === '' || toDate === ''){
            toast.warning('Both date inputs need to be filled out');
            setListOfOrders([]);
            setCurrentHeading(`From: ${fromDate} - To: ${toDate}`);
            return null;
        }
        if(createTimeStamp2(fromDate) > createTimeStamp2(toDate, 'end')){
            toast.warning('From date must be equal to or before the To date');
            setListOfOrders([]);
            setCurrentHeading(`From: ${fromDate} - To: ${toDate}`);
            return null;
        }
        const q = query(collection(db, "finalized"), orderBy("complete_timestamp", "desc"), where("complete_timestamp", ">=", createTimeStamp2(fromDate)), where("complete_timestamp", "<", createTimeStamp2(toDate, 'end')));
        getDocs(q).then((querySnapshot)=>{
            let tempListOfOrders = [];
            querySnapshot.forEach((order)=>{
                tempListOfOrders = [...tempListOfOrders, {data: order.data(), id: order.id}];
            });
            setListOfOrders(tempListOfOrders);
            setCurrentHeading(`From: ${fromDate} - To: ${toDate}`);
        }).catch((error)=>{
            toast.error(`Failed to load data: ${error.message}`);
        });
    };

    const OrderModal = (props)=>{
        return(
            <Modal
                {...props}
                fullscreen
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Order Information - {props?.order?.data?.[props?.order?.id]?.private?.info?.['delivery address']}, {props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery city"]} (COMPLETED)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid style={{marginTop:"20px"}}>
                        <Row>
                            <Col lg="6"  style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>GENERAL INFORMATION</Col></Row>
                                <Row style={{marginLeft: "20px", marginTop:"10px"}}>
                                    <Col>
                                        Status: COMPLETED
                                    </Col>
                                </Row>
                                <Row style={{marginLeft: "20px", marginTop:"10px", width:"400px"}}>
                                    <Col>Delivery Location:</Col>
                                    <Col><a href={`https://www.google.com/maps/place/${props?.order?.data?.[props?.order?.id]?.private?.info?.['delivery address']}+${props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery city"]}+${props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery province"]}+${props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery country"]}+${props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery postalcode"]}`} target="_blank" rel="noreferrer">View Map</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>DELIVERY DETAILS</Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Street Address</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.['delivery address']} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Town / City</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery city"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Province</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery province"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Country</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery country"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Postal Code</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery postalcode"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Delivery Date</Col></Row>

                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px", width:"200px"}}><Col><Form.Control type="date" value={convertToBootstrapDateFormat(props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery date"])} disabled={true} /></Col></Row>
                                
                                <Row style={{marginLeft:"10px"}}><Col>Delivery Window</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery window"]} required disabled /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Order Notes</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control as="textarea" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery_notes"]} rows={4} disabled={true} /></Col></Row>
                            </Col>

                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}>
                                    <Col>INVENTORY DETAILS</Col>
                                </Row>
                                {
                                    props?.order?.data?.[props?.order?.id]?.private?.info?.cart?.map((item, index)=>{
                                        return(
                                            <Row key={index} style={{marginLeft:"10px", marginBottom: "5px", marginTop:"30px"}}>
                                                <Col xs="9">
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col>Item: {index+1}</Col></Row>
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col>Name: <span style={{fontSize:"1.3em"}}>{item?.productInfo?.itemInfo?.name}</span> (${item?.productInfo?.itemInfo?.info?.price})</Col></Row>
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col>Quantity: <span style={{fontSize:"1.5em"}}>{item?.Quantity}</span></Col></Row>
                                                </Col>
                                                <Col xs="3">
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col><Image src={item?.productInfo?.itemInfo?.info.image_urls[0]} alt={item?.productInfo?.itemInfo?.name} style={{width:"100%", height:"auto"}} /></Col></Row>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>BILLING DETAILS</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Customer Id: {props?.order?.data?.[props?.order?.id].id}</Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Phone Number</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing phone number"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Email Address</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["email"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>First Name</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing first name"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Last Name</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing last name"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Company</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing company"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Street Address</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing address"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Town / City</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing city"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Province</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing province"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Country</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing country"]} disabled={true} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Postal Code</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={props?.order?.data?.[props?.order?.id]?.private?.info?.["billing postalcode"]} disabled={true} /></Col></Row>
                            </Col>
                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>TRANSACTION DETAILS</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Confirmation Number: {props?.order?.id}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Transaction Id: {props?.order?.data?.[props?.order?.id]?.private?.info?.card_response?.transId}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Network Transaction Id: {props?.order?.data?.[props?.order?.id]?.private?.info?.card_response?.networkTransId}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Authorization Code: {props?.order?.data?.[props?.order?.id]?.private?.info?.card_response?.authCode}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Account Type: {props?.order?.data?.[props?.order?.id]?.private?.info?.card_response?.accountType}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Account Number: {props?.order?.data?.[props?.order?.id]?.private?.info?.card_response?.accountNumber}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Card Response: {props?.order?.data?.[props?.order?.id]?.private?.info?.card_response?.message}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Sub Total: ${props?.order?.data?.[props?.order?.id]?.private?.info?.subtotal}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Delivery: ${props?.order?.data?.[props?.order?.id]?.private?.info?.["delivery charge"]}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Tax: ${props?.order?.data?.[props?.order?.id]?.private?.info?.tax}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Total: ${props?.order?.data?.[props?.order?.id]?.private?.info?.total}</Col></Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        Completed Orders
                    </Row>
                </Container>
            </Container>
            <Container fluid className="mt-4" style={{width:"100%", paddingLeft:"70px", marginRight:"70px"}}>
                <Row style={{textAlign:"center", width:"60%", margin:"auto"}}>
                    <Col style={{display:"flex"}}>From:&nbsp;<Form.Control type="date" value={fromDate} onChange={(e)=> setFromDate(e.target.value)} /></Col>
                    <Col style={{display:"flex"}}>To:&nbsp;<Form.Control type="date" value={toDate} onChange={(e)=> setToDate(e.target.value)} /></Col>
                    <Col>
                        <Button
                            onClick={()=>{
                                dateRangeSearch();
                            }}
                        >Search
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-3" style={{textAlign:"center"}}>
                    
                </Row>
            </Container>
            <Container fluid className="mt-4" style={{width:"100%", paddingLeft:"70px", marginRight:"70px"}}>
                <Row>
                    <Col lg="12">
                        <Card style={{border:"1px solid black"}}>
                            <Card.Header style={{textAlign:"center"}}>{currentHeading}</Card.Header>
                            <Card.Body style={{textAlign:"left"}}>
                            {
                                listOfOrders.map((workorder, index)=>{
                                    return(
                                        <div
                                            key={index}
                                            style={{cursor:"pointer", borderBottom:"1px solid lightgrey", paddingTop:20}}
                                            onClick={()=>{
                                                setChosenOrder(workorder);
                                                setModalShow(true);
                                            }}
                                        >
                                            {formatChosenDeliveryDate(workorder?.data?.complete_timestamp)} - {workorder.data?.[workorder.id]?.private?.info?.["delivery address"]}, {workorder.data?.[workorder.id]?.private?.info?.["delivery city"]}
                                        </div>
                                    )
                                })
                            }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <OrderModal
                show={modalShow}
                onHide={()=> {
                    setModalShow(false);
                }}
                order={chosenOrder}
            />
        </>
    )
}