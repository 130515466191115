import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../Context/AuthContext';
import { Card, Button, Container, Row, Col, Modal, Form, Image } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { db } from '../firebase-config';
import { collection, query, where, onSnapshot, doc, getDoc, updateDoc, increment, serverTimestamp, runTransaction } from 'firebase/firestore';

import { jsPDF } from "jspdf";

import {
    createTimeStamp2,
    createTimeStamp3,
    formatChosenDeliveryDate,
    convertToBootstrapDateFormat,
    convertToBootstrapDateFormatFromTimeStamp,
    convertMyDatePickerDateFormat,
    getHourFromTwentyFourHour,
    getMinutesFromTwentyFourHour,
    getTwelveHourTime
} from '../HelperFunctions/helper_functions';

export default function EmployeeHome() {
    const { currentUser } = useAuth();
    const [newOrders, setNewOrders] = useState([]);
    const [confirmedOrders, setConfirmedOrders] = useState([]);
    const [ordersOut, setOrdersOut] = useState([]);
    const [ordersScheduledForPickup, setOrdersScheduledForPickup] = useState([]);
    const [ordersReturned, setOrdersReturned] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [chosenOrder, setChosenOrder] = useState({});

    const [updateOrderButtonStatus, setUpdateOrderButtonStatus] = useState('edit');
    const [saveFlag, setSaveFlag] = useState(false);

    const [contractLength, setContractLength] = useState(0);

    const base64Imgs = useRef({});

    /**
     * status
     * 1 = order not confirmed (new order)
     * 2 = order confirmed (new order and verified, ready to be picked from inventory)
     * 3 = order out (order delivered to customer)
     * 4 = order scheduled for pickup *******************************************************************************************
     * 5 = order back not checked (order returned from customer)
     * 6 = order checked (order put away back in stock or removed from stock = order closed)
     */

    useEffect(()=>{
        const q = query(collection(db, "bookings"), where("status", ">", 0), where("status", "<", 6));
        const unsubscribe = onSnapshot(q, (snapshot)=>{
            if(snapshot.size > 0){
                let tempNewOrders = [];
                let tempConfirmedOrders = [];
                let tempOrdersOut = [];
                let tempOrdersScheduledForPickup = [];
                let tempOrdersReturned = [];
                snapshot.forEach((snap) =>{
                    const docRef = doc(db, `bookings/${snap.id}/private`, "info");
                    getDoc(docRef).then((privateInfo)=>{
                        return({public: snap.data(), private: privateInfo.data(), id: snap.id});
                    }).then((orderInfo)=>{
                        switch(orderInfo.public.status){
                            case 1:
                                tempNewOrders = [...tempNewOrders, orderInfo];
                                setNewOrders(tempNewOrders);
                                break;
                            case 2:
                                tempConfirmedOrders = [...tempConfirmedOrders, orderInfo];
                                setConfirmedOrders(tempConfirmedOrders);
                                break;
                            case 3:
                                tempOrdersOut = [...tempOrdersOut, orderInfo];
                                setOrdersOut(tempOrdersOut);
                                break;
                            case 4:
                                tempOrdersScheduledForPickup = [...tempOrdersScheduledForPickup, orderInfo];
                                setOrdersScheduledForPickup(tempOrdersScheduledForPickup);
                                break;
                            case 5:
                                tempOrdersReturned = [...tempOrdersReturned, orderInfo];
                                setOrdersReturned(tempOrdersReturned);
                                break;
                            default:
                                break;

                        }
                    }).catch((error)=>{
                        toast.error(`Error loading new private orders: ${error}`, {
                            style: {backgroundColor:'var(--error)'}
                        });
                    });
                });
                if(tempNewOrders.length === 0){ setNewOrders([]) }
                if(tempConfirmedOrders.length === 0){ setConfirmedOrders([]) }
                if(tempOrdersOut.length === 0){ setOrdersOut([]) }
                if(tempOrdersScheduledForPickup.length === 0) { setOrdersScheduledForPickup([]) }
                if(tempOrdersReturned.length === 0){ setOrdersReturned([]) }
            }else{
                setNewOrders([]);
                setConfirmedOrders([]);
                setOrdersOut([]);
                setOrdersScheduledForPickup([]);
                setOrdersReturned([]);
            }
        }, (error)=> {
            toast.error(`Error loading new orders: ${error}`, {
                style: {backgroundColor:'var(--error)'}
            });
        });

        return ()=>{
            unsubscribe();
        }
        
    }, []);

    useEffect(()=>{
        const ref = doc(db, "website_data", "general");
        getDoc(ref).then((doc)=>{
            if(doc.exists()){
                const data = doc.data();
                setContractLength(data['contract length']);
            }else{
                toast.warning('General Data does not exist', {
                    style: {backgroundColor:'var(--warning)'}
                });
            }
        }).catch((error)=>{
            toast.error('Error getting General Data: ' + error , {
                style: {backgroundColor:'var(--error)'}
            });
        });
    }, [contractLength]);

    // const runToast = ()=>{
    //     toast.success('Success', {
    //         style: {backgroundColor:'var(--success)'}
    //     });
    //     toast.warning('Warning', {
    //         style: {backgroundColor:'var(--warning)'}
    //     });
    //     toast.error('Error', {
    //         style: {backgroundColor:'var(--error)'}
    //     });
    // };

    function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const createPickList = (
        pickList,
        address,
        city,
        customerFirstName,
        customerLastName,
        customerPhoneNumber,
        customerEmailAddress,
        customerCompany,
        deliveryAddress,
        deliveryCity,
        deliveryProvince,
        deliveryCountry,
        deliveryPostalCode,
        deliveryDate,
        deliveryWindow,
        deliveryNotes,
        orderConfirmationNumber,
        contractEndDate,
        creationType,
        titleType,
    )=>{
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const hh = today.getHours();
        const min = today.getMinutes();

        const todayString = yyyy + "_" + mm + "_" + dd + "_" + hh + min;

        const doc = new jsPDF("p", "mm", "letter");
        let letterPage = {
            // letter  = 215.9mm x 279.4mm portrait
            // legal = 215.9mm x 355.6mm portait
            paperWidth: 215.9,
            paperHeight: 279.4,
            margin: 10,
            tab: 4,
            indent: 8,
            nextY: 10,
            pageNumber: 1,
            incrementPageNumber: (number) =>{
                letterPage.pageNumber += number;
            },
            enter: () => {
                letterPage.nextY += doc.getFontSize() / 2;
            },
            deleteEnter: () =>{
                letterPage.nextY -= doc.getFontSize() /2;
            },
            centerText: () => {
                return letterPage.paperWidth / 2;
            },
            checkAddPage: () => {
                letterPage.nextY = letterPage.nextY + doc.getFontSize() / 2;
                if (letterPage.nextY >= letterPage.paperHeight - 20) {
                    doc.addPage("letter", "p");
                    letterPage.incrementPageNumber(1);
                    letterPage.nextY = letterPage.margin;
                    doc.text(
                        new Date().toDateString(),
                        letterPage.paperWidth - letterPage.margin,
                        letterPage.nextY,
                        { align: "right" }
                    );
                    letterPage.nextY = letterPage.nextY + doc.getFontSize() / 2;
                }
            },
            checkAddPagePic: (heightOfPic) => {
                letterPage.nextY = letterPage.nextY + heightOfPic + doc.getFontSize() / 2;
                if (letterPage.nextY >= letterPage.paperHeight - 20) {
                    doc.addPage("letter", "p");
                    letterPage.incrementPageNumber(1);
                    letterPage.nextY = letterPage.margin;
                    doc.text(
                        new Date().toDateString(),
                        letterPage.paperWidth - letterPage.margin,
                        letterPage.nextY,
                        { align: "right" }
                    );
                    letterPage.nextY = letterPage.nextY + doc.getFontSize() / 2;
                }
            },
            preCheckAddPagePic: (heightOfPic)=>{
                if ((letterPage.nextY + heightOfPic + doc.getFontSize()) >= letterPage.paperHeight - letterPage.margin) {
                    doc.addPage("letter", "p");
                    letterPage.incrementPageNumber(1);
                    letterPage.nextY = letterPage.margin;
                    doc.text(
                        new Date().toDateString(),
                        letterPage.paperWidth - letterPage.margin,
                        letterPage.nextY,
                        { align: "right" }
                    );
                    letterPage.nextY = letterPage.nextY + doc.getFontSize() / 2;
                }
            },
        };
        doc.setFontSize(16);
        doc.text(`Limitless Staging Rentals ${titleType}`, letterPage.centerText() - (doc.getTextWidth(`Limitless Staging Rentals ${titleType}`)/2), letterPage.nextY);
        doc.setFontSize(12);
        doc.text(
            new Date().toDateString(),
            letterPage.paperWidth - letterPage.margin,
            letterPage.nextY,
            { align: "right" },
        );
        letterPage.checkAddPage();
        letterPage.checkAddPage();
        //letterPage.checkAddPage();

        doc.text("DELIVERY DETAILS:", letterPage.margin, letterPage.nextY);
        letterPage.checkAddPage();

        doc.text(
            `Address: ${deliveryAddress}, ${deliveryCity}, ${deliveryProvince}, ${deliveryCountry} ${deliveryPostalCode}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );

        doc.setTextColor(0,0,255);
        let txtWidth = doc.getTextWidth('View Map');
        doc.textWithLink(
            'View Map',
            letterPage.paperWidth - letterPage.margin - txtWidth,
            letterPage.nextY,
            {url: `https://www.google.com/maps/place/${deliveryAddress}+${deliveryCity}+${deliveryProvince}+${deliveryCountry}+${deliveryPostalCode}`},
        );
        doc.setTextColor(0,0,0);
        letterPage.checkAddPage();
        doc.text(
            `Date: ${new Date(deliveryDate).toDateString()} (${deliveryDate})`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        doc.text(
            `Time: ${deliveryWindow}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        doc.text(
            `Notes: ${deliveryNotes}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        letterPage.checkAddPage();

        doc.text("CUSTOMER DETAILS:", letterPage.margin, letterPage.nextY);
        letterPage.checkAddPage();
        doc.text(
            `Name: ${customerFirstName} ${customerLastName}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        doc.text(
            `Phone: ${customerPhoneNumber}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        doc.text(
            `Email: ${customerEmailAddress}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        doc.text(
            `Company: ${customerCompany}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        doc.text(
            `Confirmation Number: ${orderConfirmationNumber}`,
            letterPage.margin + letterPage.tab,
            letterPage.nextY
        );
        letterPage.checkAddPage();
        letterPage.checkAddPage();

        doc.text(
            'PRODUCT',
            letterPage.margin+letterPage.tab,
            letterPage.nextY
        )
        doc.text(
            'QUANTITY',
            letterPage.paperWidth - letterPage.margin,
            letterPage.nextY,
            { align: "right"}
        )
        letterPage.checkAddPage();

        pickList.forEach((item)=>{
            let imgWidth = 0;
            let imgHeight = 30;
            if(base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`] !== ""){
                const imgData = doc.getImageProperties(base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`]);
                const PXtoMM_FACTOR = 0.2645833333;
                const MAXWIDTH = 195.9*0.15; // 195.9 is width of page minus margins * percent of that width will be the width of the image in mm
                // 1px = 0.2645833333mm
                // page width 215.9 - 10 margin on both sides (215.9-20) = 195.9
                imgWidth = imgData.width*PXtoMM_FACTOR;
                imgHeight = imgData.height*PXtoMM_FACTOR;
                const scaleFactor = MAXWIDTH/imgWidth;
                if(imgWidth > MAXWIDTH){
                    imgWidth = imgWidth*scaleFactor;
                    imgHeight = imgHeight*scaleFactor;
                }
                if(imgWidth < MAXWIDTH){
                    imgWidth = imgWidth/scaleFactor;
                    imgHeight = imgHeight/scaleFactor;
                }

                letterPage.preCheckAddPagePic(imgHeight);
                doc.addImage(
                    base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`],
                    imgData.fileType,
                    letterPage.margin,
                    letterPage.nextY,
                    imgWidth,
                    imgHeight,
                    `${item?.productInfo?.itemInfo?.name}`,
                    'NONE',
                    0
                );
            }
            letterPage.checkAddPage();
            letterPage.checkAddPage();
            doc.text(
                `${item?.productInfo?.itemInfo?.name}`,
                letterPage.margin+imgWidth+letterPage.tab,
                letterPage.nextY
            );
            doc.text(
                `${item?.Quantity}`,
                letterPage.paperWidth - letterPage.margin,
                letterPage.nextY,
                {align: "right"}
            );
            letterPage.deleteEnter();
            letterPage.deleteEnter();
            letterPage.checkAddPagePic(imgHeight);
        });

        // letterPage.checkAddPage();

        doc.text(
            '**** End Report ***',
            letterPage.centerText() - (doc.getTextWidth('**** End Report ***')/2),
            letterPage.nextY
        );
        // set page numbers        
        for(let x=1; x<=doc.getNumberOfPages(); ++x){
            doc.setPage(x);
            doc.text(
                `Pg. ${x} of ${doc.getNumberOfPages()}`,
                x===1 ? letterPage.margin : letterPage.centerText() - (doc.getTextWidth(`Pg. ${x} of ${doc.getNumberOfPages()}`)/2),
                letterPage.margin
            );
    
        }

        if(creationType === 'print'){
            window.open(doc.output('bloburl'), '_blank');
        }else{
            doc.save(`${titleType} -${address}-${city}_${todayString}.pdf`);
        }
    };

    const reStockItems = async (orderID, returnCart, setFinalized=true)=>{
        // console.log(orderID);
        // console.log(returnCart);
        const websiteDataRef = doc(db, 'website_data', 'products');
        const bookingRef = doc(db, 'bookings', orderID);
        const bookingPrivateRef = doc(db, `bookings/${orderID}/private`, "info");
        const finalizedBookingRef = doc(db, 'finalized', orderID);
        const canceledBookingRef = doc(db, 'canceled', orderID);

        try{
            const result = await runTransaction(db, async (transaction)=>{
                const websiteDoc = await transaction.get(websiteDataRef);
                const bookingDoc = await transaction.get(bookingRef);
                const bookingPrivateDoc = await transaction.get(bookingPrivateRef);

                if(!websiteDoc.exists() || !bookingDoc.exists() || !bookingPrivateDoc.exists()){
                    throw new Error('Documents do not exist. Please contact admin');
                }
                const finalizedBookingData = {
                    [orderID]: {
                        ...bookingDoc.data(),
                        'private': {
                            'info':{
                                ...bookingPrivateDoc.data(),
                            }
                        },
                    },
                    'complete_timestamp': Date.now(),
                };
                let websiteData = websiteDoc.data();
                // let tempReturnItem = [];
                let errorReport = [];
                returnCart.forEach((item)=>{
                    // tempReturnItem.push({Quantity: item.Quantity, Dep: item.productInfo.department, Sub: item.productInfo.subdepartment, Name: item.productInfo.itemInfo.name});
                    // if item exists
                    if(websiteData?.[item.productInfo.department]?.[item.productInfo.subdepartment]?.[item.productInfo.itemInfo.name] !== undefined){
                        errorReport.push({name: item.productInfo.itemInfo.name, result: true});
                        websiteData = {
                            ...websiteData,
                            [item.productInfo.department]:{
                                ...websiteData[item.productInfo.department],
                                [item.productInfo.subdepartment]:{
                                    ...websiteData[item.productInfo.department][item.productInfo.subdepartment],
                                    [item.productInfo.itemInfo.name]: {
                                        ...websiteData[item.productInfo.department][item.productInfo.subdepartment][item.productInfo.itemInfo.name],
                                        quantity: websiteData[item.productInfo.department][item.productInfo.subdepartment][item.productInfo.itemInfo.name].quantity + item.Quantity,
                                    }
                                }
                            }
                        }
                    }else{
                        // could not be returned, item was not found
                        errorReport.push({name: item.productInfo.itemInfo.name, result: false});
                    }
                });

                // save websiteData
                transaction.set(websiteDataRef, websiteData);

                if(setFinalized){
                    // save finalizedBooingData
                    transaction.set(finalizedBookingRef, finalizedBookingData);
                }else{
                    // save to canceled bookings - same finalizedBookingData
                    transaction.set(canceledBookingRef, finalizedBookingData);
                }
                
                // delete old booking
                transaction.delete(bookingPrivateRef);
                transaction.delete(bookingRef);
                return errorReport;
            });
            // console.log(result);
            setUpdateOrderButtonStatus('edit');
            setModalShow(false);

            let resultString = 'The following items could not be updated.\n(Item may not longer exist)\n';
            let displayAlert = false;
            result.forEach((item)=>{
                if(!item.result){
                    resultString += `\n${item.name}`;
                    displayAlert = true;
                }
            });
            if(displayAlert){
                alert(resultString);
            }

            toast.success('Success, transaction complete', {
            style: {backgroundColor:'var(--success)'}
        });
        }catch(error){
             toast.error(`Error could not complete restock: ${error}`, {
                style: {backgroundColor:'var(--error)'}
            });
        }
    }

    const OrderModal = (props)=>{
        const [deliveryDetailsAddress, setDeliveryDetailsAddress] = useState(props?.order?.private?.['delivery address']);
        const [deliveryDetailsCity, setDeliveryDetailsCity] = useState(props?.order?.private?.['delivery city']);
        const [deliveryDetailsProvince, setDeliveryDetailsProvince] = useState(props?.order?.private?.['delivery province']);
        const [deliveryDetailsCountry, setDeliveryDetailsCountry] = useState(props?.order?.private?.['delivery country']);
        const [deliveryDetailsPostalCode, setDeliveryDetailsPostalCode] = useState(props?.order?.private?.['delivery postalcode']);
        const [deliveryDetailsDate, setDeliveryDetailsDate] = useState(convertToBootstrapDateFormat(props?.order?.public?.['delivery date']));
        const [deliveryDetailsWindow, setDeliveryDetailsWindow] = useState(props?.order?.private?.['delivery window']);
        const [deliveryDetailsStartHours, setDeliveryDetailsStartHours] = useState(getHourFromTwentyFourHour(props?.order?.public?.['delivery start']));
        const [deliveryDetailsStartTime24, setDeliveryDetailsStartTime24] = useState(props?.order?.public?.['delivery start']);
        const [deliveryDetailsEndTime24, setDeliveryDetailsEndTime24] = useState(props?.order?.public?.['delivery end']);
        const [deliveryDetailsStartMinutes, setDeliveryDetailsStartMinutes] = useState(getMinutesFromTwentyFourHour(props?.order?.public?.['delivery start']));
        const [deliveryDetailsEndHours, setDeliveryDetailsEndHours] = useState(getHourFromTwentyFourHour(props?.order?.public?.['delivery end']));
        const [deliveryDetailsEndMinutes, setDeliveryDetailsEndMinutes] = useState(getMinutesFromTwentyFourHour(props?.order?.public?.['delivery end']));
        const [deliveryDetailsNotes, setDeliveryDetailsNotes] = useState(props?.order?.private?.['delivery_notes']);

        const [billingDetailsEmail, setBillingDetailsEmail] = useState(props?.order?.private?.['email']);
        const [billingDetailsFirstName, setBillingDetailsFirstName] = useState(props?.order?.private?.['billing first name']);
        const [billingDetailsLastName, setBillingDetailsLastName] = useState(props?.order?.private?.['billing last name']);
        const [billingDetailsCompany, setBillingDetailsCompany] = useState(props?.order?.private?.['billing company']);
        const [billingDetailsAddress, setBillingDetailsAddress] = useState(props?.order?.private?.['billing address']);
        const [billingDetailsCity, setBillingDetailsCity] = useState(props?.order?.private?.['billing city']);
        const [billingDetailsProvince, setBillingDetailsProvince] = useState(props?.order?.private?.['billing province']);
        const [billingDetailsCountry, setBillingDetailsCountry] = useState(props?.order?.private?.['billing country']);
        const [billingDetailsPostalCode, setBillingDetailsPostalCode] = useState(props?.order?.private?.['billing postalcode']);
        const [customerPhoneNumber, setCustomPhoneNumber] = useState(props?.order?.private?.['billing phone number']);

        const [orderDueDate, setOrderDueDate] = useState(props?.order?.public?.['order due date'] !== undefined ? props.order.public['order due date'] : "");
        const [scheduledPickupDate, setScheduledPickupDate] = useState(props?.order?.public?.['scheduled pickup date'] !== undefined ? props.order.public['scheduled pickup date'] : "");

        const [returnCart, setReturnCart] = useState(props?.order?.private?.cart !== undefined ? props?.order?.private?.cart : []);

        // const [billingDetailsCardNumber, setBillingDetailsCarNumber] = useState('');
        // const [billingDetailsExpiryMonth, setBillingDetailsExpiryMonth] = useState('');
        // const [billingDetailsExpiryYear, setBillingDetailsExpiryYear] = useState('');
        // const [billingDetailsCVV, setBillingDetailsCVV] = useState('');

        const hours  = ["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
        const hoursDescription = [
            "12am (midnight)",
            "1 am",
            "2 am",
            "3 am",
            "4 am",
            "5 am",
            "6 am",
            "7 am",
            "8 am",
            "9 am",
            "10 am",
            "11 am",
            "12 pm (noon)",
            "1 pm",
            "2 pm",
            "3 pm",
            "4 pm",
            "5 pm",
            "6 pm",
            "7 pm",
            "8 pm",
            "9 pm",
            "10 pm",
            "11 pm",
        ];
        const minutes = ["00","15","30","45"];

        return(
            <Modal
                {...props}
                fullscreen
                animation={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                            Order Information - {props?.order?.private?.['delivery address']}, {props?.order?.private?.["delivery city"]}
                            {
                                (props?.order?.public?.status === 1 && orderDueDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order Confirmed', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        Confirm Order
                                    </Button>
                            }

                            {
                                (props?.order?.public?.status === 2 && orderDueDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(-1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order moved to New Orders', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        REVERSE CONFIRMED
                                    </Button>
                            }
                            {
                                (props?.order?.public?.status === 2 && orderDueDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order Delivered', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        MARK DELIVERED
                                    </Button>
                            }
                            
                            {
                                (props?.order?.public?.status === 3 && orderDueDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(-1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order moved to Next Deliveries', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        REVERSE MARK DELIVERED
                                    </Button>
                            }
                            {
                                (props?.order?.public?.status === 3 && orderDueDate !== "" && scheduledPickupDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order Marked for Pickup', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        MARK FOR PICKUP
                                    </Button>
                            }
                            {
                                (props?.order?.public?.status === 4 && orderDueDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(-1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order moved to Orders Coming Due', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        REVERSE SCHEDULE PICKUP
                                    </Button>
                            }
                            {
                                (props?.order?.public?.status === 4 && orderDueDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order Returned', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        MARK ORDER RETURNED
                                    </Button>
                            }
                            {
                                (props?.order?.public?.status === 5 && orderDueDate !== "" && updateOrderButtonStatus !== 'save') &&
                                    <Button onClick={()=>{
                                        const ref = doc(db, "bookings", props.order.id);
                                        updateDoc(ref, {
                                            status: increment(-1)
                                        }).then(()=>{
                                            props.onHide();
                                            toast.success('Order moved to Next Deliveries', {
                                                style: {backgroundColor:'var(--success)'}
                                            });
                                        }).catch((error)=>{
                                            toast.error(`Error: ${error}`, {
                                                style: {backgroundColor:'var(--error)'}
                                            });
                                        });
                                    }}
                                    style={{marginLeft:"20px", marginRight:"20px"}}
                                    >
                                        REVERSE ORDER RETURNED
                                    </Button>
                            }
                            {
                                updateOrderButtonStatus === 'edit' ? 
                                (
                                    props?.order?.public?.status < 5 &&
                                    <Button
                                        style={{marginLeft:"20px", marginRight:"20px"}}
                                        onClick={()=>{
                                            setUpdateOrderButtonStatus('save');
                                            setSaveFlag(true);
                                        }}
                                    >Edit Order</Button>
                                )
                                :
                                (
                                    <Button
                                        style={{marginLeft:"20px", marginRight:"20px"}}
                                        onClick={(e)=>{
                                                e.preventDefault();
                                                props.order.private['delivery address'] = deliveryDetailsAddress;
                                                props.order.private['delivery city'] = deliveryDetailsCity;
                                                props.order.private['delivery province'] = deliveryDetailsProvince;
                                                props.order.private['delivery country'] = deliveryDetailsCountry;
                                                props.order.private['delivery postalcode'] = deliveryDetailsPostalCode;

                                                props.order.public['delivery date'] = convertMyDatePickerDateFormat(deliveryDetailsDate);
                                                props.order.public['delivery timestamp'] = createTimeStamp3(convertMyDatePickerDateFormat(deliveryDetailsDate));
                                                props.order.private['delivery date'] = convertMyDatePickerDateFormat(deliveryDetailsDate);
                                                props.order.private['delivery window'] = deliveryDetailsWindow;
                                                props.order.public['delivery start'] = deliveryDetailsStartTime24;
                                                props.order.public['delivery end'] = deliveryDetailsEndTime24;
                                                props.order.private['delivery_notes'] = deliveryDetailsNotes;

                                                props.order.private['billing phone number'] = customerPhoneNumber;
                                                props.order.private['email'] = billingDetailsEmail;
                                                props.order.private['billing first name'] = billingDetailsFirstName;
                                                props.order.private['billing last name'] = billingDetailsLastName;
                                                props.order.private['billing company'] = billingDetailsCompany;
                                                props.order.private['billing address'] = billingDetailsAddress;
                                                props.order.private['billing city'] = billingDetailsCity;
                                                props.order.private['billing province'] = billingDetailsProvince;
                                                props.order.private['billing country'] = billingDetailsCountry;
                                                props.order.private['billing postalcode'] = billingDetailsPostalCode;

                                                props.order.public['order due date'] = orderDueDate;
                                                props.order.public['scheduled pickup date'] = scheduledPickupDate;
                                            // save to database
                                            const refPublic = doc(db, "bookings", props.order.id);
                                            const refPrivate = doc(db, "bookings", `${props.order.id}/private/info`);
                                            updateDoc(refPublic, {
                                                'delivery date': convertMyDatePickerDateFormat(deliveryDetailsDate),
                                                'delivery timestamp': createTimeStamp3(convertMyDatePickerDateFormat(deliveryDetailsDate)),
                                                'delivery start': deliveryDetailsStartTime24,
                                                'delivery end': deliveryDetailsEndTime24,
                                                'order due date': orderDueDate,
                                                'scheduled pickup date': scheduledPickupDate,
                                                'server timestamp': serverTimestamp(),
                                            }).then(()=>{
                                                updateDoc(refPrivate, {
                                                    'delivery address': deliveryDetailsAddress,
                                                    'delivery city': deliveryDetailsCity,
                                                    'delivery province': deliveryDetailsProvince,
                                                    'delivery country': deliveryDetailsCountry,
                                                    'delivery postalcode': deliveryDetailsPostalCode,
                                                    'delivery date': convertMyDatePickerDateFormat(deliveryDetailsDate),
                                                    'delivery window': deliveryDetailsWindow,
                                                    'delivery_notes': deliveryDetailsNotes,
                                                    'billing phone number': customerPhoneNumber,
                                                    'email': billingDetailsEmail,
                                                    'billing first name': billingDetailsFirstName,
                                                    'billing last name': billingDetailsLastName,
                                                    'billing company': billingDetailsCompany,
                                                    'billing address': billingDetailsAddress,
                                                    'billing city': billingDetailsCity,
                                                    'billing province': billingDetailsProvince,
                                                    'billing country': billingDetailsCountry,
                                                    'billing postalcode': billingDetailsPostalCode,
                                                }).then(()=>{
                                                    setUpdateOrderButtonStatus('edit');
                                                    setSaveFlag(false);
                                                    toast.success('Order Updated', {
                                                        style: {backgroundColor:'var(--success)'}
                                                    });
                                                }).catch((error)=>{
                                                    toast.error(`Error: ${error}`, {
                                                        style: {backgroundColor:'var(--error)'}
                                                    });
                                                });
                                            }).catch((error)=>{
                                                toast.error(`Error: ${error}`, {
                                                    style: {backgroundColor:'var(--error)'}
                                                });
                                            });

                                            // console.log(props);
                                        }}
                                    >Save</Button>
                                )
                            }
                            {
                                updateOrderButtonStatus === 'save' &&
                                (
                                    <Button
                                        style={{marginLeft:"20px", marginRight:"20px"}}
                                        onClick={()=>{
                                            setUpdateOrderButtonStatus('edit');
                                            setSaveFlag(false);
                                        }}
                                    >Cancel Edit</Button>
                                )
                            }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid style={{marginTop:"20px"}}>
                        <Row>
                            <Col lg="6"  style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>GENERAL INFORMATION</Col></Row>
                                <Row style={{marginLeft: "20px", marginTop:"10px"}}>
                                    <Col>
                                        Status: 
                                        {props?.order?.public?.status === 1 && ' NEW ORDER'}
                                        {props?.order?.public?.status === 2 && ' SCHEDULED FOR DELIVERY (see DELIVERY DETAILS below)'}
                                        {props?.order?.public?.status === 3 && ' DELIVERED'}
                                        {props?.order?.public?.status === 4 && ' SCHEDULED FOR PICKUP'}
                                        {props?.order?.public?.status === 5 && ' STOCK REVIEW'}
                                    </Col>
                                </Row>
                                <Row style={{marginLeft: "20px", marginTop:"10px"}}>
                                    <Col>
                                        Next Steps:
                                        {props?.order?.public?.status === 1 && ' REVIEW, SET CONTRACT END DATE AND CONFIRM ORDER'}
                                        {props?.order?.public?.status === 2 && ' PICK ITEMS, COORDINATE TRUCKS AND DELIVER'}
                                        {props?.order?.public?.status === 3 && ' SCHEDULE PICKUP'}
                                        {props?.order?.public?.status === 4 && ' COORDINATE TRUCKS AND PICKUP'}
                                        {props?.order?.public?.status === 5 && ' REVIEW RETURNED ITEMS AND RESTOCK'}
                                    </Col>
                                </Row>
                                <Row style={{marginLeft: "20px", marginTop:"10px", width:"360px"}}><Col>Contract End date:</Col><Col><Form.Control type="date" value={orderDueDate} onChange={(e)=> setOrderDueDate(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft: "20px", marginTop:"5px"}}>
                                    <Col>
                                        (standard contract is {contractLength} days)
                                        {
                                            (updateOrderButtonStatus === 'save' && props?.order?.public?.status === 1) &&
                                            <Button onClick={()=>{
                                                setOrderDueDate(convertToBootstrapDateFormatFromTimeStamp(props?.order?.public?.['delivery timestamp'] + (contractLength * 86400000)));
                                            }}>
                                                SET {contractLength} DAYS
                                            </Button>
                                        }
                                        {
                                            (updateOrderButtonStatus === 'save' && props?.order?.public?.status === 3) &&
                                            <Button onClick={()=>{
                                                setOrderDueDate(convertToBootstrapDateFormatFromTimeStamp(createTimeStamp2(props?.order?.public?.['order due date']) + (contractLength * 86400000)));
                                            }}>
                                                ADDITIONAL {contractLength} DAYS
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                                {
                                    (props?.order?.public?.status === 3 || props?.order?.public?.status === 4 || props?.order?.public?.status === 5) &&
                                        <Row style={{marginLeft: "20px", marginTop:"10px", width:"330px"}}>
                                            <Col>
                                                Scheduled Pickup:
                                            </Col>
                                            <Col><Form.Control type="date" value={scheduledPickupDate} onChange={(e)=> setScheduledPickupDate(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col>
                                        </Row>
                                }
                                <Row style={{marginLeft: "20px", marginTop:"10px", width:"400px"}}><Col>Delivery Location:</Col><Col><a href={`https://www.google.com/maps/place/${deliveryDetailsAddress}+${deliveryDetailsCity}+${deliveryDetailsProvince}+${deliveryDetailsCountry}+${deliveryDetailsPostalCode}`} target="_blank" rel="noreferrer">View Map</a></Col></Row>
                            </Col>
                            {
                                (props?.order?.public?.status === 1 && updateOrderButtonStatus === 'save') &&
                                <Col style={{display:'flex', alignItems:'flex-end', justifyContent:'center'}}>
                                    <Button
                                        variant="danger"
                                        style={{marginLeft:"20px", marginRight:"20px", marginBottom: "20px"}}
                                        onClick={()=>{
                                            let goodToGo = true;
                                            returnCart.forEach((item)=>{
                                                if(item.Quantity === ""){
                                                    goodToGo = false;
                                                }
                                            });
                                            if(goodToGo){
                                                if(window.confirm("Are you sure you want to CANCEL and restock all items?\nPlease verify each quantity to be returned into stock.") === true){
                                                    reStockItems(props.order.id, returnCart, false);
                                                }else{
                                                    toast.error(`Did not cancel order`, {
                                                        style: {backgroundColor:'var(--error)'}
                                                    });
                                                }
                                            }else{
                                                toast.error("One or more items do not have a valid return quantity.", {
                                                    style: {backgroundColor:'var(--error)'}
                                                });
                                            }
                                        }}
                                    >Cancel Order<br></br>Warning Irreversible</Button>
                                </Col>
                            }
                            {
                                props?.order?.public?.status === 5 &&
                                <Col style={{display:'flex', alignItems:'flex-end', justifyContent:'center'}}>
                                    <Button
                                        style={{marginLeft:"20px", marginRight:"20px", marginBottom: "20px"}}
                                        onClick={()=>{
                                            let goodToGo = true;
                                            returnCart.forEach((item)=>{
                                                if(item.Quantity === ""){
                                                    goodToGo = false;
                                                }
                                            });
                                            if(goodToGo){
                                                if(window.confirm("Are you sure you want to restock all items?\nPlease verify each quantity to be returned into stock.") === true){
                                                    reStockItems(props.order.id, returnCart, true);
                                                }else{
                                                    toast.error(`Restock canceled.`, {
                                                        style: {backgroundColor:'var(--error)'}
                                                    });
                                                }
                                            }else{
                                                toast.error("One or more items do not have a valid return quantity.", {
                                                    style: {backgroundColor:'var(--error)'}
                                                });
                                            }
                                        }}
                                    >Restock Items</Button>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>DELIVERY DETAILS</Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Street Address</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={deliveryDetailsAddress} onChange={(e)=> setDeliveryDetailsAddress(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Town / City</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={deliveryDetailsCity} onChange={(e)=> setDeliveryDetailsCity(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Province</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={deliveryDetailsProvince} onChange={(e)=> setDeliveryDetailsProvince(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Country</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={deliveryDetailsCountry} onChange={(e)=> setDeliveryDetailsCountry(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Postal Code</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={deliveryDetailsPostalCode} onChange={(e)=> setDeliveryDetailsPostalCode(e.target.value)} disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Delivery Date</Col></Row>

                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px", width:"200px"}}><Col><Form.Control type="date" value={deliveryDetailsDate} onChange={(e)=> setDeliveryDetailsDate(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                
                                <Row style={{marginLeft:"10px"}}><Col>Delivery Window</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={deliveryDetailsWindow} required disabled /></Col></Row>
                                
                                {
                                    updateOrderButtonStatus !== 'edit' ? 
                                    <>
                                        <Row style={{marginLeft:"10px"}}><Col>Delivery Window Start</Col></Row>
                                        <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}>
                                            <Col xs="4" sm="4" lg="3">
                                                <Form.Label>HRS</Form.Label>
                                                <Form.Select value={deliveryDetailsStartHours} onChange={(e) => {
                                                        setDeliveryDetailsStartHours(e.target.value);
                                                        setDeliveryDetailsEndHours(`${parseInt(e.target.value)+1}`);
                                                        setDeliveryDetailsStartTime24(parseInt(`${e.target.value}${deliveryDetailsStartMinutes}`));
                                                        setDeliveryDetailsEndTime24(parseInt(`${parseInt(e.target.value)+1}${deliveryDetailsStartMinutes}`));
                                                        setDeliveryDetailsWindow(`${getTwelveHourTime(parseInt(`${e.target.value}${deliveryDetailsStartMinutes}`))} - ${getTwelveHourTime(parseInt(`${parseInt(e.target.value)+1}${deliveryDetailsStartMinutes}`))}`);
                                                    }} required disabled={updateOrderButtonStatus === 'edit' ? true : false}>
                                                    {
                                                        hours.map((hour, index)=>{
                                                            return(<option key={index} value={hour}>{hoursDescription[index]}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                            </Col>
                                            <Col xs="4" sm="4" lg="3">
                                                <Form.Label>MINS</Form.Label>
                                                <Form.Select value={deliveryDetailsStartMinutes} onChange={(e) => {
                                                        setDeliveryDetailsStartMinutes(e.target.value);
                                                        setDeliveryDetailsStartTime24(parseInt(`${deliveryDetailsStartHours}${e.target.value}`));
                                                        setDeliveryDetailsWindow(`${getTwelveHourTime(parseInt(`${deliveryDetailsStartHours}${e.target.value}`))} - ${getTwelveHourTime(deliveryDetailsEndTime24)}`);
                                                    }} required disabled={updateOrderButtonStatus === 'edit' ? true : false}>
                                                    {
                                                        minutes.map((minute, index)=>{
                                                            return(<option key={index} value={minute}>{minute}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                            </Col>
                                        </Row>

                                        <Row style={{marginLeft:"10px"}}><Col>Delivery Window End</Col></Row>
                                        <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}>
                                            <Col xs="4" sm="4" lg="3">
                                                <Form.Label>HRS</Form.Label>
                                                <Form.Select value={deliveryDetailsEndHours} onChange={(e) => {
                                                        setDeliveryDetailsEndHours(e.target.value);
                                                        setDeliveryDetailsEndTime24(parseInt(`${e.target.value}${deliveryDetailsStartMinutes}`));
                                                        setDeliveryDetailsWindow(`${getTwelveHourTime(deliveryDetailsStartTime24)} - ${getTwelveHourTime(parseInt(`${e.target.value}${deliveryDetailsStartMinutes}`))}`);
                                                    }} required disabled={updateOrderButtonStatus === 'edit' ? true : false}>
                                                    {
                                                        hours.map((hour, index)=>{
                                                            if(parseInt(hour) === parseInt(deliveryDetailsStartHours) && parseInt(deliveryDetailsStartMinutes) > 30){
                                                                return(<option key={index} value={hour} disabled>{hoursDescription[index]}</option>)
                                                            }
                                                            if(parseInt(hour) < parseInt(deliveryDetailsStartHours)){
                                                                return(<option key={index} value={hour} disabled>{hoursDescription[index]}</option>)
                                                            }
                                                            return(<option key={index} value={hour}>{hoursDescription[index]}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                            </Col>
                                            <Col xs="4" sm="4" lg="3">
                                                <Form.Label>MINS</Form.Label>
                                                <Form.Select value={deliveryDetailsEndMinutes} onChange={(e) => {
                                                        setDeliveryDetailsEndMinutes(e.target.value);
                                                        setDeliveryDetailsEndTime24(parseInt(`${deliveryDetailsEndHours}${e.target.value}`));
                                                        setDeliveryDetailsWindow(`${getTwelveHourTime(deliveryDetailsStartTime24)} - ${getTwelveHourTime(parseInt(`${deliveryDetailsEndHours}${e.target.value}`))}`);
                                                    }} required disabled={updateOrderButtonStatus === 'edit' ? true : false}>
                                                    {
                                                        minutes.map((minute, index)=>{
                                                            return(<option key={index} value={minute}>{minute}</option>)
                                                        })
                                                    }
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <div></div>
                                }
                                
                                <Row style={{marginLeft:"10px"}}><Col>Order Notes</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control as="textarea" value={deliveryDetailsNotes} onChange={(e)=>{setDeliveryDetailsNotes(e.target.value)}} rows={4} disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                            </Col>
                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}>
                                    <Col>INVENTORY DETAILS</Col>
                                    {(props?.order?.public?.status === 2 || props?.order?.public?.status === 4 || props?.order?.public?.status === 5) && updateOrderButtonStatus !== 'save' &&
                                    <Col><Button onClick={()=>{createPickList(
                                        props?.order?.private?.cart,
                                        props?.order?.private?.['delivery address'],
                                        props?.order?.private?.["delivery city"],
                                        props.order.private['billing first name'],
                                        props.order.private['billing last name'],
                                        props.order.private['billing phone number'],
                                        props.order.private['email'] = billingDetailsEmail,
                                        props.order.private['billing company'],
                                        props.order.private['delivery address'],
                                        props.order.private['delivery city'],
                                        props.order.private['delivery province'],
                                        props.order.private['delivery country'],
                                        props.order.private['delivery postalcode'],
                                        props.order.public['delivery date'],
                                        props.order.private['delivery window'],
                                        props.order.private['delivery_notes'],
                                        props?.order?.id,
                                        props.order.public['order due date'],
                                        "print",
                                        props?.order?.public?.status === 2 ? 'Picklist' : props?.order?.public?.status === 4 ? 'PickUp List' : 'Returned List',
                                    )}}>{props?.order?.public?.status === 2 ? 'Print Pick List' : props?.order?.public?.status === 4 ? 'Print PickUp List' : 'Print Returned List'}</Button></Col>}
                                    {(props?.order?.public?.status === 2 || props?.order?.public?.status === 4 || props?.order?.public?.status === 5)&& updateOrderButtonStatus !== 'save' &&
                                    <Col><Button onClick={()=>{createPickList(
                                        props?.order?.private?.cart,
                                        props?.order?.private?.['delivery address'],
                                        props?.order?.private?.["delivery city"],
                                        props.order.private['billing first name'],
                                        props.order.private['billing last name'],
                                        props.order.private['billing phone number'],
                                        props.order.private['email'] = billingDetailsEmail,
                                        props.order.private['billing company'],
                                        props.order.private['delivery address'],
                                        props.order.private['delivery city'],
                                        props.order.private['delivery province'],
                                        props.order.private['delivery country'],
                                        props.order.private['delivery postalcode'],
                                        props.order.public['delivery date'],
                                        props.order.private['delivery window'],
                                        props.order.private['delivery_notes'],
                                        props?.order?.id,
                                        props.order.public['order due date'],
                                        "save",
                                        props?.order?.public?.status === 2 ? 'Picklist' : props?.order?.public?.status === 4 ? 'PickUp List' : 'Returned List',
                                    )}}>{props?.order?.public?.status === 2 ? 'Save Pick List' : props?.order?.public?.status === 4 ? 'Save PickUp List' : 'Save Returned List'}</Button></Col>}
                                </Row>
                                {
                                    props?.order?.private?.cart?.map((item, index)=>{
                                        return(
                                            <Row key={index} style={{marginLeft:"10px", marginBottom: "5px", marginTop:"30px"}}>
                                                <Col xs="9">
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col>Item: {index+1}</Col></Row>
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col>Name: <span style={{fontSize:"1.3em"}}>{item?.productInfo?.itemInfo?.name}</span> (${item?.productInfo?.itemInfo?.info?.price})</Col></Row>
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col>Quantity: <span style={{fontSize:"1.5em"}}>{item?.Quantity}</span></Col></Row>
                                                </Col>
                                                <Col xs="3">
                                                    <Row style={{marginLeft:"10px", marginBottom: "5px"}}><Col><Image src={item?.productInfo?.itemInfo?.info.image_urls[0]} alt={item?.productInfo?.itemInfo?.name} style={{width:"100%", height:"auto"}} /></Col></Row>
                                                </Col>
                                                <Row>
                                                    {
                                                        (props?.order?.public?.status === 5 || (props?.order?.public?.status === 1 && updateOrderButtonStatus === 'save')) &&
                                                        <Row>
                                                            <Col xs="9">
                                                                <Row style={{marginLeft:"10px", marginBottom: "5px"}}>
                                                                    <Col>Return Qty: </Col>
                                                                    <Col style={{fontSize:"1.5em"}}>
                                                                        <Form.Control
                                                                            type="number"
                                                                            min="0"
                                                                            value={returnCart[index].Quantity}
                                                                            onChange={(e)=> {
                                                                                let tempReturnCart = [...returnCart];
                                                                                tempReturnCart[index] = {
                                                                                    ...tempReturnCart[index],
                                                                                    Quantity: e.target.value !== "" ? parseInt(e.target.value) : "",
                                                                                };
                                                                                setReturnCart(tempReturnCart);
                                                                            }} />
                                                                    </Col>
                                                                    
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </Row>
                                                
                                            </Row>
                                        )
                                    })
                                }
                            </Col>
                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>BILLING DETAILS</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Customer Id: {props?.order?.public?.id}</Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Phone Number</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={customerPhoneNumber} onChange={(e) => setCustomPhoneNumber(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Email Address</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsEmail} onChange={(e)=> setBillingDetailsEmail(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>First Name</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsFirstName} onChange={(e)=> setBillingDetailsFirstName(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Last Name</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsLastName} onChange={(e)=> setBillingDetailsLastName(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Company</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsCompany} onChange={(e)=> setBillingDetailsCompany(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Street Address</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsAddress} onChange={(e)=> setBillingDetailsAddress(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Town / City</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsCity} onChange={(e)=> setBillingDetailsCity(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Province</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsProvince} onChange={(e)=> setBillingDetailsProvince(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Country</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsCountry} onChange={(e)=> setBillingDetailsCountry(e.target.value)} required disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                                <Row style={{marginLeft:"10px"}}><Col>Postal Code</Col></Row>
                                <Row style={{marginLeft:"10px", marginRight:"10px", marginBottom:"10px"}}><Col><Form.Control type="text" value={billingDetailsPostalCode} onChange={(e)=> setBillingDetailsPostalCode(e.target.value)} disabled={updateOrderButtonStatus === 'edit' ? true : false} /></Col></Row>
                            </Col>
                            <Col lg="6" style={{border:"1px solid black", padding:"10px", borderRadius:"10px", marginBottom:"5px"}}>
                                <Row style={{marginBottom: "20px"}}><Col>TRANSACTION DETAILS</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Confirmation Number: {props?.order?.id}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Transaction Id: {props?.order?.private?.card_response?.transId}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Network Transaction Id: {props?.order?.private?.card_response?.networkTransId}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Authorization Code: {props?.order?.private?.card_response?.authCode}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Account Type: {props?.order?.private?.card_response?.accountType}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Account Number: {props?.order?.private?.card_response?.accountNumber}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Card Response: {props?.order?.private?.card_response?.message}</Col></Row>
                                {
                                    props?.order?.private?.["discount amount"] > 0 && props?.order.private?.["coupon code"] !== "" ?
                                        <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Coupon Information: {props?.order.private?.["coupon code"]} -${props?.order?.private?.["discount amount"]}</Col></Row>
                                    :
                                        ""
                                }
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Sub Total: ${props?.order?.private?.subtotal}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Delivery: ${props?.order?.private?.["delivery charge"]}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Tax: ${props?.order?.private?.tax}</Col></Row>
                                <Row style={{marginLeft:"10px", marginBottom: "20px"}}><Col>Total: ${props?.order?.private?.total}</Col></Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>{ /****************************DOUBLE CHECK SAVEFLAG IS NEEDED */
                        if(saveFlag === false){
                            props.onHide();
                        }else{
                            toast.warning('Edit Mode, press Save to save changes or close again to exit without saving', {
                                style: {backgroundColor:'var(--warning)'}
                            });
                            setSaveFlag(false);
                        }
                    }}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    };
    return (
        <>
            <Container fluid style={{width:"100%",paddingLeft:"65px", marginRight:"65px"}}>
                <Container fluid className="mt-5" style={{fontSize:"1.5em", letterSpacing:"0.2em"}}>
                    <Row className="justify-content-center">
                        Dashboard
                    </Row>
                </Container>
                <Container fluid className="mt-4">
                    <Row>
                        <Col>
                            <Card style={{border:"1px solid black"}}>
                                <Card.Header style={{textAlign:"center"}}>New Orders (20 day warning)</Card.Header>
                                <Card.Body>
                                    {
                                        newOrders.sort((a,b)=>{
                                            if(a.public['delivery timestamp'] < b.public['delivery timestamp']) { return -1 }
                                            if(a.public['delivery timestamp'] > b.public['delivery timestamp']) { return 1 }
                                            return 0;
                                        }).map((newOrder, index)=>{
                                            return(
                                                <div 
                                                    key={index}
                                                    style={createTimeStamp2(new Date().toDateString()) > newOrder.public['delivery timestamp'] - 1728000000 ? {color:"red", cursor:"pointer", borderBottom:"1px solid lightgrey"} : {color:"black", cursor:"pointer", borderBottom:"1px solid lightgrey"}}
                                                    onClick={()=>{
                                                        setChosenOrder(newOrder)
                                                        setModalShow(true)
                                                    }}
                                                >
                                                    {newOrder.public['delivery date']} - {newOrder.private["delivery address"]}, {newOrder.private["delivery city"]}
                                                </div>
                                            )
                                        })
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{border:"1px solid black"}}>
                                <Card.Header style={{textAlign:"center"}}>Next Deliveries (5 day warning)</Card.Header>
                                <Card.Body>
                                    {
                                        confirmedOrders.sort((a,b)=>{
                                            if(a.public['delivery timestamp'] < b.public['delivery timestamp']) { return -1 }
                                            if(a.public['delivery timestamp'] > b.public['delivery timestamp']) { return 1 }
                                            return 0;
                                        }).map((confirmedOrder, index)=>{
                                            return(
                                                <div
                                                    key={index}
                                                    style={createTimeStamp2(new Date().toDateString()) > confirmedOrder.public['delivery timestamp'] - 432000000 ? {color:"red", cursor:"pointer", borderBottom:"1px solid lightgrey"} : {color:"black", cursor:"pointer", borderBottom:"1px solid lightgrey"}}
                                                    onClick={()=>{
                                                        setChosenOrder(confirmedOrder)
                                                        base64Imgs.current = {};
                                                        confirmedOrder?.private?.cart?.forEach((item)=>{
                                                            toDataUrl(item?.productInfo?.itemInfo?.info.image_urls[0], function(myBase64) {
                                                                if(myBase64.split(';')[0] !== 'data:application/json'){
                                                                    base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`] = myBase64;
                                                                }else{
                                                                    base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`] = "";
                                                                }
                                                            });
                                                        });
                                                        setModalShow(true)
                                                    }}
                                                >
                                                    {confirmedOrder.public['delivery date']} - {confirmedOrder.private["delivery address"]}, {confirmedOrder.private["delivery city"]}
                                                </div>    
                                            )
                                        })
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"10px"}}>
                        <Col>
                            <Card style={{border:"1px solid black"}}>
                                <Card.Header style={{textAlign:"center"}}>Orders Coming Due (5 day warning)</Card.Header>
                                <Card.Body>
                                    {
                                        ordersOut.sort((a,b)=>{
                                            if(a.public['order due date'] < b.public['order due date']) { return -1 }
                                            if(a.public['order due date'] > b.public['order due date']) { return 1 }
                                            return 0;
                                        }).map((orderOut, index)=>{
                                            return(
                                                <div
                                                    key={index}
                                                    style={createTimeStamp2(new Date().toDateString()) > createTimeStamp2(orderOut.public['order due date']) - 432000000 ? {color:"red", cursor:"pointer", borderBottom:"1px solid lightgrey"} : {color:"black", cursor:"pointer", borderBottom:"1px solid lightgrey"}}
                                                    onClick={()=>{
                                                        setChosenOrder(orderOut)
                                                        setModalShow(true)
                                                    }}
                                                >
                                                    {formatChosenDeliveryDate(createTimeStamp2(orderOut.public['order due date']))} - {orderOut.private["delivery address"]}, {orderOut.private["delivery city"]}
                                                </div>
                                            )
                                        })
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col>
                            <Card style={{border:"1px solid black"}}>
                                <Card.Header style={{textAlign:"center"}}>Orders Scheduled for Pickup (5 day warning)</Card.Header>
                                <Card.Body>
                                    {
                                        ordersScheduledForPickup.sort((a,b)=>{
                                            if(a.public['scheduled pickup date'] < b.public['scheduled pickup date']) { return -1 }
                                            if(a.public['scheduled pickup date'] > b.public['scheduled pickup date']) { return 1 }
                                            return 0;
                                        }).map((orderScheduled, index)=>{
                                            return(
                                                <div 
                                                    key={index}
                                                    style={createTimeStamp2(new Date().toDateString()) > createTimeStamp2(orderScheduled.public['scheduled pickup date']) - 432000000 ? {color:"red", cursor:"pointer", borderBottom:"1px solid lightgrey"} : {color:"black", cursor:"pointer", borderBottom:"1px solid lightgrey"}}
                                                    onClick={()=>{
                                                        setChosenOrder(orderScheduled)
                                                        base64Imgs.current = {};
                                                        orderScheduled?.private?.cart?.forEach((item)=>{
                                                            toDataUrl(item?.productInfo?.itemInfo?.info.image_urls[0], function(myBase64) {
                                                                if(myBase64.split(';')[0] !== 'data:application/json'){
                                                                    base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`] = myBase64;
                                                                }else{
                                                                    base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`] = "";
                                                                }
                                                            });
                                                        });
                                                        setModalShow(true)
                                                    }}
                                                >
                                                    {orderScheduled.public["scheduled pickup date"]} - {orderScheduled.private["delivery address"]}, {orderScheduled.private["delivery city"]}
                                                </div>
                                            )
                                        })
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"10px"}}>
                        <Col>
                            <Card style={{border:"1px solid black", width:"50%", margin:"0 auto"}}>
                                <Card.Header style={{textAlign:"center"}}>Restocking Review</Card.Header>
                                <Card.Body>
                                    {
                                        ordersReturned.sort((a,b)=>{
                                            if(a.public['scheduled pickup date'] < b.public['scheduled pickup date']) { return -1 }
                                            if(a.public['scheduled pickup date'] > b.public['scheduled pickup date']) { return 1 }
                                            return 0;
                                        }).map((orderReturned, index)=>{
                                            return(
                                                <div 
                                                    key={index}
                                                    style={{color:"red", cursor:"pointer", borderBottom:"1px solid lightgrey"}}
                                                    onClick={()=>{
                                                        setChosenOrder(orderReturned)
                                                        base64Imgs.current = {};
                                                        orderReturned?.private?.cart?.forEach((item)=>{
                                                            toDataUrl(item?.productInfo?.itemInfo?.info.image_urls[0], function(myBase64) {
                                                                if(myBase64.split(';')[0] !== 'data:application/json'){
                                                                    base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`] = myBase64;
                                                                }else{
                                                                    base64Imgs.current[`${item?.productInfo?.itemInfo?.name}`] = "";
                                                                }
                                                            });
                                                        });
                                                        setModalShow(true)
                                                    }}
                                                >
                                                    {orderReturned.private["delivery address"]}, {orderReturned.private["delivery city"]}
                                                </div>
                                            )
                                        })
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
        
                <Container className="d-flex align-items-center justify-content-center" style={{minHeight: "10vh"}}>
                    <div style={{maxWidth: "600px"}}>
                        {currentUser?.email}
                    </div>
                </Container>
            </Container>

            <OrderModal
                show={modalShow}
                onHide={()=> {
                    setUpdateOrderButtonStatus('edit');
                    setModalShow(false);
                }}
                order={chosenOrder}
            />
        </>
    )
}
