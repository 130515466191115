import React, { useState, useEffect} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

import { 
    getTodaysYear,
    getTodaysDay,
    getTodaysMonth,
    getWeekInMonth,
    getDaysInMonth,
    getFirstDayInMonth ,
    getNumberOfTimeSlots,
    getTwelveHourTime,
    getLastDateInMonth,
    createTimeStamp2
} from '../HelperFunctions/helper_functions';

import { db } from '../firebase-config';

import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';

import '../css/calendar.css';

export default function Calendar({ leadTime, weekends, daysOff, limitDaysInAdvance, timeslotInfo, setChosenDeliveryDate, setChosenDeliveryWindow }) {
    
    const [displayCalendar, setDisplayCalendar] = useState(0); // 0 - loading, 1 - loaded, 2 - error loading
    const [chosenDate, setChosenDate] = useState();
    const [selectedDay, setSelectedDay] = useState();
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedYear, setSelectedYear] = useState();

    const [currentDay, setCurrentDay] = useState();
    //const [currentDayChosen, setCurrentDayChosen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState();
    const [currentYear, setCurrentYear] = useState();
    //const [bookingsOnThisMonth, setBookingsOnThisMonth] = useState([]);

    const [weeks, setWeeks] = useState([]);
    const [slots, setSlots] = useState([]);

    const [manualDaysOff, setManualDaysOff] = useState({});

    const [validDayChosen, setValidDayChosen] = useState(false);
    const [deliveryWindowChosen, setDeliveryWindowChosen] = useState('');

    const [monthBookings, setMonthBookings] = useState([]);

    // useEffect(()=>{
        
    // },[]);

    useEffect(()=>{
        window.scrollTo({top:0, behavior:'smooth'});
        setDisplayCalendar(0);
        const manualDaysOffRef = doc(db, 'manual_days_off', `${getTodaysYear()}`);
        getDoc(manualDaysOffRef).then((docSnap)=>{
            if(docSnap.exists()){
                let data = {[`${getTodaysYear()}`]: docSnap.data()}
                setManualDaysOff(data);
                setDisplayCalendar(1);
            }else{
                setManualDaysOff({});
                setDisplayCalendar(1);
            }
            return null;
        }).then(()=>{
            const bookingsRef = query(collection(db, 'bookings'), where("delivery timestamp", ">=", createTimeStamp2(`${getTodaysYear()}/${getTodaysMonth()}/${getTodaysDay()}`)), where("delivery timestamp", "<", createTimeStamp2(`${getTodaysYear()}/${getTodaysMonth()}/${getLastDateInMonth(getTodaysMonth(), getTodaysYear())}`,"end")));
            getDocs(bookingsRef).then((query)=>{
                let bookings = [];
                query.forEach((doc)=>{
                    bookings.push({info: doc.data(), booking_id: doc.id});
                });
                setMonthBookings(bookings);
                setDisplayCalendar(1);
                // console.log(bookings);
            }).catch((error)=>{
                setDisplayCalendar(2);
                console.log(`Error getting booking information: ${error}`);
            });
            return null;
        }).catch((error)=>{
            setDisplayCalendar(2);
            console.log(`Error geting website data: ${error}`);
        });
    }, []);
    
    useEffect(()=>{
        // do not load from database in this function
        setSelectedDay(selectedDay === undefined ? getTodaysDay() : selectedDay);
        setSelectedMonth(selectedMonth === undefined ? getTodaysMonth() : selectedMonth);
        setSelectedYear(selectedYear === undefined ? getTodaysYear() : selectedYear);

        setCurrentYear(selectedYear);
        setCurrentMonth(selectedMonth);
        setCurrentDay(selectedDay);

        setChosenDate(<div>{new Date(selectedYear, selectedMonth-1, selectedDay).toString().split('00')[0]}</div>);

        const weeksInMonth = getWeekInMonth(getDaysInMonth(selectedMonth, selectedYear), selectedMonth, selectedYear);
        const firstDayInMonth = getFirstDayInMonth(selectedMonth, selectedYear);
        const lastDate = getDaysInMonth(selectedMonth, selectedYear);

        setWeeks([]);
        setValidDayChosen(false);

        let localLeadTime = leadTime !== undefined ? leadTime : 0;
        let localWeekends = weekends !== undefined ? weekends : false;
        let localDaysOff = daysOff !== undefined ? daysOff : [];
        let localLimitDaysInAdvance = limitDaysInAdvance !== undefined ? limitDaysInAdvance : 0;
        let localTimeslotInfo = timeslotInfo !== undefined ? timeslotInfo : {hoursOfOperationStart: "0", hoursOfOperationEnd: "0", timeslotDuration: 0, deliverysPerTimeslot: 0};

        let drawMonth = [];
        let drawWeeks = []
        let dayCounter = 0;
        let firstDayDone = false;
        for(let week=0; week<=weeksInMonth; ++week){
            drawWeeks = []
            for(let day=0; day<7; ++day){

                
                if(day === firstDayInMonth && firstDayDone === false){
                    dayCounter++;
                    firstDayDone = true;
                    let chosenDay = dayCounter;
                    // get bookings for that day --------------------------------------------------------------------------------------------------
                    let bookingsOnThisDay = getBookingsForTheDay(selectedYear, selectedMonth, dayCounter, monthBookings);
                    let timeslotsAvailable = true;

                    let timeEnd = parseInt(localTimeslotInfo.hoursOfOperationEnd);
                    let timeStart = parseInt(localTimeslotInfo.hoursOfOperationStart);
                    let timeslotDuration = parseInt(localTimeslotInfo.timeslotDuration);
                    let timeslots = getNumberOfTimeSlots(timeEnd, timeStart, timeslotDuration);
                    let timeslotsUsed = 0;

                    // Go through time slots and determine if all time slots are used ------------------------------------------------------------
                    for(let x=0; x<timeslots; ++x){
                        const start = timeStart;
                        const end = timeStart += (timeslotDuration*100);
                        for(let booking=0; booking<bookingsOnThisDay.length; ++booking){
                            for(let y = bookingsOnThisDay[booking].info['delivery start']; y < bookingsOnThisDay[booking].info['delivery end']; y+=50){
                                if(y > start && y < end){
                                    timeslotsUsed++;
                                    break;
                                }
                            }
                        }
                    }
                    if(timeslotsUsed >= (timeslots*localTimeslotInfo.deliverysPerTimeslot)){ 
                        timeslotsAvailable = false;
                    }

                    if(new Date(selectedYear, selectedMonth-1,dayCounter).getTime() < new Date(getTodaysYear(), getTodaysMonth()-1, getTodaysDay()+localLeadTime).getTime()) {
                        // past days
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(new Date(getTodaysYear(), getTodaysMonth()-1, getTodaysDay()+localLimitDaysInAdvance).getTime() < new Date(selectedYear, selectedMonth-1,dayCounter).getTime()){
                        // if days in advance is limited
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(localWeekends === false && (day === 0 || day === 6)){
                        // if no deliverying on weekends
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(localDaysOff.includes(day)){
                        // if no delivery an any specific day of week (repeated)
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(manualDaysOff?.[`${selectedYear}`]?.[`${selectedMonth}`]?.includes(dayCounter)){
                        // if day is manually booked off
                        drawWeeks.push(<div className="noselect bookedOff" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(timeslotsAvailable === false){
                        // if day is fully booked
                        drawWeeks.push(<div className="noselect bookedOff" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(dayCounter === selectedDay){
                        // if this is the chosen day
                        setValidDayChosen(true)
                        drawWeeks.push(<div className="noselect chosenDay" onClick={()=>{
                            setSelectedDay(chosenDay)
                            setValidDayChosen(true)
                        }} key={`${week}-${day}`}>{dayCounter}</div>)
                    }else{
                        // non-chosen available day
                        drawWeeks.push(<div className="noselect nonChosenAvailableDay" onClick={()=>{
                            setSelectedDay(chosenDay)
                            setValidDayChosen(true)
                        }} key={`${week}-${day}`}>{dayCounter}</div>)
                    }
                }else if(firstDayDone && dayCounter < lastDate){
                    dayCounter++;
                    let chosenDay = dayCounter;

                    // get bookings for that day --------------------------------------------------------------------------------------------------
                    let bookingsOnThisDay = getBookingsForTheDay(selectedYear, selectedMonth, dayCounter, monthBookings);
                    let timeslotsAvailable = true;

                    let timeEnd = parseInt(localTimeslotInfo.hoursOfOperationEnd);
                    let timeStart = parseInt(localTimeslotInfo.hoursOfOperationStart);
                    let timeslotDuration = parseInt(localTimeslotInfo.timeslotDuration);
                    let timeslots = getNumberOfTimeSlots(timeEnd, timeStart, timeslotDuration);
                    let timeslotsUsed = 0;

                    // Go through time slots and determine if all time slots are used ------------------------------------------------------------
                    for(let x=0; x<timeslots; ++x){
                        const start = timeStart;
                        const end = timeStart += (timeslotDuration*100);
                        for(let booking=0; booking<bookingsOnThisDay.length; ++booking){
                            for(let y = bookingsOnThisDay[booking].info['delivery start']; y < bookingsOnThisDay[booking].info['delivery end']; y+=50){
                                if(y > start && y < end){
                                    timeslotsUsed++;
                                    break;
                                }
                            }
                        }
                    }
                    if(timeslotsUsed >= (timeslots*localTimeslotInfo.deliverysPerTimeslot)){ 
                        timeslotsAvailable = false;
                    }

                    if(new Date(selectedYear, selectedMonth-1,dayCounter).getTime() < new Date(getTodaysYear(), getTodaysMonth()-1, getTodaysDay()+localLeadTime).getTime()) {
                        // past days
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(new Date(getTodaysYear(), getTodaysMonth()-1, getTodaysDay()+localLimitDaysInAdvance).getTime() < new Date(selectedYear, selectedMonth-1,dayCounter).getTime()){
                        // if days in advance is limited
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(localWeekends === false && (day === 0 || day === 6)){
                        // if no deliverying on weekends
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(localDaysOff.includes(day)){
                        // if no delivery an any specific day of week (repeated)
                        drawWeeks.push(<div className="noselect blockedDays" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(manualDaysOff?.[`${selectedYear}`]?.[`${selectedMonth}`]?.includes(dayCounter)){
                        // if day is manually booked off
                        drawWeeks.push(<div className="noselect bookedOff" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(timeslotsAvailable === false){
                        // if day is fully booked
                        drawWeeks.push(<div className="noselect bookedOff" key={`${week}-${day}`}>{dayCounter}</div>)
                    }else if(dayCounter === selectedDay){
                        // if this is the chosen day
                        setValidDayChosen(true)
                        drawWeeks.push(<div className="noselect chosenDay" onClick={()=>{
                            setSelectedDay(chosenDay)
                            setValidDayChosen(true)
                        }} key={`${week}-${day}`}>{dayCounter}</div>)
                    }else{
                        // non-chosen available day
                        drawWeeks.push(<div className="noselect nonChosenAvailableDay" onClick={()=>{
                            setSelectedDay(chosenDay)
                            setValidDayChosen(true)
                        }} key={`${week}-${day}`}>{dayCounter}</div>)
                    }
                }else{
                    // non days
                    drawWeeks.push(<div className="noselect nonDays" key={`${week}-${day}`}></div>)
                }
            }
            drawMonth.push(<div key={week} style={{display:"flex", justifyContent:"space-around", textAlign:"center"}}>{drawWeeks}</div>);
        }
        setWeeks(drawMonth);

        if(validDayChosen){
            // do not load anything from database in this function

            setChosenDeliveryDate(new Date(selectedYear, selectedMonth-1, selectedDay).toString().split('00')[0]);

            let bookingsOnThisDay = getBookingsForTheDay(selectedYear, selectedMonth, selectedDay, monthBookings); 
            
            // create draw
            let drawSlotsContainer = [];
            let drawSlots = [];

            let localTimeslotInfo = timeslotInfo !== undefined ? timeslotInfo : {hoursOfOperationStart: "0", hoursOfOperationEnd: "0", timeslotDuration: 0, deliverysPerTimeslot: 0};
            
            let timeEnd = parseInt(localTimeslotInfo.hoursOfOperationEnd);
            let timeStart = parseInt(localTimeslotInfo.hoursOfOperationStart);
            let timeslotDuration = parseInt(localTimeslotInfo.timeslotDuration);
            let timeslots = getNumberOfTimeSlots(timeEnd, timeStart, timeslotDuration);

            for(let x=0; x<timeslots; ++x){
                const start = timeStart;
                const end = timeStart += (timeslotDuration*100);

                let numberOfDeliveriesPerSlot = localTimeslotInfo.deliverysPerTimeslot;
                let used = 0;

                let timeSlotAvailable = true;
                // review bookings for the day and determin which slots are used (do not load from database in this function) ----------------------------------
                bookingsOnThisDay.forEach((booking)=>{
                    for(let y = booking.info['delivery start']; y < booking.info['delivery end']; y+=50){
                        if(y > start && y < end){
                            //timeSlotAvailable = false;
                            used++;
                            break;
                        }
                    }
                });
                if(used >= numberOfDeliveriesPerSlot){
                    timeSlotAvailable = false;
                }
                if(timeSlotAvailable){
                    drawSlots.push(<div key={`timeslot_${x}`} className="timeslotsAvailable number" onClick={()=>{
                        setDeliveryWindowChosen(`${getTwelveHourTime(start)} - ${getTwelveHourTime(end)}`)
                        setChosenDeliveryWindow(`${getTwelveHourTime(start)} - ${getTwelveHourTime(end)}`)
                    }}>{getTwelveHourTime(start)} - {getTwelveHourTime(end)}</div>)
                }else{
                    drawSlots.push(<div key={`timeslot_${x}`} className="timeslotsUsed number">{getTwelveHourTime(start)} - {getTwelveHourTime(end)}</div>)
                }
            }
            drawSlotsContainer.push(<div key="slotsContainer" style={{display: "flex", flexDirection: "column", width: "75%", margin:"auto"}}>{drawSlots}</div>);

            setDeliveryWindowChosen('');
            setChosenDeliveryWindow('');
            setSlots(drawSlotsContainer);
        }else{
            setChosenDeliveryDate('');
            setChosenDeliveryWindow('');
        }

    }, [currentDay, currentMonth, currentYear, selectedDay, selectedMonth, selectedYear, leadTime, daysOff, limitDaysInAdvance, weekends, manualDaysOff, timeslotInfo, validDayChosen, setChosenDeliveryDate, setChosenDeliveryWindow, monthBookings]);

    const minusMonth = ()=>{
        setValidDayChosen(false);
        setSelectedMonth(currentMonth-1);
        setSelectedDay(1);
        if(currentMonth < 2){
            loadManualDaysOff(currentYear-1, 12);
            setSelectedMonth(12);
            setSelectedYear(currentYear-1);
        }else{
            loadMonthBookings(currentYear, currentMonth-1);
        }
    };
    const plusMonth = ()=>{
        setValidDayChosen(false);
        setSelectedMonth(currentMonth+1);
        setSelectedDay(1);
        if(currentMonth > 11){
            loadManualDaysOff(currentYear+1, 1);
            setSelectedMonth(1);
            setSelectedYear(currentYear+1);
        }else{
            loadMonthBookings(currentYear, currentMonth+1);
        }
    };
    // const goToToday = ()=>{
    //     setValidDayChosen(false);
    //     if(selectedYear !== getTodaysYear()){
    //         loadManualDaysOff(getTodaysYear(), getTodaysMonth());
    //     }else{
    //         loadMonthBookings(getTodaysYear(), getTodaysMonth());
    //     }
    //     setSelectedDay(getTodaysDay());
    //     setSelectedMonth(getTodaysMonth());
    //     setSelectedYear(getTodaysYear());
    // };

    const loadManualDaysOff = (year, month)=>{
        //setDisplayCalendar(0);
        const manualDaysOffRef = doc(db, 'manual_days_off', `${year}`);
        getDoc(manualDaysOffRef).then((docSnap)=>{
            if(docSnap.exists()){
                let data = {[`${year}`]: docSnap.data()}
                setManualDaysOff(data);
                setDisplayCalendar(1);
            }else{
                setManualDaysOff({});
                setDisplayCalendar(1);
            }
        }).then(()=>{
            const bookingsRef = query(collection(db, 'bookings'), where("delivery timestamp", ">=", createTimeStamp2(`${year}/${month}/${1}`)), where("delivery timestamp", "<", createTimeStamp2(`${year}/${month}/${getLastDateInMonth(month, year)}`,"end")));
            getDocs(bookingsRef).then((query)=>{
                let bookings = [];
                query.forEach((doc)=>{
                    bookings.push({info: doc.data(), booking_id: doc.id});
                });
                setMonthBookings(bookings);
                setDisplayCalendar(1);
            }).catch((error)=>{
                setDisplayCalendar(2);
                console.log(`Error getting booking information: ${error}`);
            });
        }).catch((error)=>{
            setDisplayCalendar(2);
            console.log(`Error geting website data: ${error}`);
        });
    };

    const loadMonthBookings = (year, month)=>{
        const bookingsRef = query(collection(db, 'bookings'), where("delivery timestamp", ">=", createTimeStamp2(`${year}/${month}/${1}`)), where("delivery timestamp", "<", createTimeStamp2(`${year}/${month}/${getLastDateInMonth(month, year)}`,"end")));
        getDocs(bookingsRef).then((query)=>{
            let bookings = [];
            query.forEach((doc)=>{
                bookings.push({info: doc.data(), booking_id: doc.id});
            });
            setMonthBookings(bookings);
            setDisplayCalendar(1);
        }).catch((error)=>{
            setDisplayCalendar(2);
            console.log(`Error getting booking information: ${error}`);
        });
    };

    const getBookingsForTheDay = (year, month, day, bookings)=>{  // this called from the database call for the month bookings (no database call here)
        return bookings.filter((booking) => booking.info["delivery timestamp"] === new Date(year, month-1,day).getTime());
    };
    // <div className="noselect" style={{textAlign: "center"}}><button onClick={()=> goToToday()}>Go to Today</button></div>
    return (
        <>
        {
            displayCalendar === 0 ?
                <div>Loading Calendar...</div>
            :
                displayCalendar === 1 ?
                    <div className="number" style={{textAlign:"center"}}>
                        <div style={{display:"flex", justifyContent:"space-between", margin:"10px"}}>
                            <FontAwesomeIcon style={{fontSize: '1.75em', cursor: "pointer"}} onClick={()=> minusMonth()} icon={faArrowAltCircleLeft} />
                            <div style={{display:"flex"}}>{chosenDate}</div>
                            <FontAwesomeIcon style={{fontSize: '1.75em', cursor: "pointer"}} onClick={()=>plusMonth()} icon={faArrowAltCircleRight} />
                        </div>
                        
                        <div id="headerRow" className="noselect" style={{display:"flex", justifyContent:"space-around", textAlign:"center"}}><div style={{border: "1px solid black", width:"100%"}}>Sun</div><div style={{border: "1px solid black", width:"100%"}}>Mon</div><div style={{border: "1px solid black", width:"100%"}}>Tue</div><div style={{border: "1px solid black", width:"100%"}}>Wed</div><div style={{border: "1px solid black", width:"100%"}}>Thu</div><div style={{border: "1px solid black", width:"100%"}}>Fri</div><div style={{border: "1px solid black", width:"100%"}}>Sat</div></div>
                        {
                            weeks
                        }
                    </div>
                :
                    <div>Error Loading Calendar, please contact us (905)885-2885</div>
        }
        {
            validDayChosen ?
                <div>
                    <div className="number" style={{marginTop: "1em", marginBottom: "0.5em", textAlign:"center"}}>Window: {deliveryWindowChosen !== '' && <span className="deliveryTime">{deliveryWindowChosen}</span>}</div>
                    {
                        slots
                    }
                </div>
            :
                <div className="number" style={{marginTop: "1em", textAlign:"center"}}>Please select a day to view availability</div>
        }
        </>
    )
}
